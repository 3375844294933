import { DOCUMENT } from '@angular/common';
import { DestroyRef, Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, fromEvent, switchMap, take } from 'rxjs';
import { GlobalLoaderService } from '@kit/global-loader/global-loader.service';
import { getAllFormErrors } from '@kit/utils/form';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { WindowRef } from '../window-service/window.service';
import { AnalyticsCookiesService } from './analytics-cookies.service';
import { DigitalData, EventDigitalData } from './digital-data.interface';
import { DigitalDataService } from './digital-data.service';
import { ServerValidationErrorResponse } from '@kit/server-errors/server-errors.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

declare global {
  interface Window {
    digitalData?: DigitalData,
    adobe?: any,
    s?: {
      pageName: string,
      pageUrl: string,
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private appElement: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private digitalDataService: DigitalDataService,
    private analyticsCookiesService: AnalyticsCookiesService,
    private globalLoaderService: GlobalLoaderService,
    private translateService: TranslateService,
    private router: Router,
    private windowRef: WindowRef,
    @Inject(IS_BROWSER_PLATFORM)
    private readonly isBrowser: boolean,
    private readonly destroyRef: DestroyRef,
  ) {
    this.appElement = this.document.getElementById('app');

    if (this.isBrowser) this.initSpaEventListener();
  }

  public initAnalytics(): void {
    this.digitalDataService.updateDigitalData();
    this.analyticsCookiesService.initTargetCookies();

    // we have troube with load. Adobe await window load event but
    // this event can already be.
    fromEvent(this.windowRef.nativeWindow, 'OneTrustGroupsUpdated').pipe(
      take(1),
      filter(() => window.adobe?.optIn?.status === 'pending'),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.windowRef.nativeWindow.dispatchEvent(new Event('load'));
    });
  }

  public validationError(control: AbstractControl, action: string): void {
    const errors = getAllFormErrors(control);

    if (errors?.length) {
      const event: EventDigitalData = {
        category: 'error',
        action,
        label: this.translateService.instant(errors[0]),
        value: 1,
      };

      this.triggerAction(event);
    }
  }

  public validationServerError(error: ServerValidationErrorResponse, action: string): void {
    const errorCode = error?.fault?.errors[0]?.errorCode;

    if (errorCode) {
      const event: EventDigitalData = {
        category: 'error',
        action,
        label: this.translateService.instant(`errors.${errorCode}`),
        value: 1,
      };

      this.triggerAction(event);

      error.handled = true;
    }
  }

  public triggerAction(event: EventDigitalData): void {
    this.digitalDataService.addEvent(event);
    this.document.body.dispatchEvent(new CustomEvent('event-action-trigger'));
  }

  public bookingPathStepEvent(): void {
    this.appElement.dispatchEvent(new CustomEvent('event-view-start'));
    this.appElement.dispatchEvent(new CustomEvent('event-view-end'));
  }

  private initSpaEventListener(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.appElement.dispatchEvent(new CustomEvent('event-view-start'));
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => this.globalLoaderService.loading$.pipe(filter(loading => !loading), take(1))),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.targetView();
      this.appElement.dispatchEvent(new CustomEvent('event-view-end'));
    });
  }

  private targetView(): void {
    this.digitalDataService.updateDigitalData();

    if (typeof this.windowRef.nativeWindow?.adobe?.target?.triggerView === 'function') {
      this.windowRef.nativeWindow.adobe.target.triggerView(this.digitalDataService.getPageName());
    }
  }
}
