import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ThematicLinks } from '@common/blog/blog.interface';
import { BlogService } from '@common/blog/blog.service';
import { BlogArticle } from '@common/model/blogArticle';
import { RelatedArticles } from '@common/model/relatedArticles';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BlogIndex } from '@common/model/blogIndex';

const SHOW_RELATED_ARTICLES = 2;

@Component({
  selector: 'app-related-articles',
  templateUrl: './related-articles.component.html',
  styleUrls: ['./related-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemRelatedArticlesComponent extends AemBaseBlockComponent implements OnInit {
  relatedArticles: BlogArticle[];
  thematicLinks: ThematicLinks;

  constructor(
    @Inject(AEM_DATA) public override data: RelatedArticles,
    private blogService: BlogService,
    private activatedRoute: ActivatedRoute,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.loadBlogData();
  }

  private loadBlogData(): void {
    this.blogService.data$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((data: BlogIndex) => {
      const uuid = this.activatedRoute.snapshot.firstChild.data?.uuid;
      const thematicIds: string[] = data.articles.data[uuid]?.thematics?.map(tag => tag.id);

      this.relatedArticles = this.getRelatedArticles(data, uuid, thematicIds);
      this.thematicLinks = this.getThematicLinks(data);

      this.cdr.detectChanges();
    });
  }

  private getRelatedArticles(data: BlogIndex, uuid: string, thematicIds: string[]): BlogArticle[] {
    return Object.values(data.articles.data)
      .filter(item => item.uuid !== uuid && item.thematics.some(tag => thematicIds.includes(tag.id)))
      .sort((currArticle: BlogArticle, nextArticle: BlogArticle) => currArticle.title.localeCompare(nextArticle.title))
      .sort((currArticle: BlogArticle, nextArticle: BlogArticle) => nextArticle.orderDate - currArticle.orderDate)
      .slice(0, SHOW_RELATED_ARTICLES);
  }

  private getThematicLinks(data: BlogIndex): ThematicLinks {
    return Object.values(data.thematics.data).reduce<ThematicLinks>((acc, item) => {
      acc[item.tag.id] = item.link;
      return acc;
    }, {});
  }
}
