import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicRenderService } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.service';
import { Fragment } from '@common/model/fragment';
import { Screen } from '@common/model/screen';
import { UserService } from '@common/user/user.service';
import { DynamicContent } from '../dynamic-render/dynamic-render.interface';
import { UserType } from '@common/profile/profile.interfaces';

@Component({
  selector: 'app-placeholder-main',
  templateUrl: './placeholder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceHolderMainComponent implements OnInit {
  dynamicContent$: Observable<DynamicContent[]>;

  constructor(
    private route: ActivatedRoute,
    private dynamicRenderService: DynamicRenderService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.dynamicContent$ = combineLatest([
      this.userService.userSubscription$.pipe(distinctUntilChanged()),
      this.route.firstChild.data.pipe(map((data: Data) => data.routeData)),
    ]).pipe(
      map(([userSubscription, data]: [UserType, Screen]) =>
        this.dynamicRenderService.prepareContent(this.getContent(data), userSubscription)
      ),
    );
  }

  protected getContent(data: Screen): Fragment[] {
    return data.container || [];
  }
}
