import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { BreadcrumbsModule } from '@kit/breadcrumbs/breadcrumbs.module';
import { ButtonModule } from '@kit/button/button.module';
import { SliderModule } from '@kit/carousel/slider.module';
import { CheckboxModule } from '@kit/checkbox/checkbox.module';
import { ContentAndShapedImageModule } from '@kit/content-and-shaped-image/content-and-shaped-image.module';
import { DateModule } from '@kit/date/date.module';
import { DialogModule } from '@kit/dialog/dialog.module';
import { FieldErrorModule } from '@kit/field-error/field-error.module';
import { FooterModule } from '@kit/footer/footer.module';
import { IconModule } from '@kit/icon/icon.module';
import { IllustrationModule } from '@kit/illustration/illustration.module';
import { InputModule } from '@kit/input/input.module';
import { LabelModule } from '@kit/label/label.module';
import { MarkdownPipeModule } from '@kit/markdown-pipe/markdown.module';
import { RadioButtonModule } from '@kit/radio-button/radio-button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { SearchSelectModule } from '@kit/search-select/search-select.module';
import { SelectRandomImageModule } from '@kit/select-random-image/select-random-image.module';
import { SelectModule } from '@kit/select/select.module';
import { StatusButtonModule } from '@kit/status-button/status-button.module';
import { TimePickerModule } from '@kit/time-picker/time-picker.module';
import { ToolbarModule } from '@kit/toolbar/toolbar.module';
import { TooltipModule } from '@kit/tooltip/tooltip.module';
import { TripCardModule } from '@kit/trip-card/trip-card.module';
import { UpdatedFieldValueModule } from '@kit/updated-field-value/updated-field-value.module';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AccordionModule } from "@kit/accordion/accordtion.module";
import { SearchSelectMultipleModule } from "@kit/search-select-multiple/search-select-multiple.module";
import { TripWalletComponent } from './trip-wallet.component';
import { TripPlannerComponent } from './trip-planner/trip-planner.component';
import { TripPlannerActivityComponent } from './trip-planner/components/activity/trip-planner-activity.component';
import { TripPlannerFlightComponent } from './trip-planner/components/flight/trip-planner-flight.component';
import { TripPlannerHotelComponent } from './trip-planner/components/hotel/trip-planner-hotel.component';
import { FlightDelayPipeModule } from '@pages/trip/pipe/flight-delay-time.module';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  declarations: [
    TripWalletComponent,
    TripPlannerComponent,
    TripPlannerActivityComponent,
    TripPlannerFlightComponent,
    TripPlannerHotelComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SelectModule,
    LabelModule,
    InputModule,
    DateModule,
    CheckboxModule,
    MarkdownPipeModule,
    ToolbarModule,
    FooterModule,
    HttpClientModule,
    StatusButtonModule,
    TimePickerModule,
    BreadcrumbsModule,
    IconModule,
    FieldErrorModule,
    IllustrationModule,
    SliderModule,
    RadioButtonModule,
    SearchSelectModule,
    TooltipModule,
    DialogModule,
    AemRouterModule,
    ButtonModule,
    ContentAndShapedImageModule,
    SelectRandomImageModule,
    SafeHtmlModule,
    UpdatedFieldValueModule,
    BackButtonModule,
    AemButtonModule,
    TripCardModule,
    ImgAltDirectiveModule,
    AccordionModule,
    SearchSelectMultipleModule,
    FlightDelayPipeModule,
    LetModule
  ],
})
export class TripWalletModule {}
