import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgAltDirectiveModule } from '@kit/aem/img-alt-directive/img-alternate-directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { BreadcrumbsModule } from '@kit/breadcrumbs/breadcrumbs.module';
import { DateModule } from '@kit/date/date.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { ThematicModule } from '@kit/thematic/thematic.module';
import { AemAirDoctorHeroComponent } from './aem-air-doctor-hero.component';
import { AemHeroBlockModule } from '@kit/aem-hero-block/aem-hero-block.module';
import { DialogModule } from '@kit/dialog/dialog.module';
import { AirDoctorDialogComponent } from './air-doctor-dialog/air-doctor-dialog.component';
import { ButtonModule } from '@kit/button/button.module';
import { LetModule } from '@kit/let/let.module';

//Deprecated
@NgModule({
  declarations: [AemAirDoctorHeroComponent, AirDoctorDialogComponent],
  exports: [AemAirDoctorHeroComponent, AirDoctorDialogComponent],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    AemButtonModule,
    AemRouterModule,
    SafeHtmlModule,
    ThematicModule,
    DateModule,
    ImgAltDirectiveModule,
    TranslateModule,
    AemHeroBlockModule,
    DialogModule,
    ButtonModule,
    LetModule
  ]
})
export class AemAirDoctorHeroModule {
}
