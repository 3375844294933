import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { IconModule } from "@kit/icon/icon.module";
import { LabelModule } from "@kit/label/label.module";
import { SlideToggleModule } from "@kit/slide-toggle/slide-toggle.module";
import { TooltipModule } from "@kit/tooltip/tooltip.module";
import { AemNotificationSettingsComponent } from "./notification.component";
import { SafeHtmlModule } from "@kit/safe-html/safe-html.module";
import { NotificationService } from "@pages/account/components/notification/notification.service";
import { PhoneAddDialogComponent } from './phone-add-dialog/phone-add-dialog.component';
import { DialogModule } from "@kit/dialog/dialog.module";
import { FieldErrorModule } from "@kit/field-error/field-error.module";
import { ReactiveFormsModule } from "@angular/forms";
import { PhoneModule } from "@kit/phone/phone.module";
import { ButtonModule } from "@kit/button/button.module";
import { SelectModule } from "@kit/select/select.module";
import { LetModule } from '@kit/let/let.module';
import { MainNotificationsComponent } from "./components/main-notifications/main-notifications.component";
import { TagModule } from "@kit/tag/tag.module";
import { ServiceNotificationDropdownComponent } from "./components/service-notification-dropdown/service-notification-dropdown.component";
import { LanguageNotificationsComponent } from "./components/language-notifications/language-notifications.component";
import { MarketingNotificationsComponent } from "./components/marketing-notifications/marketing-notifications.component";
import { ServiceNotificationsComponent } from "./components/service-notifications/service-notifications.component";
import { BackButtonModule } from "@kit/back-button/back-button.module";

@NgModule({
  declarations: [
    AemNotificationSettingsComponent, 
    PhoneAddDialogComponent, 
    MainNotificationsComponent, 
    ServiceNotificationDropdownComponent,
    LanguageNotificationsComponent,
    MarketingNotificationsComponent,
    ServiceNotificationsComponent
  ],
  exports: [AemNotificationSettingsComponent, MainNotificationsComponent],
  imports: [
    CommonModule,
    IconModule,
    SlideToggleModule,
    LabelModule,
    TranslateModule,
    TooltipModule,
    SafeHtmlModule,
    DialogModule,
    FieldErrorModule,
    ReactiveFormsModule,
    PhoneModule,
    ButtonModule,
    SelectModule,
    LetModule,
    TagModule,
    BackButtonModule
  ],
  providers: [NotificationService],
})
export class AemNotificationSettingsModule {
}
