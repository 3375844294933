import { DestroyRef, Injectable } from '@angular/core';
import { Observable, forkJoin, switchMap, timer } from 'rxjs';
import { MINUTE } from '@kit/date/date.const';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@kit/dialog/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const TIMER_INTERVAL = 29 * MINUTE;

@Injectable({
  providedIn: 'root'
})
export class InsurancePolicyTimerService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,
    private readonly destroyRef: DestroyRef,
  ) { }

  public setTimer$(isReverification: boolean = false): Observable<string> {
    return timer(TIMER_INTERVAL).pipe(
      switchMap(() => this.openExpiredTimeDialog$(isReverification)),
      takeUntilDestroyed(this.destroyRef),
    );
  };

  private openExpiredTimeDialog$(isReverification: boolean): Observable<any> {
    return forkJoin([
      this.translateService.get('pages.MY_INSURANCE.TITLES.TIMER_EXPIRED'),
      this.translateService.get('pages.MY_INSURANCE.DESCRIPTIONS.TIMER_EXPIRED'),
      this.translateService.get('pages.MY_INSURANCE.ACTIONS.ADD_A_POLICY'),
      this.translateService.get('pages.MY_INSURANCE.ACTIONS.BACK_TO_POLICIES'),
    ]).pipe(
      switchMap(([message, info, confirmTitle, cancelTitle]: [string, string, string, string]) =>
        this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
          message: message,
          info: info,
          confirmTitle: isReverification ? null : confirmTitle,
          cancelTitle: cancelTitle,
          reverseButtonPosition: true,
        }).afterClosed$
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

}
