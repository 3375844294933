import { Component, ChangeDetectionStrategy, Inject, DestroyRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, shareReplay } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ActiveInsurancePolicies } from 'src/app/common/model/activeInsurancePolicies';
import { AemBaseBlockComponent } from 'src/app/kit/aem-base-block/aem-base-block';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { Policy } from '../../policy.interface';
import { setRouterData } from '@kit/utils/router.utils';
import { dateToEndDay } from "@kit/utils/date.utils";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-insurance-active-policies',
  templateUrl: './insurance-active-policies.component.html',
  styleUrls: ['./insurance-active-policies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceActivePoliciesComponent extends AemBaseBlockComponent {
  public policies$: Observable<Policy[]> = this.getPolices$();

  constructor(
    @Inject(AEM_DATA) public aemData: ActiveInsurancePolicies,
    public readonly bootstrapService: BootstrapService,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
  ) {
    super(aemData);
  }

  public onViewDetails(policyNumber: string, openPolicyNumber: string): void {
    if (openPolicyNumber) {
      this.policyService.showOpenPolicyPopUp();

      return;
    }

    const policyCode: string = btoa(policyNumber);

    this.policyService.getPolicyDetails$(policyNumber)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.router.navigate([setRouterData(
        this.bootstrapService.link.insurancePolicy,
        { policyId: policyCode },
      )]));
  }

  public deletePolicy(policyNumber: string): void {
    this.policyService.deletePolicy$(policyNumber)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.policies$ = this.getPolices$();
        this.cdr.markForCheck();
      });
  }

  private filterPoliciesByEndDate(policies: Policy[]): Policy[] {
    return policies.filter((policy: Policy) =>
      dateToEndDay(new Date(policy.endDate)).valueOf() >= dateToEndDay(new Date()).valueOf());
  }

  private sortPolicyByDate(policies: Policy[]): Policy[] {
    return policies.sort((a: Policy, b: Policy) =>
      new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf());
  }

  private getPolices$():Observable<Policy[]> {
    return this.policyService.getPolicies().pipe(
      map((policies: Policy[]) => this.filterPoliciesByEndDate(policies)),
      map((policies: Policy[]) => this.sortPolicyByDate(policies)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
