import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  Input,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconList } from '../icon/icon.interface';
import { ResizeEnum } from './resize.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextareaComponent),
    multi: true
  }],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
  @Input() public placeholder = '';
  @Input() public icon: IconList = null;
  @Input() public clearButton = false;
  @Input() public maxLength: number = null;
  @Input() public rows: number = null;
  @Input() public resize: ResizeEnum = ResizeEnum.None;

  public focused = false;
  public disabled = false;
  public innerControl = new UntypedFormControl({ value: null, disabled: this.disabled });

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) { }

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => this.onChange(value));
  }

  public onChange = (value: string) => {};
  public onTouched = () => {};

  public writeValue(value: string): void {
    this.innerControl.setValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.innerControl[isDisabled ? 'disable' : 'enable']({ emitEvent: false });
    this.cdr.markForCheck();
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.onTouched();
  }

  public clear(): void {
    this.innerControl.setValue(null);
  }
}
