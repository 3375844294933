import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { SearchSelectModule } from '../search-select/search-select.module';
import { AemEmergencyNumbersHeroComponent } from './emergency-number.component';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  declarations: [AemEmergencyNumbersHeroComponent],
  exports: [AemEmergencyNumbersHeroComponent],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    ButtonModule,
    IconModule,
    SearchSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    LetModule,
  ]
})
export class AemEmergencyNumberModule { }
