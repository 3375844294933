import { DestroyRef, Injectable } from '@angular/core';
import { UserType } from '@common/profile/profile.interfaces';
import { UserService } from '@common/user/user.service';
import { PremiumDetail } from '@pages/admin/components/user-edit/premium-details.interface';
import { MembershipService } from '@pages/membership/membership.service';
import { Observable, map, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiMembershipService } from '@common/membership/api-membership.service';

@Injectable({
  providedIn: 'root'
})
export class AirDoctorService {
  private defaultLink = 'https://www.air-dr.com/webapp/#home';

  constructor(
    private readonly userService: UserService,
    private readonly membershipService: MembershipService,
    private readonly apiMembershipService: ApiMembershipService,
    private readonly destroyRef: DestroyRef,
  ) { }

  public resolveLinks$(): Observable<string> {
    if (this.isUserPremium()) {
      return this.apiMembershipService.getUserProfileMemberships().pipe(
        map((memberships: PremiumDetail[]) => this.findMembership(memberships)),
        map((membership: PremiumDetail) => this.getLink(membership)),
        takeUntilDestroyed(this.destroyRef)
      )
    }

    return of(this.defaultLink);
  }

  private isUserPremium(): boolean {
    return this.userService.userData.membershipStatus === UserType.Premium;
  }

  private generateAirDoctorJsonLd(membership: PremiumDetail): string {
    const structuredData = {
      "m": this.userService.userData.email,
      "f": this.userService.userData.firstName,
      "l": this.userService.userData.lastName,
      "c": "10",
      "p": membership.insurance,
      "s": membership.startDate,
      "e": membership.endDate
    };

    return JSON.stringify(structuredData);
  }

  private replaceSymbols(base64encoded: string): string {
    return base64encoded
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  private createAirDoctorExtendedLink(membership: PremiumDetail): string {
    const base64encoded = btoa(this.generateAirDoctorJsonLd(membership));

    return `https://www.air-dr.com/webapp/?data=${this.replaceSymbols(base64encoded)}&source=partner`;
  }

  private findMembership(memberships: PremiumDetail[]): PremiumDetail | null {
    if (this.membershipService.hasCurrentPolicyWithPolicyNumber(memberships)) {
      return this.getClosestActivePolicyData(this.membershipService.getCurrentPoliciesWithPolicyNumber(memberships));
    }

    return null;
  }

  private getLink(membership: PremiumDetail | null): string {
    return !!membership ? this.createAirDoctorExtendedLink(membership) : this.defaultLink;
  }

  private getClosestActivePolicyData(memberships: PremiumDetail[]): PremiumDetail {
    const startDateTimes = this.getMembershipsDateTimes(memberships);
    const closestPolicyIndex = startDateTimes.indexOf(Math.max(...startDateTimes));

    return memberships[closestPolicyIndex];
  }

  private getMembershipsDateTimes(memberships: PremiumDetail[]): number[] {
    return memberships.map((membership: PremiumDetail) => {
      return new Date(membership.startDate).getTime();
    })
  }

}
