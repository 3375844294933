<div class="shell">
  <div class="notification__header">
    <h1 class="notification__header__text">{{ 'pages.NOTIFICATION.TITLES.NOTIFICATION_SETTING' | translate }}</h1>
  </div>
  <div class="notification">
    <app-icon icon="planet" 
              size="x-large" 
              theme="amped">
    </app-icon>
    <div class="t-normal weight-normal">
      {{ 'pages.NOTIFICATION.LABELS.LANGUAGE_NOTIFICATIONS' | translate }}
    </div>
    <app-select class="notification__dropdown t-normal" 
                [options]="languageNotificationSelects"
                [formControl]="languageNotificationSelectControl"
                valueKey="value" 
                viewKey="name" 
                selectKey="value">
    </app-select>
  </div>
</div>