<header class="header">
  <div class="header__main">
    <div class="header__shell shell">
      <app-breadcrumbs *ngIf="data.hasBreadcrumbs"></app-breadcrumbs>

      <div class="hero">
        <div class="hero__group">
          <h1 class="hero__title">{{ data.title }}</h1>
          <div class="numbers-container_form">
            <p class="text-normal">{{ 'forms.EMERGENCY.LABELS.SELECT_CURRENTLY_LOCATED' | translate }}</p>
            <app-search-select
              class="numbers-container_select"
              [placeholder]="'forms.EMERGENCY.PLACEHOLDERS.SELECT_COUNTRY' | translate"
              viewKey="name"
              valueKey="code"
              [formControl]="countryCodeForm"
              [options]="countries$ | async"></app-search-select>
            <app-button
              id="action-emergency-search"
              size="l"
              type="normal"
              (click)="select()"
            >{{ 'forms.EMERGENCY.ACTIONS.SEARCH' | translate }}</app-button>
          </div>
        </div>

        <div class="hero-component numbers-container" *let="(emergencyNumber$ | async) as emergencyNumber">
          <a
            [attr.href]="emergencyNumber?.[EmergencyNumberType.EMERGENCY_PHONE] && 'tel:' + emergencyNumber?.[EmergencyNumberType.EMERGENCY_PHONE]"
            class="number-card"
            *ngIf="!emergencyNumber || emergencyNumber[EmergencyNumberType.EMERGENCY_PHONE]"
            id="action-emergency-phone"
          >
            <app-icon icon="ambulance" theme="lighten" size="x-large"></app-icon>
            <div class="number-card_content">
              <div class="number-card_content-title text-normal">{{ 'forms.EMERGENCY.LABELS.GLOBAL_EMERGENCY_NUMBERS' | translate }}</div>
              <div class="number-card_content-number text-tiny">
                {{ !emergencyNumber ?
                  ('forms.EMERGENCY.PLACEHOLDERS.SELECT_COUNTRY' | translate)
                  : emergencyNumber[EmergencyNumberType.EMERGENCY_PHONE]
                }}
              </div>
            </div>
            <app-button [disabled]="!emergencyNumber" icon="phone" type="icon-button" size="s"></app-button>
          </a>
          <a
            [attr.href]="emergencyNumber?.[EmergencyNumberType.AMBULANCE_PHONE] && 'tel:' + emergencyNumber?.[EmergencyNumberType.AMBULANCE_PHONE]"
            class="number-card"
            *ngIf="!emergencyNumber || emergencyNumber[EmergencyNumberType.AMBULANCE_PHONE]"
            id="action-ambulance-phone"
          >
            <app-icon icon="medicine" theme="lighten" size="x-large"></app-icon>
            <div class="number-card_content">
              <div class="number-card_content-title text-normal">{{ 'forms.EMERGENCY.LABELS.AMBULANCE' | translate }}</div>
              <div class="number-card_content-number text-tiny">
                {{ !emergencyNumber ?
                  ('forms.EMERGENCY.PLACEHOLDERS.SELECT_COUNTRY' | translate)
                  : emergencyNumber[EmergencyNumberType.AMBULANCE_PHONE]
                }}
              </div>
            </div>
            <app-button [disabled]="!emergencyNumber" icon="phone" type="icon-button" size="s"></app-button>
          </a>
          <a
            [attr.href]="emergencyNumber?.[EmergencyNumberType.FIRE_PHONE] && 'tel:' + emergencyNumber?.[EmergencyNumberType.FIRE_PHONE]"
            class="number-card"
            *ngIf="!emergencyNumber || emergencyNumber[EmergencyNumberType.FIRE_PHONE]"
            id="action-fire-phone"
          >
            <app-icon icon="fire-department" theme="lighten" size="x-large"></app-icon>
            <div class="number-card_content">
              <div class="number-card_content-title text-normal">{{ 'forms.EMERGENCY.LABELS.FIRE_DEPARTMENT' | translate }}</div>
              <div class="number-card_content-number text-tiny">
                {{ !emergencyNumber ?
                  ('forms.EMERGENCY.PLACEHOLDERS.SELECT_COUNTRY' | translate)
                  : emergencyNumber[EmergencyNumberType.FIRE_PHONE]
                }}
              </div>
            </div>
            <app-button [disabled]="!emergencyNumber" icon="phone" type="icon-button" size="s"></app-button>
          </a>
          <a
            [attr.href]="emergencyNumber?.[EmergencyNumberType.POLICE_PHONE] && 'tel:' + emergencyNumber?.[EmergencyNumberType.POLICE_PHONE]"
            class="number-card"
            *ngIf="!emergencyNumber || emergencyNumber[EmergencyNumberType.POLICE_PHONE]"
            id="action-police-phone"
          >
            <app-icon icon="police" theme="lighten" size="x-large"></app-icon>
            <div class="number-card_content">
              <div class="number-card_content-title text-normal">{{ 'forms.EMERGENCY.LABELS.POLICE' | translate }}</div>
              <div class="number-card_content-number text-tiny">
                {{ !emergencyNumber ?
                  ('forms.EMERGENCY.PLACEHOLDERS.SELECT_COUNTRY' | translate)
                  : emergencyNumber[EmergencyNumberType.POLICE_PHONE]
                }}
              </div>
            </div>
            <app-button [disabled]="!emergencyNumber" icon="phone" type="icon-button" size="s"></app-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
