<section class="side-by-side-with-cta" *let="(userSubscription$ | async) as userSubscription">
  <div class="shell">
    <div class="section__header section__header_centered">
      <h2 class="zero-margin h4 m-h2">{{ aemData.title }}</h2>
      <div class="side-by-side-with-cta__label t-large m-t-normal" *ngIf="aemData.text"><app-safe-html [content]="aemData.text"></app-safe-html></div>
    </div>
    <div class="section__body">
      <div class="flight-delay-cards">
        <div class="flight-delay-card"
             [style.background-color]="item.colourBackground"
             [appAemRouterLink]="item.cta.link"
             *ngFor="let item of aemData.items"
             [ngClass]="item.entitlements ? (!item.entitlements[userSubscription] ? 'flight-delay-card_disabled' : '') : ''">
          <div class="flight-delay-card__body">
            <h4 class="flight-delay-card__title h4 m-h3">{{ item.title }}</h4>
            <app-safe-html class="flight-delay-card__text t-small m-t-normal" *ngIf="item.text" [content]="item.text"></app-safe-html>
          </div>
          <app-button
            class="flight-delay-card__button"
            [appAemRouterLink]="item.cta.link"
            type="icon-button"
            [icon]="item.entitlements ? (item.entitlements[userSubscription] ? item.cta.icon : 'lock') : item.cta.icon"
            [theme]="item.entitlements ? (item.entitlements[userSubscription] ? 'black' : 'grey') : 'black'"
            [ngClass]="item.entitlements ? (!item.entitlements[userSubscription] ? 'flight-delay-card__button_disabled' : '') : ''"
            size="l"
          ></app-button>
          <div class="cards-action">
            <img [src]="item.image.imagePath"
                 [appImgAlt]="item.image.altText"
                 [appImgAltData]="aemData">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
