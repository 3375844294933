import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Renderer2 } from '@angular/core';
import { LumiWidgetService } from '@kit/lumi-widget/service/lumi-widget.service';
import { DialogService } from '@kit/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { WindowRef } from '@common/window-service/window.service';
import { catchError, EMPTY, interval, map, takeWhile } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LumiToken } from '@kit/lumi-widget/lumi.interface';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';

@Component({
  selector: 'app-lumi-teleconsultation',
  templateUrl: './lumi-teleconsultation.component.html',
  styleUrls: ['./lumi-teleconsultation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LumiTeleconsultationComponent {
  constructor(
    private readonly lumiWidgetService: LumiWidgetService,
    private readonly renderer: Renderer2,
    private readonly destroyRef: DestroyRef,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
    private readonly windowRef: WindowRef,
    private readonly elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    this.initWidget();

    // @ts-ignore
    this.windowRef.nativeWindow.window.lumi.tryAgainExpSession = () => this.initWidget();
  }

  private initWidget(): void {
    this.lumiWidgetService.getLumiToken$()
      .pipe(
        catchError(() => {
          this.showErrorPopup();

          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((token: LumiToken) => {
        this.setIframeStyle();
        // @ts-ignore
        this.windowRef.nativeWindow.window.lumi.onError = () => this.showErrorPopup();
        this.lumiWidgetService.initWidget('teleconsultation', 'teleconsultation', token);
      });
  }


  private showErrorPopup(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('pages.LUMI_WIDGET.ERROR.SERVICE_UNAVAILABLE'),
      confirmTitle: 'OK',
    }).afterClosed$.subscribe( () => this.router.navigateByUrl(this.bootstrap.link.teleconsultation));
  }

  private setIframeStyle(): void {
    interval(300)
      .pipe(
        map(() => {
          const iFameLink = this.elementRef.nativeElement.getElementsByTagName('iFrame')[0];

          if (!iFameLink) return true;

          this.renderer.setStyle(iFameLink, 'border', '0px');
          this.renderer.setStyle(iFameLink, 'border-radius', '15px');

          return false;
        }),
        takeWhile(Boolean),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe()
  }
}
