<div class="shell content-box" *let="trips$ | async as tripList">
  <div class="my-trips_list-wrapper">
    <div class="my-trips_list" *ngIf="(tripCounter$ | async) === 0">
      <p class="text-normal">
        {{ 'pages.MY_TRIPS.NO_TRIP_NOTIFICATION' | translate }}
      </p>
    </div>
    <div class="my-trips_list current-trips">
      <div class="my-trips_list-title h4 m-h3">
        {{ 'pages.MY_TRIPS.CURRENT_TRIPS' | translate }}
        <div class="my-trips_list-counter text-tiny">
          {{ tripList?.currentTrips?.length }}
        </div>
      </div>
      <div *ngIf="tripList?.currentTrips?.length; else emptyPresentList" class="my-trips_list-content">
        <app-trip-card-detailed *ngFor="let trip of tripList.currentTrips; trackBy:trackByFn"
          [data]="trip"
          [currentAndFutureTripCounter]="tripList?.currentTrips?.length + tripList?.upcomingTrips?.length"
          (delete)="onDelete()">
        </app-trip-card-detailed>

      </div>
      <ng-template #emptyPresentList>
        <span class="text-normal">{{ 'pages.MY_TRIPS.NO_TRIPS_IN_PROGRESS' | translate }}</span>
      </ng-template>
    </div>

    <div id="upcoming_trips_list" class="my-trips_list">
      <div class="my-trips_list-title h4 m-h3">
        {{ 'pages.MY_TRIPS.UPCOMING_TRIPS' | translate }}
        <div class="my-trips_list-counter text-tiny">
          {{ tripList?.upcomingTrips?.length }}
        </div>
      </div>

      <div *ngIf="tripList?.upcomingTrips?.length; else emptyFutureList"
           class="my-trips_list-content-grid"
           [ngClass]="{'my-trips_list-content-grid_upcoming': (tripList?.currentTrips?.length + tripList?.upcomingTrips?.length) > 1}">
        <app-trip-card *ngFor="let trip of tripList.upcomingTrips" [data]="trip"></app-trip-card>
      </div>
      <ng-template #emptyFutureList>
        <span class="text-normal">{{ 'pages.MY_TRIPS.NO_UPCOMING_TRIPS' | translate }}</span>
      </ng-template>
      <div *ngIf="tripList?.upcomingTrips?.length && (tripList?.currentTrips?.length + tripList?.upcomingTrips?.length) > 1"
           class="my-trips_list-join">
        <app-button id="my-trips-action-trip-merge"
                    type="small-width"
                    size="m"
                    (click)="mergeTrip()"
                    theme="bordered"
                    class="my-trips_list-join_button">
          {{ 'forms.TRIP.ACTIONS.MERGE_FUTURE_TRIPS' | translate }}
        </app-button>
      </div>
    </div>

    <div id="past_trips_list" class="my-trips_list">
      <div class="my-trips_list-title h4 m-h3">
        {{ 'pages.MY_TRIPS.PAST_TRIPS' | translate }}
        <div class="my-trips_list-counter text-tiny">
          {{ tripList?.pastTrips?.length }}
        </div>
      </div>
      <div *ngIf="tripList?.pastTrips?.length; else emptyPastList" class="my-trips_list-content-grid">
        <app-trip-card *ngFor="let trip of tripList.pastTrips" [data]="trip"></app-trip-card>
      </div>
      <ng-template #emptyPastList>
        <span class="text-normal">{{ 'pages.MY_TRIPS.NO_PAST_TRIPS' | translate }}</span>
      </ng-template>
    </div>
  </div>
</div>
