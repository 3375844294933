import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonWithTimerComponent } from './button-with-timer.component';
import { ButtonModule } from '@kit/button/button.module';
import { IconModule } from '@kit/icon/icon.module';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  declarations: [ButtonWithTimerComponent],
  exports: [ButtonWithTimerComponent],
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    LetModule,
  ],
})
export class ButtonWithTimerModule {
}
