import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Document, FolderContent } from '@common/documents-vault/api-documents.interface';
import { ApiDocumentsService } from '@common/documents-vault/api-documents.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-document-vault-folder-page',
  templateUrl: './folder-page.component.html',
  styleUrls: ['./folder-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentVaultFolderPageComponent implements OnInit {
  @Input() public folder: FolderContent;
  @Input() public isUserFreemium: boolean;

  public folderFiles: Observable<Document>;
  public entitiesOnPage = 10;
  public currentPage = 1;

  constructor(
    private readonly apiDocumentsService: ApiDocumentsService,
  ) {}

  public ngOnInit(): void {
    this.getFiles();
  }

  public getPage(page: number): void {
    this.currentPage = page;
    this.getFiles();
  }

  public deleteFile(event: Document): void {
    this.folderFiles = of(event);
  }

  public changeCurrentPage(event: number): void {
    this.currentPage = event;
  }

  private getFiles(): void {
    this.folderFiles = this.apiDocumentsService.getDocuments(this.entitiesOnPage, this.currentPage - 1, +this.folder.id);
  }
}
