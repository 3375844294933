<section class="seo-block">
  <div class="shell">
    <div class="seo-block__header seo-block__header_mobile-centered">
      <h2 class="seo-block__title">{{ data.title }}</h2>
    </div>
    <div class="seo-block__grid">
      <div class="seo-block__column">
        <app-safe-html [content]="data.text" class="seo-block__text" #content [class.show-all]="showAll"></app-safe-html>
      </div>
      <div *ngIf="!showAll" class="seo-block__column seo-block__column_content-right">
        <app-button id="action-seo-block-show-more"
                    size="m"
                    theme="bordered"
                    type="small-width"
                    (click)="showMore()">
                    {{ 'forms.SEO_BLOCK.READ_MORE_BUTTON' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</section>
