import { AemAdditionalServicesComponent } from "@kit/aem-additional-services/aem-additional-services.component";
import { FaqBackButtonComponent } from '@kit/aem-button/faq-back-button/faq-back-button.component';
import { AemDestinationHeroComponent } from "@kit/aem-destination-hero/destination-hero.component";
import { DynamicWrapperBlockComponent } from '@kit/aem-dynamic-wrapper/aem-dynamic-wrapper.component';
import { AemEmergencyNumbersHeroComponent } from "@kit/aem-emergency-number/emergency-number.component";
import { AemHeroBlockComponent } from "@kit/aem-hero-block/aem-hero-block.component";
import { AemServiceCardsComponent } from "@kit/aem-service-cards/aem-service-cards.component";
import { AemTitledImageComponent } from "@kit/aem-titled-image/aem-titled-image.component";
import { AemBlogHeroComponent } from "@kit/aem/common/aem-blog-hero/aem-blog-hero.component";
import { AemHeroOnlyTextComponent } from "@kit/aem/common/aem-hero-only-text/aem-hero-only-text.component";
import { AemMembershipComponent } from "@kit/aem/common/aem-membership-cards/membership.component";
import { AemMembershipPromotionsComponent } from "@kit/aem/common/aem-membership-promotions/aem-membership-promotions.component";
import { AemArticleListComponent } from "@kit/aem/common/articles-list/articles-list.component";
import { AemBubbleAndShapedImageComponent } from "@kit/aem/common/bubble-and-shaped-image/bubble-and-shaped-image.component";
import { AemCardsWithTextComponent } from "@kit/aem/common/cards-with-text/cards-with-text.component";
import { AemCarouselCardsWithIconComponent } from "@kit/aem/common/carousel-cards-with-icon/carousel-cards-with-icon.component";
import { AemCodeSnippetComponent } from "@kit/aem/common/code-snippet/code-snippet.component";
import { AemColumnComponent } from "@kit/aem/common/column/column.component";
import { AemContentAndImageComponent } from "@kit/aem/common/content-and-image/content-and-image.component";
import { AemContentBlockComponent } from "@kit/aem/common/content-block/content-block.component";
import { AemCtaBlockComponent } from "@kit/aem/common/cta-block/cta-block.component";
import { AemFaqComponent } from "@kit/aem/common/faq/faq-block/faq-block.component";
import { AemFaqCollapsibleComponent } from "@kit/aem/common/faq/faq-collapsible-block/faq-collapsible.component";
import { AemFaqCollapsibleGroupComponent } from "@kit/aem/common/faq/faq-collapsible-group/faq-collapsible-group.component";
import { AemFaqThematicsComponent } from '@kit/aem/common/faq/faq-thematics/faq-thematics.component';
import { AemFaqFeedbackComponent } from '@kit/aem/common/faq/helpful/aem-feedback.component';
import { AemFileDownloadsComponent } from "@kit/aem/common/file-downloads/file-downloads.component";
import { AemHomeHeroComponent } from "@kit/aem/common/home-hero/home-hero.component";
import { AemImageBlockComponent } from "@kit/aem/common/image-block/image-block.component";
import { AemInsuranceCarrouselComponent } from "@kit/aem/common/insurance-carrousel/insurance-carrousel.component";
import { AemQuoteAndImageComponent } from "@kit/aem/common/quote-and-image/quote-and-image.component";
import { AemQuoteComponent } from "@kit/aem/common/quote/quote.component";
import { AemRelatedArticlesCarouselComponent } from "@kit/aem/common/related-articles-carousel/related-articles-carousel.component";
import { AemRelatedArticlesComponent } from "@kit/aem/common/related-articles/related-articles.component";
import { AemRowArticleComponent } from "@kit/aem/common/row-article/row-article.component";
import { AemShareBlockComponent } from "@kit/aem/common/share-block/share-block.component";
import { AemSideBySideWithCtaComponent } from "@kit/aem/common/side-by-side-with-cta/side-by-side-with-cta.component";
import { AemStepByStepComponent } from "@kit/aem/common/step-by-step/step-by-step.component";
import { AemSurveyBannerComponent } from "@kit/aem/common/survey-banner/survey-banner.component";
import { ContentAndShapedImageComponent } from "@kit/content-and-shaped-image/content-and-shaped-image.component";
import { MembershipPlansComponent } from "@kit/membership-plans/membership-plans.component";
import { SeoBlockComponent } from "@kit/seo-block/seo-block.component";
import { SideBySideComponent } from "@kit/side-by-side/side-by-side.component";
import { AemAccountEditFormComponent } from "@pages/account/components/aem-account-edit-form/account-edit-form.component";
import { AemAccountHeroComponent } from "@pages/account/components/aem-account-hero/aem-account-hero.component";
import { AemRegisterComponent } from "@pages/account/components/aem-account-register/register.component";
import { AemMyAccountComponent } from "@pages/account/components/aem-my-account/my-account.component";
import { AemPasswordCreationsFormComponent } from "@pages/account/components/aem-password-creations-form/password-creations-form.component";
import { AemProfileLinksComponent } from "@pages/account/components/aem-profile-links/aem-profile-links.component";
import { AemResetPasswordComponent } from "@pages/account/components/aem-reset-password/reset-password.component";
import { AemCoTravelersPageComponent } from "@pages/account/components/co-travelers/co-travelers.component";
import { AemManageTravalersComponent } from "@pages/account/components/co-travelers/manage-travalers/manage-travalers.component";
import { InsuranceActivePoliciesComponent } from "@pages/account/components/my-insurance/components/insurance-active-policies/insurance-active-policies.component";
import { AddExistingPolicyComponent } from "@pages/account/components/my-insurance/components/insurance-add-existing-policy/insurance-add-existing-policy.component";
import { InsuranceDetailsPinComponent } from "@pages/account/components/my-insurance/components/insurance-details-pin/insurance-details-pin.component";
import { InsurancePastPoliciesComponent } from "@pages/account/components/my-insurance/components/insurance-past-policies/insurance-past-policies.component";
import { InsurancePolicyDetailsComponent } from "@pages/account/components/my-insurance/components/insurance-policy-details/insurance-policy-details.component";
import { AemNotificationSettingsComponent } from "@pages/account/components/notification/notification.component";
import { PlaceHolderBottomComponent } from "@pages/dynamic/placeholder/placeholder-bottom.component";
import { PlaceHolderMainComponent } from "@pages/dynamic/placeholder/placeholder-main.component";
import { PlaceHolderTopComponent } from "@pages/dynamic/placeholder/placeholder-top.component";
import { AddActivityElementComponent } from "@pages/trip/components/activity-element/add-activity-element/add-activity-element.component";
import { EditActivityElementComponent } from "@pages/trip/components/activity-element/edit-activity-element/edit-activity-element.component";
import { AddTripElementComponent } from "@pages/trip/components/add-trip-element/add-trip-element.component";
import { AddCoTravelerComponent } from "@pages/trip/components/aem-co-traveler/add-co-traveler/add-co-traveler.component";
import { CoTravelerListComponent } from "@pages/trip/components/aem-co-traveler/co-traveler-list/co-traveler-list.component";
import { CoTravelerComponent } from "@pages/trip/components/aem-co-traveler/co-traveler.component";
import { AemPastFlightCompensationComponent } from '@pages/trip/components/aem-past-flight-compensation/aem-past-flight-compensation.component';
import { CreateTripComponent } from "@pages/trip/components/create-trip/create-trip.component";
import { EditTripComponent } from "@pages/trip/components/edit-trip/edit-trip.component";
import { EnumerationComponent } from "@pages/trip/components/enumeration/enumeration.component";
import { FlightDelayComponent } from "@pages/trip/components/flight-delay/flight-delay.component";
import { AddFlightManuallyComponent } from "@pages/trip/components/flight/add-flight-manually/add-flight-manually.component";
import { FlightAddComponent } from "@pages/trip/components/flight/flight-add.component";
import { FlightEditComponent } from "@pages/trip/components/flight/flight-edit/flight-edit.component";
import { AddHotelComponent } from "@pages/trip/components/hotel/add-hotel/add-hotel.component";
import { EditHotelComponent } from "@pages/trip/components/hotel/edit-hotel/edit-hotel.component";
import { MyTripsComponent } from "@pages/trip/components/my-trips/my-trips.component";
import { StartJourneyComponent } from "@pages/trip/components/start-journey/start-journey.component";
import { TripWalletComponent } from "@pages/trip/components/trip-wallet/trip-wallet.component";
import { FlightAddedComponent } from "@pages/trip/components/flight-added/flight-added.component";
import { FlightsExistingComponent } from "@pages/trip/components/flights-existing/flights-existing.component";
import { MergeTripSuccessfullyComponent } from "@pages/trip/components/merge-trip/merge-trip-successfully/merge-trip-successfully.component";
import { MergeTripComponent } from "@pages/trip/components/merge-trip/merge-trip.component";
import { AemEmbeddedPlayerComponent } from "@kit/aem/common/aem-embedded-player/aem-embedded-player.component";
import { AemVideoPlayerComponent } from "@kit/aem/common/aem-video-player/aem-video-player.component";
import { AemContentAndVideoComponent } from "@kit/aem/common/content-and-video/content-and-video.component";
import { AltairWidgetComponent } from "@kit/altair-widget/altair-widget.component";
import { AemLoungeVouchersComponent } from "@pages/account/components/lounge-vouchers/lounge-vouchers.component";
import { AemDocumentVaultComponent } from "@pages/account/components/document-vault/document-vault.component";
import { TermsAndConditionsComponent } from "@pages/account/components/terms-and-conditions/terms-and-conditions.component";
import {
  RoleBasedContentWrapperComponent
} from "@kit/aem/common/role-based-content-wrapper/role-based-content-wrapper.component";
import { AemUniversalHeroComponent } from "@kit/aem-universal-hero/aem-universal-hero.component";
import { AemImageComponent } from "@kit/aem/common/aem-image/aem-image.component";
import { AemRichTextComponent } from "@kit/aem/common/aem-rich-text/aem-rich-text.component";
import { SspWidgetComponent } from '@kit/ssp-widget/ssp-widget.component';
import { AemContactFormComponent } from '@kit/aem/common/aem-contact-form/aem-contact-form.component';
import {
  InsuranceBasedContentWrapperComponent
} from '@kit/aem/common/insurance-based-content-wrapper/insurance-based-content-wrapper.component';
import { GradientCardsContentComponent } from "@kit/aem/common/gradient-cards-content/gradient-cards-content.component";
import {
  UserTypeContentWrapperComponent
} from '@kit/aem/common/user-type-content-wrapper/user-type-content-wrapper.component';
import { MobileAppContentCardsComponent } from "@kit/aem/common/mobile-app-content-cards/mobile-app-content-cards.component";
import {
  MobileApplicationLinksComponent
} from '@kit/aem/common/mobile-application-links/mobile-application-links.component';
import {
  LumiSymptomCheckerComponent
} from '@kit/lumi-widget/widgets/lumi-symptom-checker/lumi-symptom-checker.component';
import { LumiDoctorChatComponent } from '@kit/lumi-widget/widgets/lumi-doctor-chat/lumi-doctor-chat.component';
import {
  LumiTeleconsultationComponent
} from '@kit/lumi-widget/widgets/lumi-teleconsultation/lumi-teleconsultation.component';

export const AEM_COMPONENTS = (): Record<string, any> => ({
  // Placeholder
  PlaceholderMain: PlaceHolderMainComponent,
  PlaceholderTop: PlaceHolderTopComponent,
  PlaceholderBottom: PlaceHolderBottomComponent,

  // Common block
  SEOBlock: SeoBlockComponent,
  ContentAndShapedImage: ContentAndShapedImageComponent,
  SideBySide: SideBySideComponent,
  Memberships: MembershipPlansComponent,
  ServiceCards: AemServiceCardsComponent,
  AdditionalServices: AemAdditionalServicesComponent,
  TitledImage: AemTitledImageComponent,
  BubbleAndShapedImage: AemBubbleAndShapedImageComponent,
  MembershipCards: AemMembershipComponent,
  MembershipPromotions: AemMembershipPromotionsComponent,
  SideBySideWithCTA: AemSideBySideWithCtaComponent,
  StepByStep: AemStepByStepComponent,
  CarouselCardsWithIcon: AemCarouselCardsWithIconComponent,
  ContentBlock: AemContentBlockComponent,
  Quote: AemQuoteComponent,
  Image: AemImageComponent,
  RichText: AemRichTextComponent,
  ImageBlock: AemImageBlockComponent,
  CTABlock: AemCtaBlockComponent,
  FileDownloads: AemFileDownloadsComponent,
  ContentAndImage: AemContentAndImageComponent,
  QuoteAndImage: AemQuoteAndImageComponent,
  SurveyBanner: AemSurveyBannerComponent,
  FAQ: AemFaqComponent,
  FAQCollapsible: AemFaqCollapsibleComponent,
  FAQCollapsibleGroup: AemFaqCollapsibleGroupComponent,
  FAQBackButton: FaqBackButtonComponent,
  FAQThematics: AemFaqThematicsComponent,
  FAQFeedback: AemFaqFeedbackComponent,
  CodeSnippet: AemCodeSnippetComponent,
  DynamicComponentAddInsuranceForm: AddExistingPolicyComponent,
  InsurancePolicyDetails: InsurancePolicyDetailsComponent,
  DynamicComponentPinCodeForm: InsuranceDetailsPinComponent,
  CardsWithText: AemCardsWithTextComponent,
  ArticlesList: AemArticleListComponent,
  RowArticle: AemRowArticleComponent,
  RelatedArticlesCarousel: AemRelatedArticlesCarouselComponent,
  RelatedArticles: AemRelatedArticlesComponent,
  Column: AemColumnComponent,
  BlogShare: AemShareBlockComponent,
  DynamicComponentWrapperBlock: DynamicWrapperBlockComponent,
  DynamicComponentFlightDelayAndCancelation: AemPastFlightCompensationComponent,
  EmbeddedVideoPlayer: AemEmbeddedPlayerComponent,
  VideoPlayer: AemVideoPlayerComponent,
  ContentAndVideo: AemContentAndVideoComponent,
  AltairWidget: AltairWidgetComponent,
  SSPWidget: SspWidgetComponent,
  RoleBasedContentWrapper: RoleBasedContentWrapperComponent,
  UserTypeContentWrapper: UserTypeContentWrapperComponent,
  ContactForm: AemContactFormComponent,
  GradientCardsContent: GradientCardsContentComponent,
  MobileAppContentCards: MobileAppContentCardsComponent,
  MobileApplicationLinks: MobileApplicationLinksComponent,
  SymptomCheckerWidget: LumiSymptomCheckerComponent,
  DoctorChatWidget: LumiDoctorChatComponent,
  TeleconsultationWidget: LumiTeleconsultationComponent,

  // Trip Component
  TripList: MyTripsComponent,
  TripDetails: TripWalletComponent,
  FlightDelay: FlightDelayComponent,
  Enumeration: EnumerationComponent,
  ActiveInsurancePolicies: InsuranceActivePoliciesComponent,
  PastInsurancePolicies: InsurancePastPoliciesComponent,
  TripContentAndShapedImage: StartJourneyComponent,
  FormElements: AddTripElementComponent,
  FormTripAdd: CreateTripComponent,
  MergeTripSuccessfully: MergeTripSuccessfullyComponent,
  MergeTrip: MergeTripComponent,
  DynamicComponentFlightsAdded: FlightAddedComponent,
  DynamicComponentExistingFlights: FlightsExistingComponent,
  FormTripEdit: EditTripComponent,
  FormActivityAdd: AddActivityElementComponent,
  FormActivityEdit: EditActivityElementComponent,
  FormFlightAdd: FlightAddComponent,
  FormFlightEdit: FlightEditComponent,
  FormFlightAddManually: AddFlightManuallyComponent,
  FormAccommodationAdd: AddHotelComponent,
  FormAccommodationEdit: EditHotelComponent,
  FlightSelectCotravelersForm: CoTravelerListComponent,
  FlightAddCotravelerForm: AddCoTravelerComponent,
  Information: CoTravelerComponent,

  // Account
  AccountHero: AemAccountHeroComponent,
  AccountDetails: AemMyAccountComponent,
  NotificationSettings: AemNotificationSettingsComponent,
  LoungeVouchers: AemLoungeVouchersComponent,
  DocumentVault: AemDocumentVaultComponent,
  DynamicComponentProfileLinks: AemProfileLinksComponent,
  DynamicComponentPasswordRecoveryForm: AemResetPasswordComponent,
  DynamicComponentRegistrationForm: AemRegisterComponent,
  DynamicComponentPasswordCreationForm: AemPasswordCreationsFormComponent,
  DynamicComponentAccountEditForm: AemAccountEditFormComponent,
  DynamicComponentCotravelersList: AemCoTravelersPageComponent,
  DynamicComponentAddCotravelerForm: AemManageTravalersComponent,
  DynamicComponentEditCotravelerForm: AemManageTravalersComponent,
  TermsAndConditionsComponent: TermsAndConditionsComponent,

  // Destination
  DestinationExplorerHero: AemDestinationHeroComponent,

  // Insurance
  InsuranceCarrousel: AemInsuranceCarrouselComponent,

  // Hero Component
  Hero: AemHeroBlockComponent,
  UniversalHero: AemUniversalHeroComponent,
  EmergencyNumbersHero: AemEmergencyNumbersHeroComponent,
  HomeHero: AemHomeHeroComponent,
  FAQHero: AemHeroOnlyTextComponent,
  BlogHero: AemBlogHeroComponent,
  InsuranceBasedContentWrapper: InsuranceBasedContentWrapperComponent,
});
