import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ConfirmationDialogComponent } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from 'src/app/kit/dialog/dialog.service';
import { endDatePassed } from 'src/app/kit/utils/date.utils';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { TripDetails as TripDetailsAEM } from '../../../../common/model/models';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { DestinationPoint, TripDetails } from '../../trip.interfaces';
import { TripService } from '../../trip.service';
import { CountyImageService } from "@common/country-image/county-image.service";
import { PremiumDetail } from '@pages/admin/components/user-edit/premium-details.interface';
import { MembershipService } from '@pages/membership/membership.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiMembershipService } from '@common/membership/api-membership.service';

@Component({
  selector: 'app-trip-wallet',
  templateUrl: './trip-wallet.component.html',
  styleUrls: ['./trip-wallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripWalletComponent extends AemBaseBlockComponent implements OnInit {
  public currentTrip$: Observable<TripDetails>;
  public showInsuranceBlock$: Observable<boolean>;
  public showAEMInsuranceBlock$: Observable<boolean>;
  public showCompensationBlock$: Observable<boolean>;
  public showMedicalBlock$: Observable<boolean>;
  public countryImagePath: string;

  constructor(
    @Inject(AEM_DATA) public override data: TripDetailsAEM,
    public readonly bootstrapService: BootstrapService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService,
    private readonly tripService: TripService,
    private readonly destroyRef: DestroyRef,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
    private readonly countyImageService: CountyImageService,
    private readonly membershipService: MembershipService,
    private readonly apiMembershipService: ApiMembershipService,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.currentTrip$ = this.route.firstChild.data
      .pipe(
        map((data: Data) => data.currentTrip),
        tap((trip: TripDetails) =>
          this.countryImagePath = this.countyImageService.getCountyImagePath(trip.destinationPoints[0].country.code)
        ),
      )

    this.showInsuranceBlock$ = this.currentTrip$.pipe(map((trip: TripDetails) => {
        return (this.isPresentTrip(trip) || this.isFutureTrip(trip)) && !this.data.title;
      }
    ));

    this.showAEMInsuranceBlock$ = this.currentTrip$.pipe(switchMap((trip: TripDetails) => {
        return this.isUserPremiumDuringFutureTrip(trip).pipe(
          map((isPremium: boolean) => {
            return (this.isFutureTrip(trip) || this.isPresentTrip(trip)) && this.data.title && !isPremium;
          })
        );
      })
    );

    this.showCompensationBlock$ = this.currentTrip$.pipe(map((trip: TripDetails) =>
      this.isPastTrip(trip)
    ));

    this.showMedicalBlock$ = this.currentTrip$.pipe(map((trip: TripDetails) =>
      this.isPresentTrip(trip)
    ));
  }

  public deleteTrip(tripId: number): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.DIALOG_TITLE'),
      info: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CONTENT_MESSAGE'),
      cancelTitle: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CANCEL_TITLE'),
      confirmTitle: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CONFIRMATION_TITLE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.tripService.deleteTrip(tripId)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.router.navigateByUrl(this.bootstrapService.link.trips));
  }

  public getInsurance(): void {
    this.router.navigateByUrl(this.bootstrap.link.insuranceLanding);
  }

  public onImageLoadError(): void {
    this.countryImagePath = this.countyImageService.getDefaultImagePath();
  }

  public getDestinationsPointsList(destinationsPoints: DestinationPoint[]): string {
    return destinationsPoints.map((destinationsPoint: DestinationPoint) =>
      destinationsPoint.description
    ).join(' | ');
  }

  private isUserPremiumDuringFutureTrip(trip: TripDetails): Observable<boolean> {
    return this.apiMembershipService.getUserProfileMemberships().pipe(
      map((memberships: PremiumDetail[]) => this.membershipService
        .joinMemberships(memberships)
        .some((membership: PremiumDetail) => {
          if (membership?.deleted) return false;

          return (new Date(membership?.startDate) <= new Date(trip.startDate))
            && (new Date(membership?.endDate) >= new Date(trip.endDate));
        })));
  }

  private isPresentTrip(trip: TripDetails): boolean {
    const now = new Date();
    return now > new Date(trip.startDate) && !endDatePassed(trip.endDate);
  }

  private isFutureTrip(trip: TripDetails): boolean {
    const now = new Date();
    return now < new Date(trip.startDate);
  }

  private isPastTrip(trip: TripDetails): boolean {
    return endDatePassed(trip.endDate);
  }

}
