import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Input, OnInit } from '@angular/core';
import { CTAAction } from '@common/model/cTAAction';
import { CTADisableableLink } from '@common/model/cTADisableableLink';
import { CTAHideableLink } from '@common/model/cTAHideableLink';
import { CTALink } from '@common/model/cTALink';
import { UserService } from '@common/user/user.service';
import { ButtonComponent } from '../button/button/button.component';
import { CTAHideableExtendedLink } from '@common/model/cTAHideableExtendedLink';
import { DataParams } from "@kit/utils/router.utils";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const NOT_ROUTE_LINK_REGEXP = /^(mailto|tel|http|https):/;

@Component({
  selector: 'app-aem-button',
  templateUrl: './aem-button.component.html',
  styleUrls: ['./aem-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemButtonComponent implements OnInit {
  @Input() public cta: CTALink | CTAAction | CTAHideableExtendedLink;
  @Input() public type: ButtonComponent['type'] | 'tag' = 'normal';
  @Input() public size: ButtonComponent['size'] = 'l';

  ActionEnum = CTAAction.ActionEnum;
  isDisabled = false;

  @HostBinding('class.hidden')
  hidden: boolean = false;

  @HostBinding('attr.id')
  get bindElementId() {
    return this.cta.id;
  }

  @HostBinding('class.inherit')
  get hasInherit() {
    return this.size === 'inherit';
  }

  get ctaLink(): CTALink {
    if (this.cta._type !== 'CTAAction' &&
      this.cta._type !== 'CTAHideableExtendedLink' &&
      this.cta._type !== 'CTACopyIdAction') {

      return this.cta as CTALink;
    }

    return null;
  }

  get ctaAction(): CTAAction {
    if (this.cta._type === 'CTAAction') {
      return this.cta as CTAAction;
    }

    return null;
  }

  get ctaHideableExtendedLink(): CTAHideableExtendedLink {
    if (this.cta._type === 'CTAHideableExtendedLink') {
      return this.cta as CTAHideableExtendedLink;
    }

    return null;
  }

  get ctaCopyIdAction(): any {
    if (this.cta._type === 'CTACopyIdAction') {
      return this.cta;
    }

    return null;
  }

  get isRoute(): boolean {
    return !NOT_ROUTE_LINK_REGEXP.test(this.ctaLink.link);
  }

  get link(): string {
    return this.ctaLink.link.split('?')[0];
  }

  get queryParams(): DataParams {
    return this.ctaLink.link.split('?')[1]
      ?.split('&')
      ?.reduce((a: DataParams, v: string) => ({ ...a, [v.split('=')[0]]: v.split('=')[1] }), {});
  }

  constructor(
    private userService: UserService,
    private destroyRef: DestroyRef,
  ) {
  }

  ngOnInit(): void {
    this.type = this.cta.buttonType || this.type;
    this.size = this.cta.buttonSize || this.size;

    if ((this.cta as CTAHideableLink).entitlements) {
      this.userService.userSubscription$.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(userSubscription => {
          if (this.cta._type === 'CTAHideableLink' || this.cta._type === 'CTAHideableExtendedLink' || this.cta._type === 'CTAAction') {
            const cta = this.cta as CTAHideableLink | CTAHideableExtendedLink | CTAAction;

            this.hidden = cta.entitlements && !cta.entitlements[userSubscription];
          }

          if (this.cta._type === 'CTADisableableLink') {
            this.isDisabled = (this.cta as CTADisableableLink).entitlements[userSubscription] === false;
          }
        });
    }
  }
}
