import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ServiceNotification, ServiceNotificationName, ServiceNotificationType } from "../../notification-options.consts";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-service-notifications',
  templateUrl: './service-notifications.component.html',
  styleUrls: ['./service-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceNotificationsComponent {
  @Input() notification: ServiceNotificationName;
  @Input() set options(value: ServiceNotification[]) {
    this.options$.next(value);
  };

  @Output() updateValue = new EventEmitter<ServiceNotificationType>();

  public options$ = new BehaviorSubject<ServiceNotification[]>([]);

  public transferUpdatedValue(value: ServiceNotificationType): void {
    this.updateValue.emit(value);
  }
}
