import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ThematicLinks } from '@common/blog/blog.interface';
import { BlogArticle } from '@common/model/blogArticle';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesComponent implements OnChanges {
  @Input() article: BlogArticle;
  @Input() thematicLinks: ThematicLinks;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.article) {
      this.article.thematics.sort((a, b) => a.title.localeCompare(b.title));
    }
  }
}
