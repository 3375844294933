import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  HostBinding,
  Input
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldWrapperComponent implements AfterViewInit {
  @Input() public label = '';
  @Input() public clarification = '';
  @Input() public required = false;
  @Input() public hint: string;

  @ContentChild(NgControl)
  public control: NgControl;

  @HostBinding('class.form-field_label-disabled')
  public get disabled(): boolean {
    return this.control?.disabled;
  }

  @HostBinding('class.form-field_label-invalid')
  public get invalid(): boolean {
    return this.control?.invalid;
  }

  @HostBinding('class.form-field_label-touched')
  public get touched(): boolean {
    return this.control?.touched;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    ) { }

  ngAfterViewInit(): void {
    if (this.control) {
      this.control.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.cdr.markForCheck();
      })
    }
  }
}
