<div class="select form-field"
     [class.select_icon]="!!icon"
     [class.form-field_focused]="expanded"
     [class.form-field_disabled]="disabled"
     [ngClass]="['select-theme__' + theme]"
     (activeZoneChange)="changeActiveState($event)">
  <div class="select_wrapper" [class.select_wrapper_icon]="!!icon" (click)="toggleOption()">
    <app-icon *ngIf="icon"
              [icon]="icon"
              size="x-large">
    </app-icon>
    <input readonly
           class="select_input text-small"
           [class.text-small]="!icon"
           [placeholder]="placeholder"
           [value]="selectedOption && selectedOption[viewKey]">
    <div class="select__action">
      <app-icon *ngIf="!disabled && clearButton && selectedView"
                (click)="clear($event)"
                class="select__action-icon icon"
                icon="close"
                size="large"
                theme="lighten">
      </app-icon>
      <app-icon class="select__action-icon select__action-toggle icon"
                icon="chevron-down"
                size="large"
                theme="lighten">
      </app-icon>
    </div>
  </div>

  <div class="select_options text-tiny" *ngIf="expanded">
    <ng-container *ngIf="options?.length; else emptyPlaceholder">
      <div *ngFor="let option of options"
           class="select_options-item"
           [class.active]="option === selectedOption"
           (click)="select(option)">
        <div class="select_options-item-content">
          {{ option[viewKey] }}
        </div>
      </div>
    </ng-container>

    <ng-template #emptyPlaceholder>
      <div class="select_options-item-empty">
        {{ 'forms.CREATE_TRIP.PLACEHOLDERS.EMPTY_SEARCH_RESULT' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="expanded"
     class="select_toggle-area">
</div>
