import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { forkJoin, map, Observable, take } from 'rxjs';
import { PremiumDetail } from '@pages/admin/components/user-edit/premium-details.interface';
import { dateToEndDay } from '@kit/utils/date.utils';
import { MembershipService } from '@pages/membership/membership.service';
import { DynamicContent } from '@pages/dynamic/dynamic-render/dynamic-render.interface';
import { UserService } from '@common/user/user.service';
import { DynamicRenderService } from '@pages/dynamic/dynamic-render/dynamic-render.service';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { InsuranceBasedContentWrapper } from '@common/model/insuranceBasedContentWrapper';
import { UserType } from '@common/profile/profile.interfaces';
import { ApiMembershipService } from '@common/membership/api-membership.service';

@Component({
  selector: 'app-insurance-based-content-wrapper',
  templateUrl: './insurance-based-content-wrapper.component.html',
  styleUrls: ['./insurance-based-content-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceBasedContentWrapperComponent extends AemFragmentComponent implements OnInit {
  public dynamicContent$: Observable<DynamicContent[]>;

  constructor(
    private readonly membershipService: MembershipService,
    private readonly apiMembershipService: ApiMembershipService,
    private readonly userService: UserService,
    private readonly dynamicRenderService: DynamicRenderService,
    @Inject(AEM_DATA) public override data: InsuranceBasedContentWrapper,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.initDynamicComponents();
  }

  private checkUserInsurance$(): Observable<boolean> {
    return this.apiMembershipService.getUserProfileMemberships().pipe(
      map((memberships: PremiumDetail[]) => {
        const currentDate = new Date();

        return this.membershipService.joinMemberships(memberships)
          .some((membership: PremiumDetail) => (new Date(membership?.startDate).getTime() <= currentDate.getTime())
            && (dateToEndDay(membership?.endDate).getTime() >= currentDate.getTime()))
      })
    );
  }

  private initDynamicComponents(): void {
    this.dynamicContent$ = forkJoin([
      this.userService.userSubscription$.pipe(take(1)),
      this.checkUserInsurance$().pipe(take(1)),
    ]).pipe(
      map(([userSubscription, hasInsurance]: [UserType, boolean]) => {
        const fragment = hasInsurance ? [this.data.hasInsurance] : [this.data.hasNoInsurance];

        return this.dynamicRenderService.prepareContent(fragment, userSubscription);
      })
    );
  }
}
