import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCotravelersService } from '@common/co-travelers/api-cotravelers.service';
import { catchError, EMPTY, Observable } from 'rxjs';
import { RootPaths } from 'src/app/app.const';
import { CoTraveler } from 'src/app/common/profile/profile.interfaces';

@Injectable({
  providedIn: 'root',
})
export class CoTravelerResolver  {
  constructor(
    private apiCotravelersService: ApiCotravelersService,
    private router: Router
  ) {}

  resolve(): Observable<CoTraveler[]> {
    return this.apiCotravelersService.getCotravelers().pipe(
      catchError((err) => {
        this.router.navigate([RootPaths.NotFound], { skipLocationChange: true });

        console.error(err);
        return EMPTY;
      })
    );
  }
}
