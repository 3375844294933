import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, map, Observable, of } from "rxjs";
import { LanguageService } from "@common/language/language.service";
import { WoosmapAutocompleteData, WoosmapDetails } from "@kit/woosmap-search/woosmap-search.interface";
import { AuthServerConfig } from '@common/auth/auth.interfaces';
import { SERVER_AUTH_DATA_STATE } from '@common/start-up/start-up.const';
import { TransferState } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class WoosmapSearchService {
  private readonly _authConfig: AuthServerConfig = this.transfer.get(SERVER_AUTH_DATA_STATE, null);

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private readonly transfer: TransferState,
  ) { }

  public autocomplete(input: string): Observable<WoosmapAutocompleteData[]> {
    const params = new URLSearchParams();

    params.append('input', input);
    params.append('key', this._authConfig.woosmapApiKey);
    params.append('types', 'country|locality');
    params.append('language', this.languageService.getCurrentLanguage().toLowerCase());

    return this.http.get<{ localities: WoosmapAutocompleteData[]}>(`https://api.woosmap.com/localities/autocomplete?${params.toString()}`)
      .pipe(
        map( ({ localities }) =>  localities),
        catchError(() => of([])),
      );
  }

  public getDetails(input: string): Observable<WoosmapDetails> {
    const params = new URLSearchParams();

    params.append('public_id', input);
    params.append('key', this._authConfig.woosmapApiKey);

    return this.http.get<{ result: WoosmapDetails}>(`https://api.woosmap.com/localities/details?${params.toString()}`)
      .pipe(map(({ result }) => result));
  }
}
