import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  forkJoin,
  map,
  Observable,
  switchMap,
  throwError
} from 'rxjs';
import { ApiProfileService } from 'src/app/common/profile/api-profile.service';
import { normalizeUserProfileForm } from 'src/app/common/profile/profile.mapper';
import { UserService } from 'src/app/common/user/user.service';
import { DialogService } from 'src/app/kit/dialog/dialog.service';
import { ResultDialogComponent } from 'src/app/kit/dialog/result-dialog/result-dialog.component';
import { ResultDialogData } from 'src/app/kit/dialog/result-dialog/result-dialog.interface';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { NON_SPACE_REGEX, PASSWORD_REGEX } from 'src/app/kit/password/password.const';
import { codeToPattern } from 'src/app/kit/phone/phone.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { notBlankValidator, repeatPasswordGroupValidator } from 'src/app/kit/utils/validators';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { UserProfile } from '@common/profile/profile.interfaces';
import { CREDENTIAL_REGEXP } from '../co-travelers/co-travelers.const';
import { getAllFormErrors } from "@kit/utils/form";
import { RegistrationService } from "@common/registration/registration.service";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService],
})
export class ManageAccountComponent implements OnInit {
  public userInfoForm: UntypedFormGroup;
  public passwordForm: UntypedFormGroup;
  public newPasswordForm: UntypedFormGroup;
  public disableButton: Observable<boolean>;
  private userInfo: UserProfile;

  public get userDataChanged(): boolean {
    return this.userInfoForm.dirty;
  }

  public get passwordChanged(): boolean {
    const newPassword = this.newPasswordForm.get('newPassword').value;
    return !!newPassword;
  }

  public get passwordFormValid(): boolean {
    return !this.passwordForm.invalid && !this.newPasswordForm.invalid;
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly destroyRef: DestroyRef,
    private readonly validationService: ServerErrorsValidationService,
    private readonly profileService: ApiProfileService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
    private readonly cdr: ChangeDetectorRef,
    private readonly analyticsService: AnalyticsService,
  ) { }

  public ngOnInit(): void {
    this.initUserInfoForm();
    this.initPasswordForm();
    this.initUserInfo();
  }

  private initUserInfoForm(): void {
    this.userInfoForm = this.fb.group({
      email: [{ value: null, disabled: true }],
      firstName: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('firstName'),
      ],
      lastName: [
        null,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('lastName'),
      ],
      phone: [
        " ",
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
          wrapValidator(Validators.pattern(codeToPattern(this.userService.location)), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('phone'),
      ],
    }, {
      asyncValidators: [this.validationService.createValidator()]
    });
  }

  private initPasswordForm() {
    const passwordControl = this.fb.control(null, null, this.validationService.createValidator('password'));
    const newPasswordControl = this.fb.control(null, [
      wrapValidator(Validators.pattern(PASSWORD_REGEX), 'errors.CEAZ091'),
      wrapValidator(Validators.pattern(NON_SPACE_REGEX), 'errors.CEAZ058'),
      wrapValidator(Validators.minLength(8), 'errors.CEAZ091'),
      wrapValidator(Validators.maxLength(32), 'errors.CEAZ090'),
      wrapValidator((control) => passwordControl.value ? Validators.required(control) : null, 'errors.CEAZ000_NotBlank')
    ]);
    const repeatPasswordControl = this.fb.control(
      null,
      wrapValidator((control) => passwordControl.value ? Validators.required(control) : null, 'errors.CEAZ000_NotBlank')
    );

    this.newPasswordForm = this.fb.group({
      newPassword: newPasswordControl,
      repeatPassword: repeatPasswordControl,
    });

    this.passwordForm = this.fb.group({
      password: passwordControl,
      newPasswordGroup: this.newPasswordForm,
    });

    const passwordValidator = (): ValidationErrors | null => {
      return newPasswordControl.value?.trim()
        ? Validators.required(passwordControl)
        : null;
    };

    this.passwordForm.addValidators(wrapValidator(
      passwordValidator,
      'errors.CEAZ000_NotBlank'
    ));

    this.newPasswordForm.addValidators(wrapValidator(
      repeatPasswordGroupValidator(newPasswordControl, repeatPasswordControl),
      'errors.CEAZ026'
    ));

    const previousPassValidator = (control: AbstractControl) =>
      control?.value &&
      control.value === passwordControl.value
        ? { repeat: true }
        : null;

    newPasswordControl.addValidators(wrapValidator(previousPassValidator, 'errors.CEAZ059'));

    newPasswordControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => passwordControl.markAllAsTouched());

    passwordControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        newPasswordControl.updateValueAndValidity();
        repeatPasswordControl.updateValueAndValidity();
      });
  }

  private initUserInfo(): void {
    this.profileService.getUserProfile().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(userInfo => {
      this.userInfoForm.get('phone').setValue(null);
      this.userInfoForm.patchValue(userInfo, { emitEvent: false });
      this.userInfoForm.markAsPristine();
      this.userInfo = userInfo;
      this.initSaveButtonState();
    });
  }

  public saveChanges(): void {
    this.userInfoForm.markAllAsTouched();

    if (this.userDataChanged) this.userInfoForm.updateValueAndValidity();

    if (!this.userInfoForm.invalid && this.passwordFormValid) {
      const user = normalizeUserProfileForm(this.userInfoForm);

      const requests: Observable<unknown>[] = [];

      if (this.userDataChanged) requests.push(this.profileService.editUserProfile(user));
      if (this.passwordChanged) requests.push(this.getPasswordUpdateRequest());

      forkJoin(requests).pipe(
        catchError(error => {
          return this.validationService.handleServerError(error?.error)
        }),
        delay(1000),
        switchMap(() => this.userService.reloadUserInfo$()),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => {
        this.dialogService.open(ResultDialogComponent, <ResultDialogData>{
          title: this.translateService.instant('pages.MANAGE_MY_ACCOUNT.DIALOG.TITLE'),
          message: this.translateService.instant('pages.MANAGE_MY_ACCOUNT.DIALOG.MESSAGE'),
          closeTitle: this.translateService.instant('pages.MANAGE_MY_ACCOUNT.DIALOG.ACTION')
        });

        this.userInfoForm.markAsUntouched();
        this.userInfoForm.markAsPristine();
        this.passwordForm.reset();
        this.passwordForm.markAsUntouched();
        this.passwordForm.markAsPristine();
        this.cdr.markForCheck();
      });

      return;
    }

    this.analyticsService.validationError(this.userInfoForm, this.constructor.name);
  }

  private getPasswordUpdateRequest(): Observable<void> {
    const password = this.passwordForm.get('password').value;
    const newPassword = this.newPasswordForm.get('newPassword').value;

    return this.registrationService.updatePassword(this.userInfo.email, password, newPassword)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return throwError(() => this.updateAndReturnErrorResponse(error));
          }

          return throwError(() => error);
        })
      );
  }

  private initSaveButtonState(): void {
    const userFormInvalid$ = new BehaviorSubject<boolean>(false);
    const passwordFormInvalid$ = new BehaviorSubject<boolean>(false);
    const newPasswordFormInvalid$ = new BehaviorSubject<boolean>(false);

    this.userInfoForm.valueChanges
      .pipe(
        map(() => !!getAllFormErrors(this.userInfoForm)?.length),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((state: boolean) => userFormInvalid$.next(state));

    this.passwordForm.valueChanges
      .pipe(
        map(() => !!getAllFormErrors(this.passwordForm)?.length),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((state: boolean) => passwordFormInvalid$.next(state));

    this.newPasswordForm.valueChanges
      .pipe(
        map(() => !!getAllFormErrors(this.newPasswordForm)?.length),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((state: boolean) => newPasswordFormInvalid$.next(state));

    this.disableButton = combineLatest([
      userFormInvalid$,
      passwordFormInvalid$,
      newPasswordFormInvalid$,
    ]).pipe(
      map(([userFormInvalid, passwordFromInvalid, newPasswordFormInvalid]) => {
        const formsPristine = this.userInfoForm.pristine && this.passwordForm.pristine && this.newPasswordForm.pristine;

        return userFormInvalid || passwordFromInvalid || newPasswordFormInvalid || formsPristine;
      })
    )
  }

  private updateAndReturnErrorResponse(error: HttpErrorResponse): HttpErrorResponse {
    return {
      ...error,
      error: {
        ...error.error,
        fault: {
          errors: [{
            description: 'error.CEAZ025',
            errorCode: 'CEAZ025',
            fieldName: 'password',
          }]
        },
      },
    }
  }
}
