<div class="shell">
  <app-back-button (goBack)="back()" class="back-button"></app-back-button>
  <ng-container [ngSwitch]="step">

    <app-main-notifications *ngSwitchCase="notificationSteps.MAIN"
                            [notifications]="notifications"
                            (changeStep)="saveMainStep($event)">
    </app-main-notifications>

    <app-marketing-notifications *ngSwitchCase="notificationSteps.MARKETING"
                                 [notifications]="notifications.ALLYZ.consentTypes"
                                 (toggleMarketing)="switchMarketingNotification($event)">
    </app-marketing-notifications>

    <app-service-notifications *ngSwitchCase="notificationSteps.TRIP_ALERTS"
                               [notification]="notificationName.TRIP_ALERTS"
                               [options]="tripAlertsOptions$ | async"
                               (updateValue)="updateNotificationValue($event, servicePartner.CRISIS24)">
    </app-service-notifications>

    <app-service-notifications *ngSwitchCase="notificationSteps.FLIGHT_ALERTS"
                               [notification]="notificationName.FLIGHT_ALERTS"
                               [options]="flightAlertsOptions$ | async"
                               (updateValue)="updateNotificationValue($event, servicePartner.AMADEUS)">
    </app-service-notifications>

    <app-service-notifications *ngSwitchCase="notificationSteps.LOUNGE_VOUCHERS"
                               [notification]="notificationName.LOUNGE_VOUCHERS"
                               [options]="loungeVouchersOptions$ | async"
                               (updateValue)="updateNotificationValue($event, servicePartner.COLLINSON)">
    </app-service-notifications>

    <app-service-notifications *ngSwitchCase="notificationSteps.FLIGHT_COMPENSATION"
                               [notification]="notificationName.FLIGHT_COMPENSATION"
                               [options]="flightCompensationOptions$ | async"
                               (updateValue)="updateNotificationValue($event, servicePartner.FLIGHT_RIGHT)">
    </app-service-notifications>

    <app-language-notifications *ngSwitchCase="notificationSteps.NOTIFICATION_SETTING">
    </app-language-notifications>

  </ng-container>
</div>

