<div class="shell wrapper">
  <ng-container *ngIf="data.designType !== 'us-landing'; else linksWithQR">
    <div class="app-buttons">
      <div *ngIf="data.appleApplicationLink" class="app-button">
        <a [href]="data.appleApplicationLink" target="_blank" id="apple-app-link">
          <img [src]="appleStoreImagePath" class="app-button-apple">
        </a>
      </div>
      <div *ngIf="data.androidApplicationLink" class="app-button">
        <a [href]="data.androidApplicationLink" target="_blank" id="google-app-link">
          <img [src]="googlePlayImagePath" class="app-button-google">
        </a>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #linksWithQR>
  <div [class.separate-block]="data.title" class="links-wrapper">
    <p *ngIf="data.title" class="h3 m-h2 links-wrapper_title">{{ data.title }}</p>
    <div *ngIf="data.text" class="t-normal m-h3">{{ data.text }}</div>
    <div class="app-buttons-qr-codes">
      <div *ngIf="data.appleApplicationLink" class="app-button">
        <a [href]="data.appleApplicationLink" target="_blank" id="apple-app-link">
          <img [src]="appleStoreImagePath" class="app-button-apple">
        </a>
      </div>
      <div *ngIf="data.appleApplicationQrCode" class="app-qr">
        <img [src]="data.appleApplicationQrCode" id="apple-app-qr" class="app-qr_image">
        <p>Scan to download</p>
      </div>
      <div class="separator"></div>
      <div *ngIf="data.androidApplicationLink" class="app-button">
        <a [href]="data.androidApplicationLink" target="_blank" id="google-app-link">
          <img [src]="googlePlayImagePath" class="app-button-google">
        </a>
      </div>
      <div *ngIf="data.androidApplicationQrCode" class="app-qr">
        <img [src]="data.androidApplicationQrCode" id="google-app-qr" class="app-qr_image">
        <p>Scan to download</p>
      </div>
    </div>
  </div>
</ng-template>
