export const environment = {
  production: true,
  ssrStaticDirectoryPath: '/usr/src/app/allyz-web-app/browser',
  origin: 'http://localhost:8080',
  appUrlPrefix: 'https://',
  dictionaryPath: '/assets/translations/',
  altairScriptPath: 'https://widgets.allianz-partners.com/awc',
  altairScriptConfig: 'https://widgets.allianz-partners.com/awc/static/configurations/config_Allyz_Altair.json',
  teleconsultationWidgetPath: 'https://widget.myhealthathand.com/form?widget=allyz&domain=hah',
  sspWidgetPath: 'https://ssp-fe-prod.allianz-protection.com',
  oneClaimUrl: 'https://allianz-protection.com/claims-ux-frontend-webapp',
  freshWorksAdapter: 'https://pro-allianz-partners.apis.allianz.com/External/CyberCare-Freshworks-Adapter/1.0',
  helBffURL: 'https://pro-allianz-partners.apis.allianz.com/HEL-bff/1.0',
  api: {
    baseUrl: 'https://pro-allianz-partners.apis.allianz.com/Allyz-bff/1.0',
    publicCustomer: 'https://pro-allianz-partners.apis.allianz.com/External/Allyz-customer/1.0',
    publicInformation: 'https://pro-allianz-partners.apis.allianz.com/External/Allyz-information/1.0',
    publicBookingPath: 'https://pro-allianz-partners.apis.allianz.com/External/Allyz-booking-path/1.0',
    anonymousAuth: 'https://pro-allianz-partners.apis.allianz.com/oauth',
    oidcProxy: 'https://pro-allianz-partners.apis.allianz.com/itmp-oidc/v1/',
    forgeRock: 'https://account.allyz.com',
    aemPath: 'https://crs.allyz.com/v1',
    aemAdminPath: 'https://pro-allianz-partners.apis.allianz.com/Allyz-bff/1.0/admin',
    publicProfile: 'https://pro-allianz-partners.apis.allianz.com/External/Allyz-profile/1.0',
    allianzProtection: 'https://allianz-protection.com/login-path',
  },
  medi24: {
    en: 'https://personal-assistant.medi24.com/',
    fr: 'https://personal-assistant.france.medi24.com/',
    de: 'https://personal-assistant.de.medi24.com/',
    it: 'https://personal-assistant.it.medi24.com/',
    es: 'https://personal-assistant.spain.medi24.com/',
    nl: 'https://personal-assistant.nl.medi24.com/',
  },
  teleconsultationPortal: {
    fr: 'https://www.allianz-voyage-teleconsultation.fr/',
    es: 'https://www.allianz-assistance-teleconsultation.es/#/'
  },
  teleconsultation: {
    it: '+390226609283',
  },
  oidcConfig: {
    response_type: 'code',
    scope: 'openid profile email user admin super.admin content.manager content.global.manager content.developer',
  },
  upgradeDialogEmail: {
    nl: 'upgrade.nl@allyz.freshdesk.com',
  },
  previewFeature: false,
  appStore: {
    fr: 'https://apps.apple.com/fr/app/allyz-by-allianz/id6462418576',
    de: 'https://apps.apple.com/de/app/allyz-by-allianz/id6462418576',
    nl: 'https://apps.apple.com/nl/app/allyz-by-allianz/id6462418576',
    at: 'https://apps.apple.com/at/app/allyz-by-allianz/id6462418576'
  },
  googlePlay: {
    fr: 'https://play.google.com/store/apps/details?id=com.allyz&gl=FR',
    de: 'https://play.google.com/store/apps/details?id=com.allyz&gl=DE',
    nl: 'https://play.google.com/store/apps/details?id=com.allyz&gl=NL',
    at: 'https://play.google.com/store/apps/details?id=com.allyz&gl=AT'
  },
  name: 'PROD',
};
