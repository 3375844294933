<section class="destinations" *ngIf="countryName">
  <div class="shell">
    <div class="destinations__header" #destinationsContainer>
      <h2 class="destinations__title"
          [innerHtml]="data.component.title.replace('{country}', '<span class=destinations__title_country-name>'+ countryName +'<span>')">
      </h2>
    </div>
    <div class="destinations__grid" *ngIf="destination">
      <div *let="(isAuth$ | async) as isAuth"
           class="destinations__column"
           [class.show-protection]="isAccordionExtended && !isAuth">
        <div (extendedChange)="extendedChange($event)"
             class="destinations__accordion-container"
             appAccordionContainer>
          <app-accordion *ngIf="destination.countryEntryRequirements?.length">
            <h5 class="zero-margin h5 m-h3"
                accordionTitle>{{ 'pages.DESTINATIONS.LABELS.ENTRY_REQUIREMENTS' | translate }}</h5>

            <div appAccordionContainer class="destinations__accordion-child-container">
              <app-accordion size="child" *ngFor="let requirements of destination.countryEntryRequirements">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate; context: { $implicit: requirements.status }"></ng-container>
                  <div class="accordion__title-container">
                    <div class="h2 accordion__title">{{ requirements.question }}</div>
                    <div class="accordion__title-info text-small">{{ requirements.answer }}</div>
                  </div>
                </ng-container>

                <div class="accordion-content">
                  <app-safe-html class="accordion-content__comment" [content]="requirements.comment | markdown"></app-safe-html>
                </div>
              </app-accordion>
            </div>
          </app-accordion>

          <app-accordion
            *ngIf="destination.countryCovidAndQuarantine?.length">
            <h5 class="zero-margin h5 m-h3" accordionTitle>{{ 'pages.DESTINATIONS.LABELS.COVID' | translate }}</h5>

            <div appAccordionContainer class="destinations__accordion-child-container">
              <app-accordion size="child" *ngFor="let covidAndQuarantine of destination.countryCovidAndQuarantine">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate; context: { $implicit: covidAndQuarantine.status }"></ng-container>

                  <div class="accordion__title-container">
                    <div class="h2 accordion__title">{{ covidAndQuarantine.question }}</div>
                    <div class="accordion__title-info text-small">{{ covidAndQuarantine.answer }}</div>
                  </div>
                </ng-container>

                <div class="accordion-content">
                  <app-safe-html class="accordion-content__comment" [content]="covidAndQuarantine.comment | markdown"></app-safe-html>
                </div>
              </app-accordion>
            </div>
          </app-accordion>

          <app-accordion
            *ngIf="destination.countryAlerts?.length">
            <h5 class="zero-margin h5 m-h3" accordionTitle>{{ 'pages.DESTINATIONS.LABELS.ALERTS.MAIN_TITLE' | translate }}</h5>

            <div appAccordionContainer class="destinations__accordion-child-container">
              <app-accordion size="child">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate;"></ng-container>
                  <h2 class="accordion__title">{{ 'pages.DESTINATIONS.LABELS.ALERTS.SUB_TITLE' | translate }}</h2>
                </ng-container>

                <div class="accordion-content">
                  <div class="accordion-content__item" *ngFor="let alert of destination.countryAlerts">
                    <div class="accordion-content__title">{{ alert.headline }}</div><br>
                    <div class="accordion-content__comment">{{ 'pages.DESTINATIONS.LABELS.ALERTS.DATE_OF_ALERT' | translate }} {{ (alert.updatedDate || alert.createdDate) | dateFormat }}</div>
                    <app-safe-html class="accordion-content__comment first-p-zero-top-margin" [content]="alert.alertComment | markdown"></app-safe-html>
                  </div>
                </div>
              </app-accordion>
            </div>
          </app-accordion>
          <app-accordion
            [disabled]="!isAuth" *ngIf="($any(destination.whenYouInCountry) | keyvalue)?.length">
            <h5 class="zero-margin h5 m-h3" accordionTitle>{{ 'pages.DESTINATIONS.LABELS.WHEN_YOU_IN_COUNTRY.TITLE' | translate }}</h5>

            <div appAccordionContainer class="destinations__accordion-child-container" *ngIf="isAuth">
              <app-accordion size="child" *ngIf="destination.whenYouInCountry.protectionAndSecurity">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate;"></ng-container>
                  <h2 class="accordion__title">{{ 'pages.DESTINATIONS.LABELS.WHEN_YOU_IN_COUNTRY.PROTECTION_AND_SECURITY' | translate }}</h2>
                </ng-container>

                <div class="accordion-content">
                  <div class="accordion-content__item">
                    <div class="accordion-content__title">{{ 'pages.DESTINATIONS.LABELS.WHEN_YOU_IN_COUNTRY.AREAS_TO_AVOID' | translate }}</div>
                    <app-safe-html class="accordion-content__comment first-p-zero-top-margin" [content]="destination.whenYouInCountry.protectionAndSecurity.securityArea | markdown"></app-safe-html>
                  </div>
                  <div class="accordion-content__item">
                    <div class="accordion-content__title">{{ 'pages.DESTINATIONS.LABELS.WHEN_YOU_IN_COUNTRY.EXTREME_VIOLENCE' | translate }}</div>
                    <app-safe-html class="accordion-content__comment first-p-zero-top-margin" [content]="destination.whenYouInCountry.protectionAndSecurity.securityViol | markdown"></app-safe-html>
                  </div>
                  <div class="accordion-content__item">
                    <div class="accordion-content__title">{{ 'pages.DESTINATIONS.LABELS.WHEN_YOU_IN_COUNTRY.PERSONAL_SECURITY' | translate }}</div>
                    <app-safe-html class="accordion-content__comment first-p-zero-top-margin" [content]="destination.whenYouInCountry.protectionAndSecurity.securityPersonal | markdown"></app-safe-html>
                  </div>
                  <div class="accordion-content__item">
                    <div class="accordion-content__title">{{ 'pages.DESTINATIONS.LABELS.WHEN_YOU_IN_COUNTRY.POLITICAL_INSTABILITY' | translate }}</div>
                    <app-safe-html class="accordion-content__comment first-p-zero-top-margin" [content]="destination.whenYouInCountry.protectionAndSecurity.securityPol | markdown"></app-safe-html>
                  </div>
                </div>
              </app-accordion>
              <app-accordion size="child" *ngIf="destination.whenYouInCountry.thingsToDo?.length">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate;"></ng-container>
                  <h2 class="accordion__title">{{ 'pages.DESTINATIONS.LABELS.WHEN_YOU_IN_COUNTRY.THING_TO_DO' | translate }}</h2>
                </ng-container>

                <div class="accordion-content">
                  <div class="accordion-content__item" *ngFor="let thingToDo of destination.whenYouInCountry.thingsToDo">
                    <div class="accordion-content__title accordion-content__title_todo">{{ thingToDo.question }}</div>
                    <app-safe-html class="accordion-content__comment" [content]="thingToDo.comment | markdown"></app-safe-html>
                  </div>
                </div>
              </app-accordion>

              <ng-container *ngTemplateOutlet="accordionTemplate; context: { $implicit: destination.whenYouInCountry.curfew }"></ng-container>
              <ng-container *ngTemplateOutlet="accordionTemplate; context: { $implicit: destination.whenYouInCountry.careService }"></ng-container>
              <ng-container *ngTemplateOutlet="accordionTemplate; context: { $implicit: destination.whenYouInCountry.nonEssentialStore }"></ng-container>
              <ng-container *ngTemplateOutlet="accordionTemplate; context: { $implicit: destination.whenYouInCountry.internalTransit }"></ng-container>
              <ng-container *ngTemplateOutlet="accordionTemplate; context: { $implicit: destination.whenYouInCountry.airline }"></ng-container>
              <ng-container *ngTemplateOutlet="accordionTemplate; context: { $implicit: destination.whenYouInCountry.other }"></ng-container>

              <ng-template let-item #accordionTemplate>
                <app-accordion size="child" *ngIf="item">
                  <ng-container accordionTitle>
                    <ng-container *ngTemplateOutlet="statusTemplate; context: { $implicit: item.status }"></ng-container>

                    <div class="accordion__title-container">
                      <div><h2 class="accordion__title">{{ item.question }}</h2></div>
                      <div><p class="accordion__title-info text-small">{{ item.answer }}</p></div>
                    </div>
                  </ng-container>

                  <div class="accordion-content">
                    <app-safe-html class="accordion-content__comment" [content]="item.comment | markdown"></app-safe-html>
                  </div>
                </app-accordion>
              </ng-template>
            </div>
          </app-accordion>
          <app-accordion *ngIf="destination.medicationAdvices?.countryDiseases?.length || destination.medicationAdvices?.countryVaccinations.length || destination.medicationAdvices?.countryMedications?.length"
                         [disabled]="!isAuth">
            <h5 class="zero-margin h5 m-h3" accordionTitle>{{ 'pages.DESTINATIONS.LABELS.HEALTH_AND_MEDICATION.TITLE' | translate }}</h5>

            <div appAccordionContainer class="destinations__accordion-child-container" *ngIf="isAuth">
              <app-accordion size="child" *ngIf="destination.medicationAdvices.countryDiseases.length">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate;"></ng-container>
                  <h2 class="accordion__title">{{ 'pages.DESTINATIONS.LABELS.HEALTH_AND_MEDICATION.LOCAL_DISEASES' | translate }}</h2>
                </ng-container>

                <div class="accordion-content">
                  <div class="accordion-content__item" *ngFor="let diseases of destination.medicationAdvices.countryDiseases">
                    <div class="accordion-content__title">{{ diseases.disease.commonName }} {{ diseases.disease.scientificName }}</div>
                    <app-safe-html class="accordion-content__comment" [content]="diseases.comment | markdown"></app-safe-html>
                  </div>
                </div>
              </app-accordion>
              <app-accordion size="child" *ngIf="destination.medicationAdvices.countryVaccinations.length">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate;"></ng-container>
                  <h2 class="accordion__title">{{ 'pages.DESTINATIONS.LABELS.HEALTH_AND_MEDICATION.VACCINATIONS_SUGGESTED' | translate }}</h2>
                </ng-container>

                <div class="accordion-content">
                  <div class="accordion-content__item" *ngFor="let vaccination of destination.medicationAdvices.countryVaccinations">
                    <div class="accordion-content__title">{{ vaccination.name }}</div>
                    <app-safe-html class="accordion-content__comment" [content]="vaccination.comment | markdown"></app-safe-html>
                  </div>
                </div>
              </app-accordion>
              <app-accordion size="child" *ngIf="destination.medicationAdvices.countryMedications.length">
                <ng-container accordionTitle>
                  <ng-container *ngTemplateOutlet="statusTemplate;"></ng-container>
                  <h2 class="accordion__title">{{ 'pages.DESTINATIONS.LABELS.HEALTH_AND_MEDICATION.MEDICATIONS' | translate }}</h2>
                </ng-container>

                <div class="accordion-content">
                  <div class="accordion-content__item" *ngFor="let medication of destination.medicationAdvices.countryMedications">
                    <div class="accordion-content__title">{{ medication.name }}</div>
                    <app-safe-html class="accordion-content__comment" [content]="medication.comment | markdown"></app-safe-html>
                  </div>
                </div>
              </app-accordion>
            </div>
          </app-accordion>
        </div>
      </div>
      <div class="destinations__column destinations__column_babble" *ngIf="bubbles?.length">
        <div class="destinations__info-form" *ngFor="let bubble of bubbles">
          <app-icon class="destinations__info-icon" [icon]="bubble.icon" theme="amped" size="inherit" type="bubble"></app-icon>
          <div class="destinations__info-form_title">
            {{ bubble.title }}
          </div>
          <span class="destinations__info-form_text">
            {{ bubble.text }}
          </span>
          <app-aem-button *ngIf="bubble.cta" [cta]="bubble.cta" size="m" type="full-width"></app-aem-button>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template let-status #statusTemplate>
  <ng-container [ngSwitch]="status">
    <app-icon *ngSwitchCase="distinctionStatus.RED" class="icon-status icon-status_red" icon="error" theme="inherit" size="inherit"></app-icon>
    <app-icon *ngSwitchCase="distinctionStatus.GREEN" class="icon-status icon-status_green" icon="done" theme="inherit" size="inherit"></app-icon>
    <app-icon *ngSwitchCase="distinctionStatus.YELLOW" class="icon-status icon-status_yellow" icon="error" theme="inherit" size="inherit"></app-icon>
    <app-icon *ngSwitchCase="distinctionStatus.CORONAVIRUS" class="icon-status icon-status_coronavirus" icon="coronavirus" theme="inherit" size="inherit"></app-icon>

    <app-icon *ngSwitchDefault class="icon-status icon-status_info" icon="info-fill" theme="inherit" size="inherit"></app-icon>
  </ng-container>
</ng-template>
