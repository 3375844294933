import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  DestroyRef,
  ChangeDetectorRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, EMPTY, Observable } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { notBlankValidator } from 'src/app/kit/utils/validators';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { MaskedEmail, PolicyValidationData } from '../../policy.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ServerValidationError } from '@kit/server-errors/server-errors.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-insurance-number-input',
  templateUrl: './insurance-number-input.component.html',
  styleUrls: ['./insurance-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService]
})
export class InsuranceNumberInputComponent implements OnInit {
  @Input() public contractNumber: string = null;
  @Input() public reverification: boolean = false;

  @Output() complete = new EventEmitter<PolicyValidationData>();
  @Output() boscoloValidationError = new EventEmitter<string>();
  @Output() leclercValidationError = new EventEmitter<string>();

  public policyForm: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly validationService: ServerErrorsValidationService,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public readonly bootstrapService: BootstrapService,
  ) {
  }

  public ngOnInit(): void {
    this.initForm();

    if (this.reverification) {
      this.policyForm.get('shareAccept').setValue(true);
      this.onSubmit();
    }
  }

  private initForm(): void {
    this.policyForm = this.fb.group({
      contractNumber: [
        this.contractNumber,
        [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        ],
        this.validationService.createValidator('policyNumber')
      ],
      shareAccept: [
        false,
        wrapValidator(Validators.requiredTrue, 'errors.CEAZ011'),
      ],
    });

    this.policyForm.addAsyncValidators(this.validationService.createValidator());
  }

  public onSubmit(): void {
    this.policyForm.markAllAsTouched();
    const { contractNumber, shareAccept } = this.policyForm.getRawValue();

    if (this.policyForm.invalid || !shareAccept) {
      return;
    }

    this.policyService.verifyContract$({ contractNumber: contractNumber.trim() }).pipe(
      catchError(err => this.handlePolicyVerificationErrors(err, contractNumber.trim())),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private handlePolicyVerificationErrors(err: HttpErrorResponse, contractNumber: string): Observable<never> {
    const isBoscoloValidationError: boolean = err?.error?.fault?.errors.some((err: ServerValidationError) =>
      (err.errorCode === 'CEAZ135' || err.fieldName === 'firstName, lastName, bookingReference'));
    const isLeclercValidationError: boolean = err?.error?.fault?.errors.some((err: ServerValidationError) =>
      (err.errorCode === 'CEAZ133' || err.fieldName === 'firstName, lastName, dateOfBirth'));
    const isEmailValidationError: boolean = err.status === 449 && err?.error?.fault?.errors.some((err: ServerValidationError) =>
      (err.errorCode === 'CEAZ127'));

    if (isBoscoloValidationError) this.boscoloValidationError.emit(contractNumber);
    if (isLeclercValidationError) this.leclercValidationError.emit(contractNumber);
    if (isEmailValidationError) {
      const maskedEmails: MaskedEmail[] = JSON.parse(err.error?.fault?.errors[0]?.fieldName)?.maskedEmails;

      if (maskedEmails?.length === 1) {

        this.policyService.sendPinToEmail$(contractNumber, maskedEmails[0].id).subscribe();
      }

      this.complete.emit({ contractNumber: contractNumber, maskedEmails: maskedEmails, selectedEmail: maskedEmails[0] });
    }

    this.validationService.handleServerError(err?.error);
    this.changeDetectorRef.detectChanges();

    return EMPTY;
  }
}
