<section class="section">
  <div class="shell" [formGroup]="emailForm">
    <div class="select-email">
      <div class="select-email__title h5 m-h3">
        {{ 'pages.MY_INSURANCE.TITLES.SELECT_EMAIL' | translate }}
      </div>
      <div class="select-email__text t-normal">
        <app-safe-html
          [content]="reverification
          ? ('pages.MY_INSURANCE.DESCRIPTIONS.SELECT_EMAIL_REVERIFICATION' | translate)
          : ('pages.MY_INSURANCE.DESCRIPTIONS.SELECT_EMAIL' | translate)">
        </app-safe-html>
      </div>
      <div *ngFor="let email of emails$ | async; index as i" (click)="buttonIndex = i"
        [class.checked]="buttonIndex === i" class="select-email__radio">
        <app-radio-button [value]="email.email" formControlName="selectEmail">
          <p class="select-email__email weight-medium">{{ email.email }}</p>
        </app-radio-button>
      </div>
      <div class="select-email__button">
        <app-button class="select-email__button" type="full-width" (click)="onSubmit()"
          [disabled]="isButtonDisable$ | async">
          {{ 'pages.MY_INSURANCE.ACTIONS.NEXT' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</section>
