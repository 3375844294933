import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  forwardRef,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, filter, map, startWith } from "rxjs/operators";
import { SelectComponent } from '../select/select.component';
import { timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true
    },
  ],
})
export class SearchSelectComponent<T extends { [key: string]: any }> extends SelectComponent<T> implements OnInit {
  @Output() blurEvent = new EventEmitter<void>();

  searchControl = new UntypedFormControl('');
  filteredOptions$ = this.searchControl.valueChanges.pipe(
    startWith(''),
    debounceTime(100),
    map((value) => this.filterOptions(this.options, value))
  );

  constructor(
    cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      filter((value) => value && value !== this.selectedView && !this.expanded),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.expanded = true;
    });
  }

  public onBlur(): void {
    this.blurEvent.emit()
    timer(200).pipe(
      filter(() => this.searchControl.value !== this.selectedView),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.searchControl.setValue(this.selectedView);
    });
  }

  override clear(event: Event): void {
    super.clear(event);

    this.searchControl.setValue(null);
  }

  override select(option: T): void {
    super.select(option);
    this.searchControl.setValue(this.selectedView);
  }

  override writeValue(value: unknown): void {
    super.writeValue(value);
    this.searchControl.setValue(this.selectedView);
  }

  private filterOptions(options: T[], criteria: string): T[] {
    return options.filter(option => option[this.viewKey]?.toLowerCase()?.includes(criteria?.toLowerCase()))
  }
}
