import { AemHeroBlockModule } from '@kit/aem-hero-block/aem-hero-block.module';
import { AemUniversalHeroModule } from '@kit/aem-universal-hero/aem-universal-hero.module';
import { AemButtonModule } from '@kit/aem-button/aem-button.module';
import { AemServiceCardsModule } from '@kit/aem-service-cards/aem-service-cards.module';
import { AemAdditionalServicesModule } from '@kit/aem-additional-services/aem-additional-services.module';
import { AemEmergencyNumberModule } from '@kit/aem-emergency-number/emergency-number.module';
import { AemTitledImageModule } from '@kit/aem-titled-image/aem-titled-image.module';
import { AemDestinationHeroModule } from '@kit/aem-destination-hero/destination-hero.module';
import { AemSideBySideWithCtaModule } from '@kit/aem/common/side-by-side-with-cta/side-by-side-with-cta.module';
import { AemStepByStepWithCtaModule } from '@kit/aem/common/step-by-step/step-by-step.module';
import {
  AemCarouselCardsWithIconModule
} from '@kit/aem/common/carousel-cards-with-icon/carousel-cards-with-icon.module';
import { AemHomeHeroModule } from '@kit/aem/common/home-hero/home-hero.module';
import { AemContentBlockModule } from '@kit/aem/common/content-block/content-block.module';
import { AemQuoteModule } from '@kit/aem/common/quote/quote.module';
import { AemImageBlockModule } from '@kit/aem/common/image-block/image-block.module';
import { AemCtaBlockModule } from '@kit/aem/common/cta-block/cta-block.module';
import { AemFileDownloadsModule } from '@kit/aem/common/file-downloads/file-downloads.module';
import { AemContentAndImageModule } from '@kit/aem/common/content-and-image/content-and-image.module';
import { AemContentAndVideoModule } from '@kit/aem/common/content-and-video/content-and-video.module';
import { AemQuoteAndImageModule } from '@kit/aem/common/quote-and-image/quote-and-image.module';
import { AemSurveyBannerModule } from '@kit/aem/common/survey-banner/survey-banner.module';
import { AemFaqModule } from '@kit/aem/common/faq/faq.module';
import { AemCodeSnippetModule } from '@kit/aem/common/code-snippet/code-snippet.module';
import { AemHeroOnlyTextModule } from '@kit/aem/common/aem-hero-only-text/aem-hero-only-text.module';
import { AemCardsWithTextModule } from '@kit/aem/common/cards-with-text/cards-with-text.module';
import { AemRowArticleModule } from '@kit/aem/common/row-article/row-article.module';
import { AemColumnModule } from '@kit/aem/common/column/column.module';
import { AemShareBlockModule } from '@kit/aem/common/share-block/share-block.module';
import { AemArticleListModule } from '@kit/aem/common/articles-list/articles-list.module';
import { AemRelatedArticlesModule } from '@kit/aem/common/related-articles/related-articles.module';
import {
  AemRelatedArticlesCarouselModule
} from '@kit/aem/common/related-articles-carousel/related-articles-carousel.module';
import { AemBlogHeroModule } from '@kit/aem/common/aem-blog-hero/aem-blog-hero.module';
import { AemInsuranceCarrouselModule } from '@kit/aem/common/insurance-carrousel/insurance-carrousel.module';
import { AemDynamicWrapperBlockModule } from '@kit/aem-dynamic-wrapper/aem-dynamic-wrapper.module';
import { AemEmbeddedPlayerModule } from '@kit/aem/common/aem-embedded-player/aem-embedded-player.module';
import { AemVideoPlayerModule } from '@kit/aem/common/aem-video-player/aem-video-player.module';
import { AltairWidgetModule } from '@kit/altair-widget/altair-widget.module';
import {
  RoleBasedContentWrapperModule
} from '@kit/aem/common/role-based-content-wrapper/role-based-content-wrapper.module';
import {
  UserTypeContentWrapperModule
} from '@kit/aem/common/user-type-content-wrapper/user-type-content-wrapper.module';
import { AemRichTextModule } from '@kit/aem/common/aem-rich-text/aem-rich-text.module';
import { AemImageModule } from '@kit/aem/common/aem-image/aem-image.module';
import { AemContactFormModule } from '@kit/aem/common/aem-contact-form/aem-contact-form.module';
import { SspWidgetModule } from '@kit/ssp-widget/ssp-widget.module';
import {
  InsuranceBasedContentWrapperModule
} from '@kit/aem/common/insurance-based-content-wrapper/insurance-based-content-wrapper.module';
import { LumiWidgetModule } from '@kit/lumi-widget/lumi-widget.module';
import { SeoBlockModule } from '@kit/seo-block/seo-block.module';
import { SideBySideModule } from '@kit/side-by-side/side-by-side.module';
import { MembershipPlansModule } from '@kit/membership-plans/membership-plans.module';
import { GradientCardsContentModule } from '@kit/aem/common/gradient-cards-content/gradient-cards-content.module';
import { MobileAppContentCardsModule } from '@kit/aem/common/mobile-app-content-cards/mobile-app-content-cards.module';
import { MobileApplicationLinksModule } from '@kit/aem/common/mobile-application-links/mobile-application-links.module';

export enum RootPaths {
  NotFound = 'not-found',
  Forbidden = 'forbidden',
  Admin = 'admin',
  NotFoundVisa = 'en-fr/not-found',
  ForbiddenVisa = 'en-fr/forbidden',
}

export const DISABLE_GLOBAL_SCROLL_CLASS = 'disable-global-scroll';

export const AEM_IMPORTS = [
  AemHeroBlockModule,
  AemUniversalHeroModule,
  AemButtonModule,
  AemServiceCardsModule,
  AemAdditionalServicesModule,
  AemEmergencyNumberModule,
  AemTitledImageModule,
  AemDestinationHeroModule,
  AemSideBySideWithCtaModule,
  AemStepByStepWithCtaModule,
  AemCarouselCardsWithIconModule,
  AemHomeHeroModule,
  AemContentBlockModule,
  AemQuoteModule,
  AemImageBlockModule,
  AemCtaBlockModule,
  AemFileDownloadsModule,
  AemContentAndImageModule,
  AemContentAndVideoModule,
  AemQuoteAndImageModule,
  AemSurveyBannerModule,
  AemFaqModule,
  AemCodeSnippetModule,
  AemHeroOnlyTextModule,
  AemCardsWithTextModule,
  AemRowArticleModule,
  AemColumnModule,
  AemShareBlockModule,
  AemArticleListModule,
  AemRelatedArticlesModule,
  AemRelatedArticlesCarouselModule,
  AemBlogHeroModule,
  AemInsuranceCarrouselModule,
  AemDynamicWrapperBlockModule,
  AemEmbeddedPlayerModule,
  AemVideoPlayerModule,
  AltairWidgetModule,
  RoleBasedContentWrapperModule,
  UserTypeContentWrapperModule,
  AemRichTextModule,
  AemImageModule,
  AemContactFormModule,
  SspWidgetModule,
  InsuranceBasedContentWrapperModule,
  LumiWidgetModule,
  SeoBlockModule,
  SideBySideModule,
  MembershipPlansModule,
  GradientCardsContentModule,
  MobileAppContentCardsModule,
  MobileApplicationLinksModule,
];
