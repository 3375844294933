import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, DestroyRef
} from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { ActivationCode } from '@common/profile/profile.interfaces';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '@common/language/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiActivationCodesService } from '@common/activation-codes/api-activation-codes.service';

@Component({
  selector: 'app-chat-with-doctor',
  templateUrl: './chat-with-doctor.component.html',
  styleUrls: ['./chat-with-doctor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWithDoctorComponent {
  public showMessage = false;

  constructor(
    private readonly apiActivationCodesService: ApiActivationCodesService,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly languageService: LanguageService,
  ) { }

  public onClick(): void {
    this.apiActivationCodesService.getActivationCode()
      .pipe(
        map((data: ActivationCode) => this.getLink(data.activationCodeValue, data.country.code)),
        catchError(() => of(null)),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(link => {
      if (!link) {
        this.showMessage = true;
        this.cdr.markForCheck();
      } else {
        window.open(link, '_blank');
      }
    });
  }

  private getLink(activationCode: string, countryCode: string): string {
    const medi24Url: string = (<any>environment.medi24)[countryCode.toLowerCase()] as string;

    if (medi24Url) {
      const url = new URL(medi24Url);

      url.searchParams.set('lang', this.languageService.languageCode.toLowerCase());
      url.searchParams.set('ac', activationCode);

      return url.href;
    }

    throw 'Unknown language for doctor chat';
  }
}
