import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { DialogService } from '@kit/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';

@Component({
  selector: 'app-teleconsultation',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeleconsultationComponent {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
  ) {}

  @HostListener('click')
  openDialog(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.TELECONSULTATION.POPUP.TITLE'),
      info: this.translateService.instant('pages.TELECONSULTATION.POPUP.TEXT'),
      warning: this.translateService.instant('pages.TELECONSULTATION.POPUP.WARNING'),
      reverseButtonPosition: true,
      cancelTitle: this.translateService.instant('pages.TELECONSULTATION.POPUP.CANCEL'),
      confirmTitle: this.translateService.instant('pages.TELECONSULTATION.POPUP.OK'),
      icon: 'big-blue-info',
      bigIcon: true,
      centredMessage: true,
    }).afterClosed$.subscribe((value: string) => {
      if (value === ConfirmationDialogComponent.CONFIRM) {
        this.router.navigateByUrl(this.bootstrap.link.teleconsultationBooking);
      }
    });
  }
}
