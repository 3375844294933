import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MembershipsValidationResponse } from "@common/profile/profile.interfaces";
import { PremiumDetail, PremiumPeriodRequestData, PremiumPeriodResponseData } from "@pages/admin/components/user-edit/premium-details.interface";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiMembershipService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getProfileMemberships(profileId: string): Observable<PremiumDetail[]> {
    return this.http.get<PremiumDetail[]>(`${this.baseUrl}/profiles/${profileId}/memberships`);
  }

  public createProfileMembership(profileId: string, data: Partial<PremiumDetail>): Observable<PremiumDetail> {
    return this.http.post<PremiumDetail>(`${this.baseUrl}/profiles/${profileId}/memberships`, data);
  }

  public editProfileMembership(profileId: string, data: PremiumDetail): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(`${this.baseUrl}/profiles/${profileId}/memberships/${data.id}`, data);
  }

  public deleteProfileMembership(profileId: string, membershipId: number): Observable<{ id: number }> {
    return this.http.delete<{ id: number }>(`${this.baseUrl}/profiles/${profileId}/memberships/${membershipId}`);
  }

  public getPremiumPeriod(requestData: PremiumPeriodRequestData): Observable<PremiumPeriodResponseData> {
    const params: URLSearchParams = new URLSearchParams();

    params.append('upgradeReason', requestData.upgradeReason);
    if (requestData.promoCode) params.append('promoCode', requestData.promoCode);
    if (requestData.startDate) params.append('startDate', requestData.startDate);
    if (requestData.endDate) params.append('endDate', requestData.endDate);

    return this.http.get<PremiumPeriodResponseData>(`${this.baseUrl}/memberships/premium-period?${params.toString()}`);
  }

  public validateMemberships(countryCode: string, file: File): Observable<MembershipsValidationResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<MembershipsValidationResponse>(`${this.baseUrl}/memberships/validate/${countryCode}`, formData);
  }

  public importMemberships(countryCode: string, fileId: string): Observable<{uri: string}> {
    return this.http.post<{uri: string}>(`${this.baseUrl}/memberships/import/${countryCode}/${fileId}`, {});
  }

  public getUserProfileMemberships(): Observable<PremiumDetail[]> {
    return this.http.get<PremiumDetail[]>(`${this.baseUrl}/profile/memberships`);
  }
}