import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Inject, LOCALE_ID, NgModule, Optional, TransferState } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from '@common/auth/auth.module';
import { AuthService } from '@common/auth/auth.service';
import { GlobalErrorHandlerService } from '@common/global-error-handler/global-error-handler.service';
import { HttpConfigInterceptor } from '@common/http/http-config.interceptor';
import { ApiInformationService } from '@common/information/api-information.service';
import { AnonymousInformationService } from '@common/information/information.anonymous.service';
import { InformationService } from '@common/information/information.service';
import { LanguageService } from '@common/language/language.service';
import { Bootstrap, Breadcrumbs, RouterConfigurations } from './common/model/models';
import {
  SERVER_BOOTSTRAP_DATA, SERVER_BOOTSTRAP_DATA_STATE, SERVER_BREADCRUMBS_DATA,
  SERVER_BREADCRUMBS_DATA_STATE, SERVER_ROUTING_DATA, SERVER_ROUTING_DATA_STATE
} from '@common/start-up/start-up.const';
import { StartUpService } from '@common/start-up/start-up.service';
import { UserService } from '@common/user/user.service';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { dateFormatFactory, FormatDate } from '@kit/date/date-formats/date-formats.factory';
import { DateModule } from '@kit/date/date.module';
import { DialogModule } from '@kit/dialog/dialog.module';
import { FooterModule } from '@kit/footer/footer.module';
import { GlobalLoaderModule } from '@kit/global-loader/global-loader.module';
import { ToolbarModule } from '@kit/toolbar/toolbar.module';
import { IS_SERVER_PLATFORM } from '@kit/utils/ssr.utils';
import { AccountModule } from '@pages/account/account.module';
import { DynamicModule } from '@pages/dynamic/dynamic.module';
import { TripModule } from '@pages/trip/trip.module';
import { dateLocaleFactory } from '@kit/date/date-locale.factory';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeAt from '@angular/common/locales/de-AT';
import localePl from '@angular/common/locales/pl';
import localeAu from '@angular/common/locales/en-AU';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ApiPromoCodeService } from '@common/admin/promocode/api-promo-code.service';
import { AnonymousPromoCodeService } from '@common/admin/promocode/promo-code.anonymous.service';
import { PromoCodeService } from '@common/admin/promocode/promo-code.service';
import { AEM_COMPONENTS_MAP } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { AEM_COMPONENTS } from './aem-components-map.const';
import { TranslationKeysSwitcherModule } from "@kit/translation-keys-switcher/translation-keys-switcher.module";
import { AemAirDoctorHeroModule } from '@kit/aem-air-doctor-hero/aem-air-doctor-hero.module';
import { LegalConsentHttpInterceptor } from "@common/legal-consent-http/legal-consent-http.interceptor";
import { HEL_WIDGET_BASE_URL } from '@allianz/hel-admin-widgets';
import { environment } from '../environments/environment';
import { AEM_IMPORTS } from './app.const';

registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localeNl);
registerLocaleData(localeEs);
registerLocaleData(localeEn);
registerLocaleData(localeAt);
registerLocaleData(localePl);
registerLocaleData(localeAu);

@NgModule({
  declarations: [
    AppComponent,
    AemBaseBlockComponent,
  ],
  imports: [
    ...AEM_IMPORTS,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ToolbarModule,
    FooterModule,
    TranslateModule,
    DialogModule,
    DynamicModule,
    AuthModule,
    BrowserAnimationsModule,
    DateModule,
    TripModule,
    GlobalLoaderModule,
    AccountModule,
    TranslationKeysSwitcherModule,
    AemAirDoctorHeroModule, // deprecated
  ],
  providers: [
    {
      provide: FormatDate,
      useFactory: dateFormatFactory,
      deps: [TranslateService],
    },
    {
      provide: LOCALE_ID,
      useFactory: dateLocaleFactory,
      deps: [LanguageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LegalConsentHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: ApiPromoCodeService,
      useFactory: (auth: AuthService, service: PromoCodeService, anonymousService: AnonymousPromoCodeService) => {
        return auth.authorized
          ? service
          : anonymousService;
      },
      deps: [AuthService, PromoCodeService, AnonymousPromoCodeService]
    },
    {
      provide: ApiInformationService,
      useFactory: (auth: AuthService, service: InformationService, anonymousService: AnonymousInformationService) => {
        return auth.authorized
          ? service
          : anonymousService;
      },
      deps: [AuthService, InformationService, AnonymousInformationService]
    },
    {
      provide: AEM_COMPONENTS_MAP,
      useValue: AEM_COMPONENTS(),
    },
    LanguageService,
    TranslateService,
    { provide: HEL_WIDGET_BASE_URL, useValue: environment.helBffURL }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly transfer: TransferState,
    private readonly startUpService: StartUpService,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
    @Optional() @Inject(SERVER_BOOTSTRAP_DATA) private readonly bootstrap: Bootstrap,
    @Optional() @Inject(SERVER_BREADCRUMBS_DATA) private readonly breadcrumbs: Breadcrumbs,
    @Optional() @Inject(SERVER_ROUTING_DATA) private readonly routesConfig: RouterConfigurations,
  ) {
    if (this.isServer) {
      this.startUpService.initApp(this.bootstrap, this.breadcrumbs, this.routesConfig);
      this.router.initialNavigation();
    } else {
      this.startUpService.initApp(
        this.transfer.get(SERVER_BOOTSTRAP_DATA_STATE, null),
        this.transfer.get(SERVER_BREADCRUMBS_DATA_STATE, null),
        this.transfer.get(SERVER_ROUTING_DATA_STATE, null),
      );

      this.userService.userDataInitialized$
        .pipe(take(1))
        .subscribe(() => {
          this.router.initialNavigation();
        });
    }

    const lang: string = this.languageService.languageCode.toLowerCase();

    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }
}
