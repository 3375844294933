import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { wrapValidator } from "@kit/field-error/field-error.utils";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { rangeValidator } from "@kit/utils/validators";
import { ServerErrorsValidationService } from "@kit/server-errors/server-errors-validation.service";
import { Observable } from "rxjs";
import { Country } from "@pages/trip/trip.interfaces";
import { ApiInformationService } from "@common/information/api-information.service";
import { getAllFormErrors } from "@kit/utils/form";
import { AnalyticsService } from "@common/analytics/analytics.service";
import { UserService } from "@common/user/user.service";
import { CreateTripSource } from "@pages/trip/components/create-trip/create-trip.interface";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-edit-trip-form',
  templateUrl: './edit-trip-form.component.html',
  styleUrls: ['./edit-trip-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTripFormComponent implements OnInit {
  @Input() validationService: ServerErrorsValidationService;
  @Input() showNotificationContainer: boolean;
  @Input() showNotificationCheckbox: boolean;
  @Input() tripData: any | null = null;

  @Output() creteTrip = new EventEmitter<CreateTripSource>();
  @Output() editTrip = new EventEmitter<CreateTripSource>();
  @Output() deleteTrip = new EventEmitter<void>();

  public tripForm: FormGroup;
  public countries$: Observable<Country[]> = this.informationService.getCountries();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly informationService: ApiInformationService,
    private readonly analyticsService: AnalyticsService,
    private readonly userService: UserService,
    private readonly destroyRef: DestroyRef,
  ) { }

  public ngOnInit(): void {
    this.initForm();
    this.addFormValidators();
    this.addFormAsyncValidators();
    this.addFormControlsValidators();
    this.addFormControlsAsyncValidators();
    this.setDepartureCountryByUser();
  }

  public createTripHandler(): void {
    this.tripForm.markAllAsTouched();

    if (getAllFormErrors(this.tripForm)?.length) {
      this.analyticsService.validationError(this.tripForm, this.constructor.name);

      return;
    }

    this.creteTrip.emit(this.tripForm.getRawValue());
  }

  public editTripHandler(): void {
    this.tripForm.markAllAsTouched();

    if (getAllFormErrors(this.tripForm)?.length) {
      this.analyticsService.validationError(this.tripForm, this.constructor.name);

      return;
    }

    this.editTrip.emit(this.tripForm.getRawValue());
  }

  public deleteTripHandler(): void {
    this.deleteTrip.emit();
  }

  private initForm(): void {
    this.tripForm = this.formBuilder.group({
      departureCountry: [this.tripData?.departureCountry || null],
      destinationPoints: [this.tripData?.destinationPoints || []],
      name: [this.tripData?.name || null],
      from: [this.tripData ? new Date(this.tripData.startDate) : null],
      to: [this.tripData ? new Date(this.tripData.endDate) : null],
      subscribedOnNotifications: [false],
    });
  }

  private addFormValidators(): void {
    this.tripForm.addValidators(wrapValidator(
      rangeValidator(this.tripForm.get('from'), this.tripForm.get('to')),
      'errors.CEAZ001'
    ));
  }

  private addFormAsyncValidators(): void {
    this.tripForm.addAsyncValidators(this.validationService.createValidator());
  }

  private addFormControlsValidators(): void {
    this.tripForm.get('departureCountry')
      .addValidators(wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'));
    this.tripForm.get('destinationPoints')
      .addValidators(wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'));
    this.tripForm.get('from')
      .addValidators(wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'));
    this.tripForm.get('to')
      .addValidators(wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'));
  }

  private addFormControlsAsyncValidators(): void {
    this.tripForm.get('departureCountry').addAsyncValidators([
      this.validationService.createValidator('departureCountry'),
      this.validationService.createValidator('departureCountry.countryId'),
    ]);
    this.tripForm.get('destinationPoints').addAsyncValidators([
      this.validationService.createValidator('destinationPoints'),
    ]);
    this.tripForm.get('from').addAsyncValidators([
      this.validationService.createValidator('startDate'),
    ]);
    this.tripForm.get('to').addAsyncValidators([
      this.validationService.createValidator('endDate'),
    ]);
  }

  private setDepartureCountryByUser(): void {
    if (this.tripData?.departureCountry) return;

    this.countries$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((countries: Country[]) => {
      this.tripForm.get('departureCountry').setValue(this.getUserCountry(countries));
    });
  }

  private getUserCountry(countries: Country[]): Country {
    return countries.find(
      ({ code }: Country) => code.toLowerCase() === this.userService.location?.toLowerCase(),
    );
  }
}
