import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, delay, filter, finalize, Observable, switchMap, tap } from 'rxjs';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { ApiInformationService } from '@common/information/api-information.service';
import { FormFlightAddManually } from '@common/model/formFlightAddManually';
import { ApiFlightService } from '@common/flight/api-flight.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { GlobalLoaderService } from '@kit/global-loader/global-loader.service';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { setRouterData } from '@kit/utils/router.utils';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { mapFlightDtoToFlightInfo, mapFlightSourceToDto } from '../../../flight.mapper';
import { AddFlightManuallyComponent } from '../add-flight-manually/add-flight-manually.component';
import { getAllFormErrors } from "@kit/utils/form";
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-flight-edit',
  templateUrl: './flight-edit.component.html',
  styleUrls: ['./flight-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService],
})
export class FlightEditComponent extends AddFlightManuallyComponent implements AfterViewInit {
  private flightId: number = this.route.firstChild.snapshot.params.flightId;
  private returnToTripWallet = this.route.firstChild.snapshot.queryParams.returnToTripWallet;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    formBuilder: UntypedFormBuilder,
    informationService: ApiInformationService,
    analyticsService: AnalyticsService,
    router: Router,
    route: ActivatedRoute,
    bootstrapService: BootstrapService,
    flightService: ApiFlightService,
    validationService: ServerErrorsValidationService,
    destroyRef: DestroyRef,
    public override loaderService: GlobalLoaderService,
    @Inject(AEM_DATA) data: FormFlightAddManually,
  ) {
    super(
      formBuilder,
      informationService,
      analyticsService,
      router,
      route,
      bootstrapService,
      flightService,
      validationService,
      destroyRef,
      loaderService,
      data,
    );
  }

  ngAfterViewInit(): void {
    const flight = this.route.firstChild.snapshot.data.flight;
    this.flightForm.patchValue(mapFlightSourceToDto(flight));
  }

  override goBack(): void {
    if (this.returnToTripWallet) {
      this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet, { tripId: this.tripId }));

      return;
    }

    this.router.navigateByUrl(setRouterData(this.bootstrapService.link.existingFlightsPage, { tripId: this.tripId }));
  }

  public onSave(): void {
    this.flightForm.markAllAsTouched();

    if (getAllFormErrors(this.flightForm)?.length) {
      this.analyticsService.validationError(this.flightForm, this.constructor.name);
      return;
    }

    const flight = mapFlightDtoToFlightInfo(this.flightForm.value);
    const { tripId, flightId } = this.route.firstChild.snapshot.params;

    this.flightService.updateFlight(tripId, flightId, flight).pipe(
      tap(() => this.loaderService.show()),
      delay(3000),
      catchError(err => this.handleServerError(err)),
      finalize(() => this.loaderService.hide()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => this.goBack());
  }

  public onDelete(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DELETE_FLIGHT'),
      info: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DISCLAIMER_DELETE'),
      cancelTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.CANCEL'),
      confirmTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.DELETE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.flightService.deleteFlight(this.tripId, this.flightId)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.goBack();
    });
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }
}
