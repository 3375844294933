import { Inject, Injectable } from '@angular/core';
import { RegisterDTO } from "@common/auth/auth.interfaces";
import { catchError, EMPTY, Observable, of, switchMap, throwError } from "rxjs";
import {
  fillForgeRockCallback,
  ForgeRockCallBack,
  ForgeRockCallBackItemType,
  ForgeRockCallBackType,
  mapFRValidationErrors,
  setFRCallbackValue
} from "@kit/utils/forge-rock.utils";
import { ResidenceMap } from "@common/auth/auth.const";
import { Params } from "@angular/router";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { LanguageService } from "@common/language/language.service";
import { getCountryCode } from "@common/language/language.const";
import { ApiPromoCodeService } from "@common/admin/promocode/api-promo-code.service";
import { ServerErrorsValidationService } from "@kit/server-errors/server-errors-validation.service";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private readonly registrationJourney = 'GenericUserRegistrationV3';
  private readonly activationResendJourney = 'LostActivationCode';
  private readonly updatePasswordJourney = 'AllyzUpdatePassword';
  private readonly forgeRockUrl = `${environment.api.forgeRock}/am/json/realms/root/realms/alpha/authenticate`;
  private readonly registrationUrl = `${this.forgeRockUrl}?authIndexType=service&authIndexValue=${this.registrationJourney}`;
  private readonly activationResendUrl = `${this.forgeRockUrl}?authIndexType=service&authIndexValue=${this.activationResendJourney}`;
  private readonly updatePasswordUrl = `${this.forgeRockUrl}?authIndexType=service&authIndexValue=${this.updatePasswordJourney}`;

  constructor(
    private readonly http: HttpClient,
    private readonly langService: LanguageService,
    private readonly apiPromoCodeService: ApiPromoCodeService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  public register(source: RegisterDTO): Observable<void> {
    // remove after rework on CAM side
    const language = this.langService.countryCode.toUpperCase() === 'AT' ? 'de-AT' : this.langService.languageCode.toUpperCase()
    const valuesMap = new Map<ForgeRockCallBackItemType, unknown>([
      [ForgeRockCallBackItemType.CreateUsernameCallback, source.email],
      [ForgeRockCallBackItemType.CreateFirstNameCallback, source.firstName],
      [ForgeRockCallBackItemType.CreateLastNameCallback, source.lastName],
      [ForgeRockCallBackItemType.PasswordCallback, source.password],
      [ForgeRockCallBackItemType.CountryCallback, ResidenceMap.get(this.langService.countryCode as any)],
      [ForgeRockCallBackItemType.LanguageCallback, language],
      [ForgeRockCallBackItemType.PhoneCallback, source.phoneNumber],
      [ForgeRockCallBackItemType.PromotionCallback, source.promocode || ''],
      [ForgeRockCallBackItemType.PlatformIdCallback, '1'],
      [ForgeRockCallBackItemType.RegistrationSourceCallback, 'allyz.com'],
      [ForgeRockCallBackItemType.OwnerNameCallback, 'allyz.com'],
      [ForgeRockCallBackItemType.RedirectionUrlCallback, this.getRedirectionUrl()],
      [ForgeRockCallBackItemType.MarketingConsentCallback, source.marketingConsent],
    ]);

    return this.http.post<ForgeRockCallBack>(this.registrationUrl, null).pipe(
      switchMap((callback) => this.http.post<ForgeRockCallBack>(this.registrationUrl, fillForgeRockCallback(valuesMap, callback))),
      switchMap((callback) => {
        if (callback.callbacks?.length < valuesMap.size) {
          return of(null);
        }

        return throwError(mapFRValidationErrors(callback?.callbacks));
      }),
    );
  }

  public activateUserAccount(activationParams: Params): Observable<void> {
    return this.http.post<any>(this.forgeRockUrl, null, {
      params: activationParams,
    });
  }

  public resendActivationLink(email: string): Observable<void> {
    const valuesMap = new Map<ForgeRockCallBackItemType, unknown>([
      [ForgeRockCallBackItemType.CreateUsernameCallback, email],
    ]);

    return this.http.post<ForgeRockCallBack>(this.activationResendUrl, null).pipe(
      switchMap((callback) => this.http.post<void>(this.activationResendUrl, fillForgeRockCallback(valuesMap, callback)))
    );
  }

  public updatePassword(email: string, oldPassword: string, newPassword: string): Observable<void> {
    return this.http.post<ForgeRockCallBack>(this.updatePasswordUrl, null).pipe(
      switchMap((callback) => {
        setFRCallbackValue(callback, { type: ForgeRockCallBackType.NameCallback }, email);
        setFRCallbackValue(callback, { type: ForgeRockCallBackType.PasswordCallback }, oldPassword);

        return this.http.post<ForgeRockCallBack>(this.updatePasswordUrl, callback);
      }),
      switchMap((callback) => {
        setFRCallbackValue(callback, { type: ForgeRockCallBackType.ValidatedCreatePasswordCallback }, newPassword);

        return this.http.post<void>(this.updatePasswordUrl, callback);
      }),
    );
  }

  public validatePromoCode(promoCode: string, validationService: ServerErrorsValidationService): Observable<void> {
    const countryCode = getCountryCode(this.document.location?.host);

    return this.apiPromoCodeService.validatePromoCode(promoCode.trim(), countryCode).pipe(

      catchError(error => {
        validationService.handleServerError(error?.error);

        return EMPTY;
      }),
    )
  }

  private getRedirectionUrl(): string {
    const host = this.document.location?.host;
    const langParam = this.langService.localizationCode === 'en-FR' ? '/en-fr' : '';

    if (host.includes('localhost')) {
      const countryCode = getCountryCode(this.document.location?.host).toLowerCase();

      return `https://ng-nonprod-${countryCode}.allyz.com${langParam}?action=login`;
    }

    return `https://${host}/${langParam}?action=login`;
  }
}
