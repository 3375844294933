import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  NgZone,
  OnInit,
} from '@angular/core';
import { AccountNotification, MarketingPartner, ServicePartner } from '@common/profile/profile.interfaces';
import { NotificationService } from "@pages/account/components/notification/notification.service";
import { BehaviorSubject, take } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import {
  ConsentType,
  FLIGHT_ALERTS_OPTIONS,
  FLIGHT_COMPENSATION_OPTIONS,
  LOUNGE_VOUCHERS_OPTIONS,
  NotificationsSteps,
  RegroupedNotifications,
  ServiceNotification,
  ServiceNotificationName,
  ServiceNotificationType,
  TRIP_ALERTS_OPTIONS
} from './notification-options.consts';
import { DOCUMENT } from "@angular/common";
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemNotificationSettingsComponent implements OnInit {
  public notificationSteps = NotificationsSteps;
  public step: NotificationsSteps = NotificationsSteps.MAIN;
  public notificationName = ServiceNotificationName;
  public servicePartner = ServicePartner;
  public consentType = ConsentType;
  public tripAlertsOptions$: BehaviorSubject<ServiceNotification[]> =
    new BehaviorSubject(this.getTranslationsForOptionName(TRIP_ALERTS_OPTIONS));
  public flightAlertsOptions$: BehaviorSubject<ServiceNotification[]> =
    new BehaviorSubject(this.getTranslationsForOptionName(FLIGHT_ALERTS_OPTIONS));
  public loungeVouchersOptions$: BehaviorSubject<ServiceNotification[]> =
    new BehaviorSubject(this.getTranslationsForOptionName(LOUNGE_VOUCHERS_OPTIONS));
  public flightCompensationOptions$: BehaviorSubject<ServiceNotification[]> =
    new BehaviorSubject(this.getTranslationsForOptionName(FLIGHT_COMPENSATION_OPTIONS));
  public notifications: RegroupedNotifications;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly bootstrapService: BootstrapService,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }

  public ngOnInit(): void {
    this.initNotifications();
  }

  public back(): void {
    if (this.step === NotificationsSteps.MAIN) {
      return this.router.navigate([this.bootstrapService.link.welcome]) as unknown as void;
    }

    this.step = NotificationsSteps.MAIN;
    this.resetScroll();
    this.cdr.markForCheck();
  }

  public saveMainStep(step: NotificationsSteps): void {
    this.step = step;
    this.resetScroll();
    this.cdr.markForCheck();
  }

  public updateNotificationValue(value: ServiceNotificationType, partnerName: ServicePartner) {
    const payload = [
      {
        consentType: ConsentType.SMS,
        consentValue: value.includes(ServiceNotificationType.SMS),
        partnerName: partnerName
      },
      {
        consentType: ConsentType.EMAIL,
        consentValue: value.includes(ServiceNotificationType["E-MAIL"]),
        partnerName: partnerName
      },
      {
        consentType: ConsentType.PUSH,
        consentValue: this.notifications[partnerName].consentTypes.includes(ServiceNotificationType.PUSH),
        partnerName: partnerName
      }
    ]

    if (value === ServiceNotificationType.NONE) {
      this.unsubscribeServiceNotification(payload, partnerName);

      return;
    }

    this.saveNotification(payload, partnerName);
  }

  public switchMarketingNotification(consentType: ConsentType): void {
    const consentValue = this.notifications.ALLYZ.consentTypes.includes(consentType);
    const payload = [
      {
        partnerName: MarketingPartner.ALLYZ,
        consentType: consentType,
        consentValue: !consentValue
      }
    ];

    if (!consentValue) {
      this.saveNotification(payload, MarketingPartner.ALLYZ);
    } else {
      this.unsubscribeFlow(payload, MarketingPartner.ALLYZ);
    }
  }

  private initNotifications(): void {
    this.notificationService.userNotificationSettings$.pipe(
      takeUntilDestroyed(this.destroyRef)
    )
      .subscribe(data => {
        this.notifications = data.reduce((acc: any, item) => {

          if (!acc[item.partnerName]) {
            acc[item.partnerName] = {
              consentTypes: [],
              isBlocked: !!item.isBlocked
            };
          }

          if (item.consentValue) {
            acc[item.partnerName].consentTypes.push(item.consentType);
          }

          return acc;
        }, {});

        this.updateSelectsStates();
      });
  }

  private getTranslationsForOptionName(notifications: ServiceNotification[]): ServiceNotification[] {
    const clonedNotifications = [...notifications];
    clonedNotifications.forEach(notification => notification.name = this.translateService.instant(notification.name));

    return clonedNotifications;
  }

  private updateSelectedValues(notificationOptions: ServiceNotification[], settings: string[]): ServiceNotification[] {
    if (settings.includes(ConsentType.EMAIL) && settings.includes(ConsentType.SMS)) {
      return notificationOptions.map(item => {
        item.selected = item.value === ServiceNotificationType['E-MAIL_AND_SMS'];

        return item;
      });
    }

    if (settings.includes(ConsentType.EMAIL)) {
      return notificationOptions.map(item => {
        item.selected = item.value === ServiceNotificationType['E-MAIL_ONLY'];

        return item;
      });
    }

    if (settings.includes(ConsentType.SMS)) {
      return notificationOptions.map(item => {
        item.selected = item.value === ServiceNotificationType['SMS_ONLY'];

        return item;
      });
    }

    return notificationOptions.map(item => {
      item.selected = item.value === ServiceNotificationType['NONE'];

      return item;
    })
  }

  private saveNotification(payload: AccountNotification[], partnerName: ServicePartner | MarketingPartner): void {
    this.notificationService.saveNotificationSettings$(payload, partnerName)
      .pipe(take(1))
      .subscribe();
  }

  private unsubscribeFlow(payload: AccountNotification[], partnerName: ServicePartner | MarketingPartner): void {
    this.notificationService.unsubscribeFromNotification(payload, partnerName)
      .pipe(take(1))
      .subscribe();
  }

  private unsubscribeServiceNotification(payload: AccountNotification[], partnerName: MarketingPartner | ServicePartner): void {
    this.notificationService.unsubscribeServiceNotification$(payload, partnerName)
      .pipe(take(1))
      .subscribe();
  }

  private resetScroll(): void {
    this.zone.runOutsideAngular(() => requestAnimationFrame(
      () => this.document.scrollingElement.scrollTop = 0
    ));
  }

  private updateSelectsStates(): void {
    this.tripAlertsOptions$.next(
      this.updateSelectedValues(this.tripAlertsOptions$.getValue(), this.notifications.CRISIS24.consentTypes)
    );
    this.flightAlertsOptions$.next(
      this.updateSelectedValues(this.flightAlertsOptions$.getValue(), this.notifications.AMADEUS.consentTypes)
    );
    this.loungeVouchersOptions$.next(
      this.updateSelectedValues(this.loungeVouchersOptions$.getValue(), this.notifications.COLLINSON.consentTypes)
    );
    this.flightCompensationOptions$.next(
      this.updateSelectedValues(this.flightCompensationOptions$.getValue(), this.notifications.FLIGHT_RIGHT.consentTypes)
    );

    this.cdr.detectChanges();
  }
}
