import { Component, DestroyRef, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, shareReplay, switchMap } from 'rxjs';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { AuthService } from '@common/auth/auth.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { AccountDetails } from '@common/model/accountDetails';
import { ApiProfileService } from '@common/profile/api-profile.service';
import { UserProfile } from '@common/profile/profile.interfaces';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { AppStorage } from '@kit/utils/ssr.utils';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const DELETED_ACCOUNT_EMAIL_KEY = '';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class AemMyAccountComponent extends AemBaseBlockComponent {
  public profile$: Observable<UserProfile> = this.profileService.getUserProfile().pipe(
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly auth: AuthService,
    private readonly storage: AppStorage,
    public readonly bootstrapService: BootstrapService,
    public readonly profileService: ApiProfileService,
    public readonly analyticsService: AnalyticsService,
    @Inject(AEM_DATA) data: AccountDetails,
  ) {
    super(data);
  }

  public onDelete(): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: null,
      message: this.translateService.instant('pages.MANAGE_MY_ACCOUNT.DELETE_ACCOUNT_DIALOG.MESSAGE'),
      info: this.translateService.instant('pages.MANAGE_MY_ACCOUNT.DELETE_ACCOUNT_DIALOG.INFO'),
      confirmTitle: this.translateService.instant('pages.MANAGE_MY_ACCOUNT.DELETE_ACCOUNT_DIALOG.CONFIRM_TITLE'),
      cancelTitle: this.translateService.instant('pages.MANAGE_MY_ACCOUNT.DELETE_ACCOUNT_DIALOG.CANCEL_TITLE'),
      reverseButtonPosition: true,
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.profileService.deleteUserProfile()),
      switchMap(() => this.profile$),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((profile) => {
      this.storage.setItem(DELETED_ACCOUNT_EMAIL_KEY, profile.email);

      this.analyticsService.triggerAction({
        category: 'member_account',
        action: 'accountDeleted',
        label: '',
        value: 1,
      });

      this.auth.logOut();
    });
  }
}
