import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { getCountryCode, Market } from "@common/language/language.const";
import { NotificationsSteps } from "../../notification-options.consts";

@Component({
  selector: 'app-main-notifications',
  templateUrl: './main-notifications.component.html',
  styleUrls: ['./main-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNotificationsComponent {
  @Input() notifications: any;
  @Output() changeStep = new EventEmitter<NotificationsSteps>();
  
  public showLanguageNotifications: boolean = getCountryCode(this.document.location.host) === Market.FR;
  public notificationSteps = NotificationsSteps;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  public proceedStep(step: NotificationsSteps): void {
    this.changeStep.emit(step);
  }
}
