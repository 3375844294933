<div class="shell">
  <div class="notification__header">
    <h1 class="notification__header__text">{{ 'pages.NOTIFICATION.TITLES.' + notification | translate }}</h1>
  </div>
  <div class="notification__subtitle">
    <span class="notification__subtitle__text">{{ 'pages.NOTIFICATION.SUBTITLES.' + notification | translate }}</span>
  </div>
  <div class="notification">
    <app-service-notification-dropdown [serviceNotificationOptions]="options$ | async"
                                       (updateValue)="transferUpdatedValue($event)">
    </app-service-notification-dropdown>
  </div>
</div>
