import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit, Input, DestroyRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { catchError, EMPTY, Observable } from 'rxjs';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { notBlankValidator } from 'src/app/kit/utils/validators';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { MaskedEmail, MaskedEmails, PolicyDetails, PolicyPinCode } from '../../policy.interface';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { InsurancePolicyTimerService } from '../../insurance-policy-timer.service';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-insurance-register-pin',
  templateUrl: './insurance-register-pin.component.html',
  styleUrls: ['./insurance-register-pin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService]
})
export class InsuranceRegisterPinComponent implements OnInit {
  @Input() policyCode: string;
  @Input() email: MaskedEmail;
  @Input() reverification: boolean = false;

  @Output() complete = new EventEmitter<PolicyPinCode>();
  @Output() expireTimer = new EventEmitter<void>();

  public pinControl: UntypedFormControl;
  public preposition = this.translateService.instant('pages.MY_INSURANCE.ACTIONS.PREPOSITION');

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly validationService: ServerErrorsValidationService,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly translateService: TranslateService,
    private readonly insurancePolicyTimerService: InsurancePolicyTimerService,
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService
  ) { }

  public ngOnInit(): void {
    this.initPinControl();
    this.setTimer();
  }

  public onSubmit(): void {
    this.pinControl.markAsTouched();

    if (this.pinControl.invalid) {
      return;
    }

    const data = {
      contractNumber: this.policyCode.trim(),
      pin: this.pinControl.value.trim()
    };

    const request$: Observable<PolicyDetails | MaskedEmails> = this.reverification
      ? this.reverificationContract$()
      : this.verifyContract$();

    request$.pipe(
      catchError(err => {
        this.validationService.handleServerError(err?.error);

        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.complete.emit(data);
    });
  }

  public resendCode(): void {
    this.policyService.sendPinToEmail$(this.policyCode, this.email.id).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private verifyContract$(): Observable<MaskedEmails> {
    return this.policyService.verifyContract$(
      { contractNumber: this.policyCode.trim(), pinCode: this.pinControl.value.trim() },
      true
    );
  }

  private reverificationContract$(): Observable<PolicyDetails> {
    return this.policyService.getPolicyDetailsWithPin$(this.policyCode.trim(), this.pinControl.value.trim());
  }

  private initPinControl(): void {
    this.pinControl = new UntypedFormControl(
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
        wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        wrapValidator(Validators.minLength(6), 'pages.MY_INSURANCE.ERRORS.MIN_LENGTH'),
      ],
      [
        this.validationService.createValidator('pinCode'),
        this.validationService.createValidator()
      ]
    );
  }

  private setTimer(): void {
    this.insurancePolicyTimerService.setTimer$(this.reverification).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => {
      if (value === ConfirmationDialogComponent.CONFIRM) {
        this.expireTimer.emit();

        return;
      }

      this.router.navigateByUrl(this.bootstrapService.link.myInsurancePolicies);
    });
  }
}
