import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Tradedoubler } from "./tradedoublerRouteData";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class ApiTradedoublerService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private http: HttpClient
  ) {}

  public sendTradedoublerData(body: Tradedoubler, isAuthorized: boolean): Observable<void> {
    const url = isAuthorized ? this.baseUrl : environment.api.publicProfile;

    return this.http.post<void>(`${url}/profile/affiliate-partners-outersource`, body);
  }
}