import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, filter, map, of, startWith, switchMap } from 'rxjs';
import { LanguageSwitcherService } from './language-switcher.service';
import { NavigationEnd, Router } from '@angular/router';
import { LanguageMappingURLService } from '@common/language-mapping-url/language-mapping-url.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LanguageSwitcherComponent implements OnInit {
  @Input() isMobileView: boolean = false;

  public languages$: Observable<Array<string>>;
  public displayMenu: boolean = false;
  public currentLanguage$: BehaviorSubject<string> = this.languageSwitcherService.currentLanguage$;

  constructor (
    private readonly cdr: ChangeDetectorRef,
    private readonly elRef: ElementRef,
    private readonly destroyRef: DestroyRef,
    private readonly languageSwitcherService: LanguageSwitcherService,
    private readonly languageMappingURLService: LanguageMappingURLService,
    private readonly router: Router
  ) { }

  @HostListener('document:click', ['$event'])
  clickHandler(event: MouseEvent): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.displayMenu = false;
    }
  }

  ngOnInit(): void {
    this.languages$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(this.router.url),
      switchMap(() => of(this.languageMappingURLService.getPossibleLanguages(this.router.url))),
      takeUntilDestroyed(this.destroyRef)
    );

    combineLatest([this.languages$, this.currentLanguage$]).pipe(
      map(([languages, currentLanguage]) => this.sortLanguages(languages, currentLanguage)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(sortedLanguages => {
      this.languages$ = of(sortedLanguages);
      this.cdr.markForCheck();
    });
  }

  public toggleMenu(): void {
    this.displayMenu = !this.displayMenu;
  }

  public switchLanguage(lang: string): void {
    if (this.languageSwitcherService.currentLanguage$.getValue() !== lang) {
      this.languageSwitcherService.updateLanguage(lang);
      this.cdr.markForCheck();
    }

    this.displayMenu = false;
    this.languageSwitcherService.navigateToNewRoute(this.currentLanguage$.getValue());
  }

  private sortLanguages(languages: Array<string>, lang: string): Array<string> {
    return [lang, ...languages.filter(language => language !== lang)];
  }
}
