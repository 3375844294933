import {
  ChangeDetectionStrategy, Component, DestroyRef, ElementRef,
  Inject, OnDestroy, OnInit, Renderer2, ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { SspWidget } from '@common/model/sspWidget';
import { environment } from '../../../environments/environment';
import { SspWidgetService } from '@kit/ssp-widget/ssp-widget.service';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { LanguageService } from '@common/language/language.service';
import { languageCode } from '@common/language/language.const';
import { UserService } from '@common/user/user.service';
import { AppSessionStorage } from '@kit/utils/ssr.utils';
import { DialogService } from '@kit/dialog/dialog.service';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from '@ngx-translate/core';
import { getAllRouterParams, setRouterData } from '@kit/utils/router.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogRef } from '@kit/dialog/dialog.interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiInsurancePolicyService } from '@pages/account/components/my-insurance/insurance-policy.service';
import { PolicyDetails } from '@pages/account/components/my-insurance/policy.interface';

@Component({
  selector: 'app-ssp-widget',
  templateUrl: './ssp-widget.component.html',
  styleUrls: ['./ssp-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SspWidgetComponent extends AemFragmentComponent implements OnInit, OnDestroy {
  private policyNumber: string;
  private popupRef: DialogRef<ConfirmationDialogComponent>;

  @ViewChild('componentContainerRef') componentContainerRef: ElementRef;

  constructor(
    private readonly sspWidgetService: SspWidgetService,
    private readonly renderer: Renderer2,
    private readonly destroyRef: DestroyRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService,
    private readonly languageService: LanguageService,
    private readonly userService: UserService,
    private readonly sessionStorageService: AppSessionStorage,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly apiInsurancePolicyService: ApiInsurancePolicyService,
    @Inject(AEM_DATA) public override data: SspWidget,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.policyNumber = this.getPolicyId();

    if (!this.policyNumber) {
      this.showErrorPopUp('NOT_FOUND');

      return;
    }

    this.clearUserInfoFromLocaleStorage();
    this.apiInsurancePolicyService.getPolicyDetails$(this.policyNumber)
      .pipe(
        switchMap((policyDetails: PolicyDetails) => {
          const policyHolderEmail: string = policyDetails?.contractHolder?.email || this.userService.userEmail;

          return this.sspWidgetService.auth(this.policyNumber, policyHolderEmail)
        }),
        catchError(error => this.handleAuthError(error)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.loadWidget());
  }

  public ngOnDestroy(): void {
    this.popupRef?.close('noNavigate');
  }

  private getPolicyId(): string {
    const codedPolicyId: string = getAllRouterParams(this.activatedRoute.snapshot.root)?.policyId;

    return codedPolicyId ? atob(codedPolicyId) : undefined;
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401 ) {
      this.showErrorPopUp('NO_PERMISSION');

      return throwError(error);
    }

    if (error.status === 403 ) {
      this.showErrorPopUp('NOT_FOUND');

      return throwError(error);
    }

    this.showErrorPopUp('SERVICE_UNAVAILABLE');

    return throwError(error);
  }

  private loadWidget(): void {
    const envScriptLink: HTMLElement = this.document.getElementById('sspEnv');
    const sspScriptLink: HTMLElement = this.document.getElementById('sspScript');

    if (envScriptLink && sspScriptLink) {
      this.renderComponentWhenDefined('allianz-ssp-full-app-element', this.componentContainerRef.nativeElement);

      return;
    }

    const path: string = environment.sspWidgetPath;
    const envScript: HTMLScriptElement = this.loadScript(`${path}/assets/env.js`, 'sspEnv');
    const sspScript: HTMLScriptElement = this.loadScript(`${path}/bundles/ssp-full-app/index-ssp-full-app.js`, 'sspScript');


    envScript.onload = (() => {
      sspScript.onload = (() => {
        this.renderComponentWhenDefined('allianz-ssp-full-app-element', this.componentContainerRef.nativeElement);
      });
    });

    envScript.onerror = (() => {
      this.removeScriptLink('sspEnv');
      this.showErrorPopUp('SERVICE_UNAVAILABLE');
    });

    sspScript.onerror = (() => {
      this.removeScriptLink('sspScript');
      this.showErrorPopUp('SERVICE_UNAVAILABLE');
    });
  }

  private loadScript(path: string, scriptId: string): HTMLScriptElement {
    const scriptLink: HTMLScriptElement = this.document.getElementById(scriptId) as HTMLScriptElement;

    if (scriptLink) return scriptLink;

    const script = this.renderer.createElement('script');

    script.type = 'text/javascript';
    script.src = path;
    script.id = scriptId;
    this.renderer.appendChild(this.document.body, script);

    return script;
  }

  private clearUserInfoFromLocaleStorage(): void {
    this.sessionStorageService.removeItem('userInfo');
  }

  private renderComponentWhenDefined(widgetComponentName: string, containerRef: any): void {
    customElements.whenDefined(widgetComponentName).then(() => {
      const element = this.renderer.createElement(widgetComponentName);

      this.renderer.setAttribute(element, 'lang', languageCode[this.languageService.languageCode]);
      this.renderer.setAttribute(element, 'client', 'ALLYZ');
      this.renderer.appendChild(containerRef, element);
    });
  }

  private navigateToPolicyInformationPage(): void {
    this.router.navigate([setRouterData(
      this.bootstrapService.link.insurancePolicy,
      { policyId: getAllRouterParams(this.activatedRoute.snapshot.root)?.policyId },
    )]);
  }


  private showErrorPopUp(dictionaryKey: string): void {
    this.popupRef = this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant(`pages.SSP_WIDGET.TITLE.${dictionaryKey}`),
      info: this.translateService.instant(`pages.SSP_WIDGET.ERROR.${dictionaryKey}`),
      confirmTitle: this.translateService.instant('OK'),
    });

    this.popupRef.afterClosed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: string)  => {
        if (state === 'noNavigate') return;

        this.popupRef = null;
        this.navigateToPolicyInformationPage();
      });
  }

  private removeScriptLink(scriptId: string): void {
    const scriptLink: HTMLElement = this.document.getElementById(scriptId);

    if (scriptLink) scriptLink.remove();
  }
}
