import { Component, DestroyRef, Inject, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, EMPTY, BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { ContactUsHero } from '@common/model/contactUsHero';
import { UserService } from '@common/user/user.service';
import { CREDENTIAL_REGEXP } from '@pages/account/components/co-travelers/co-travelers.const';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { wrapValidator } from '@kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from '@kit/server-errors/server-errors-validation.service';
import { emailValidator, notBlankValidator } from '@kit/utils/validators';
import { mapCategoriesToContactForm, mapContactFormToSource } from './contact-us-form.mapper';
import { LanguageService } from '@common/language/language.service';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import {
  CustomerCareCategory,
  CustomerCareCategoryDTO,
  CustomerCareRequests
} from '@common/customer-care/customer-care.interface';
import { ApiCustomerCareService } from '@common/customer-care/customer-care.service';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from "../../../../../../environments/environment";
import {EnvOption, FieldPrefix} from "@kit/aem/common/aem-contact-form/contact-us-form/contact-us-form.consts";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss'],
  providers: [ServerErrorsValidationService],
})
export class ContactUsFormComponent implements OnInit {
  @Input() categories: CustomerCareCategory[];

  public showForm = new BehaviorSubject(true);
  public contactForm: UntypedFormGroup;

  public get selectedSubcategory(): CustomerCareCategory {
    return this.contactForm.get('subcategory').value;
  }

  public get mappedForm(): CustomerCareRequests {
    return mapContactFormToSource(
      this.contactForm.value,
      this.langService.countryCode,
      this.langService.languageCode
    )
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly validationService: ServerErrorsValidationService,
    private readonly analyticsService: AnalyticsService,
    private readonly userService: UserService,
    private readonly destroyRef: DestroyRef,
    private readonly customerCareService: ApiCustomerCareService,
    private readonly langService: LanguageService,
    private readonly ngZone: NgZone,
    @Inject(AEM_DATA) public data: ContactUsHero,
    @Inject(IS_BROWSER_PLATFORM) private isBrowser: boolean,
  ) { }

  public ngOnInit(): void {
    this.categories = this.getMappedCategories();
    this.initForm();
    this.preFillForm();
  }

  public submit(): void {
    this.contactForm.markAllAsTouched();

    if (!this.contactForm.invalid && this.contactForm.value.confirm) {

      this.addTestPrefixToFormFields();

      this.customerCareService
        .sendCustomerCare(this.mappedForm)
        .pipe(
          catchError((err) => this.handleSubmitError(err)),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => this.handleSubmitSuccess());

      return;
    }

    this.analyticsService.validationError(this.contactForm, this.constructor.name);
  }

  public fillNewForm(): void {
    this.contactForm.reset();
    this.preFillForm();
    this.showForm.next(true);
  }

  private initForm(): void {
    this.contactForm = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      email: [null],
      subcategory: [null],
      message: [null],
      confirm: [false],
    });

    this.addFormControlsValidators();
    this.addFormControlsAsyncValidators();
  }

  private addFormControlsValidators(): void {
    const firstNameControl = this.contactForm.get('firstName');
    const lastNameControl = this.contactForm.get('lastName');
    const emailControl = this.contactForm.get('email');
    const subcategoryControl = this.contactForm.get('subcategory');
    const messageControl = this.contactForm.get('message');
    const confirmControl = this.contactForm.get('confirm');

    firstNameControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
      wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
      wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
      wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern'),
    ]);
    lastNameControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
      wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
      wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
      wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern'),
    ]);
    emailControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
      wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
      wrapValidator(Validators.maxLength(320), 'errors.CEAZ000_Size'),
      wrapValidator(emailValidator, 'errors.CEAZ000_Pattern'),
    ]);
    subcategoryControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
    ]);
    messageControl.addValidators([
      wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
      wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
      wrapValidator(Validators.maxLength(1000), 'errors.CEAZ000_Size'),
    ]);
    confirmControl.addValidators([
      wrapValidator(Validators.requiredTrue, 'errors.CEAZ011'),
    ]);
  }

  private addFormControlsAsyncValidators(): void {
    this.validationService.createValidator('firstName');
    this.validationService.createValidator('lastName');
    this.validationService.createValidator('email');
    this.validationService.createValidator('subcategory');
    this.validationService.createValidator('message');
    this.validationService.createValidator('confirm');
  }

  private preFillForm(): void {
    if (this.userService.authorized) {
      const userData = this.userService.userData$.value;

      this.contactForm.patchValue({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
      });
    }
  }

  private getMappedCategories(): CustomerCareCategory[] {
    return this.categories.map(category =>
      mapCategoriesToContactForm(category as unknown as CustomerCareCategoryDTO));
  }

  private handleSubmitError(err: HttpErrorResponse): Observable<never> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);
    this.validationService.handleServerError(err?.error);

    return EMPTY;
  }

  private handleSubmitSuccess(): void {
    this.showForm.next(false);
    this.triggerAnalyticAction();
    this.scrollTop();
  }

  private triggerAnalyticAction(): void {
    this.analyticsService.triggerAction({
      category: 'customer_care',
      action: 'contactFormSubmitted',
      label: `${ this.contactForm.value.subcategory.parent.categoryCode }_${ this.contactForm.value.subcategory.categoryCode }`,
      value: 1,
      timeStamp: new Date().toISOString(),
    });
  }

  private scrollTop(): void {
    if (!this.isBrowser) return;

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => document.scrollingElement.scrollTop = 0, 0);
    });
  }

  private addTestPrefixToFormFields(): void {
    let firstNameValue = this.contactForm.get('firstName').value;
    let lastNameValue = this.contactForm.get('lastName').value;
    let messageValue = this.contactForm.get('message').value;

    if (environment.name === EnvOption.Dev || environment.name === EnvOption.Uat) {
      firstNameValue = FieldPrefix.Name + firstNameValue.trim();
      lastNameValue = FieldPrefix.Name + lastNameValue.trim();
      messageValue = FieldPrefix.Message + messageValue.trim();
    }

    this.contactForm.patchValue({
      firstName: firstNameValue,
      lastName: lastNameValue,
      message: messageValue,
    });
  }
}
