import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { ButtonModule } from '@kit/button/button.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemMyAccountComponent } from './my-account.component';
import { TooltipModule } from '@kit/tooltip/tooltip.module';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  declarations: [AemMyAccountComponent],
  exports: [AemMyAccountComponent],
  imports: [
    CommonModule,
    ButtonModule,
    AemRouterModule,
    SafeHtmlModule,
    TranslateModule,
    TooltipModule,
    LetModule,
  ]
})
export class AemMyAccountModule { }
