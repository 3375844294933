export interface PremiumDetail {
  id: number;
  premiumStartDate: string;
  premiumEndDate: string;
  promoCode?: string;
  partnerName?: string;
  upgradeMeans: UpgradeMeanEnum;
  upgradeReason: UpgradeReasonEnum,
  startDate?: string;
  endDate?: string;
  insurance: string;
  deleted?: string;
}

export interface PremiumPeriodRequestData {
  upgradeReason: string;
  promoCode?: string;
  startDate?: string;
  endDate?: string;
}

export interface PremiumPeriodResponseData {
  premiumStartDate: string;
  premiumEndDate: string;
}

export enum UpgradeReasonEnum {
  INSURANCE_POLICY = 'INSURANCE_POLICY',
  PROMOCODE = 'PROMOCODE',
}

export enum UpgradeMeanEnum {
  AUTOMATIC = 'AUTOMATIC',
  SEMIAUTOMATIC_API = 'SEMIAUTOMATIC_API',
  MANUAL_BACKOFFICE = 'MANUAL_BACKOFFICE',
  EXCEL_IMPORT = 'EXCEL_IMPORT',
  BUNDLE_OFFER_API = 'BUNDLE_OFFER_API',
}
