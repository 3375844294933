import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { Market } from "@common/language/language.const";
import { UserService } from "@common/user/user.service";
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "@kit/dialog/dialog.service";
import { DOCUMENT } from "@angular/common";
import { ConsentType } from "../../notification-options.consts";

@Component({
  selector: 'app-marketing-notifications',
  templateUrl: './marketing-notifications.component.html',
  styleUrls: ['./marketing-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingNotificationsComponent implements AfterViewInit {
  @Input() notifications: string[];
  @Output() toggleMarketing = new EventEmitter<ConsentType>();

  public consentType = ConsentType;

  public get residence(): string {
    return this.userService.location;
  }

  public get isGlobalMarketingConsent(): boolean {
    return (this.residence === Market.DE || this.residence === Market.NL || this.residence === Market.AT);
  }

  public get internalMarketingConsentValue(): boolean {
    return this.notifications.includes(ConsentType.INTERNAL_MARKETING);
  }

  public get externalMarketingConsentValue(): boolean {
    return this.notifications.includes(ConsentType.EXTERNAL_MARKETING);
  }

  public get globalMarketingConsentValue(): boolean {
    return this.notifications.includes(ConsentType.GLOBAL_MARKETING);
  }

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }

  public ngAfterViewInit(): void {
    const allianzGroupLink = this.document?.getElementById('allianz-group-link');

    allianzGroupLink?.setAttribute('style', 'text-decoration: underline; cursor: pointer');
    allianzGroupLink?.addEventListener('click', (event: MouseEvent) => this.openAllianzGroupPopup(event));
  }

  public switchMarketingNotification(consentType: ConsentType, event: Event): void {
    event.preventDefault();
    this.toggleMarketing.emit(consentType);
  }

  private openAllianzGroupPopup(event: Event): void {
    event.preventDefault();
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      info: this.translateService.instant('pages.NOTIFICATION.ALLIANZ_GROUP_DIALOG.TEXT'),
      confirmTitle: 'OK'
    });
  }
}
