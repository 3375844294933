import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@kit/icon/icon.module';
import { ButtonModule } from '@kit/button/button.module';
import { InsuranceActivePoliciesComponent } from './components/insurance-active-policies/insurance-active-policies.component';
import { InsurancePastPoliciesComponent } from './components/insurance-past-policies/insurance-past-policies.component';
import { TranslateModule } from '@ngx-translate/core';
import { InsuranceNumberInputComponent } from './components/insurance-number-input/insurance-number-input.component';
import { LabelModule } from '@kit/label/label.module';
import { InputModule } from '@kit/input/input.module';
import { CheckboxModule } from '@kit/checkbox/checkbox.module';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { InsuranceAddSucceededComponent } from './components/insurance-add-succeeded/insurance-add-succeeded.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldErrorModule } from 'src/app/kit/field-error/field-error.module';
import { AddExistingPolicyComponent } from './components/insurance-add-existing-policy/insurance-add-existing-policy.component';
import { AemRouterModule } from 'src/app/common/routing/aem-router.module';
import { InsurancePolicyDetailsComponent } from './components/insurance-policy-details/insurance-policy-details.component';
import { InsuranceRegisterPinComponent } from './components/insurance-register-pin/insurance-register-pin.component';
import { InsuranceDetailsPinComponent } from './components/insurance-details-pin/insurance-details-pin.component';
import { DateModule } from 'src/app/kit/date/date.module';
import { InsurancePinDialogComponent } from './components/insurance-pin-dialog/insurance-pin-dialog.component';
import { DialogModule } from 'src/app/kit/dialog/dialog.module';
import { InsuranceSelectEmailComponent } from './components/insurance-select-email/insurance-select-email.component';
import { RadioButtonModule } from '@kit/radio-button/radio-button.module';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { ButtonWithTimerModule } from '@kit/button-with-timer/button-with-timer.module';
import { InsuranceUserDataComponent } from './components/insurance-user-data/insurance-user-data.component';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    TranslateModule,
    LabelModule,
    ReactiveFormsModule,
    FieldErrorModule,
    InputModule,
    CheckboxModule,
    SafeHtmlModule,
    AemRouterModule,
    DateModule,
    DialogModule,
    RadioButtonModule,
    BackButtonModule,
    ButtonWithTimerModule,
    LetModule
  ],
  declarations: [
    InsuranceActivePoliciesComponent,
    InsurancePastPoliciesComponent,
    InsuranceNumberInputComponent,
    InsuranceAddSucceededComponent,
    InsurancePolicyDetailsComponent,
    AddExistingPolicyComponent,
    InsuranceRegisterPinComponent,
    InsuranceDetailsPinComponent,
    InsurancePinDialogComponent,
    InsuranceSelectEmailComponent,
    InsuranceUserDataComponent
  ],
})
export class MyInsuranceModule { }
