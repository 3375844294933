import { ChangeDetectionStrategy, Component, DestroyRef, Inject } from '@angular/core';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { DynamicComponentRegistrationForm } from '@common/model/dynamicComponentRegistrationForm';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { RegistrationService } from "@common/registration/registration.service";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemRegisterComponent extends AemFragmentComponent {
  public confirmationStep = false;
  private userEmail: string;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly registrationService: RegistrationService,
    private readonly destroyRef: DestroyRef,
    @Inject(AEM_DATA) data: DynamicComponentRegistrationForm,
  ) {
    super(data);
  }

  public onFormSend(email: string): void {
    this.confirmationStep = true;
    this.userEmail = email;
  }

  public resend(): void {
    this.registrationService.resendActivationLink(this.userEmail)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
