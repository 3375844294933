<app-trip-hero-block (goBack)="back()">
  <div class="flight-added">
    <div (click)="addMoreFlights()" class="flight-added_add-more">
      <app-icon class="flight-added_add-more-icon" icon="plane" theme="inherit" size="large"></app-icon>
      <span class="flight-added_add-more-text t-small m-t-normal">
        {{'pages.ADDED_FLIGHTS.ADD_MORE_FLIGHTS' | translate}}
      </span>
      <app-icon class="flight-added_add-more-plus" icon="plus" size="large" theme="inherit"></app-icon>
    </div>
    <ng-container *let="flights$ | async as flights">
      <div *ngIf="flights?.length" class="h4 m-h2">{{data.component.title}}</div>
      <div *ngFor="let flight of flights" class="flight-added_flight-items">
        <app-flight-card-detailed [flight]="flight"
                                  (delete)="deleteFlight($event)">
        </app-flight-card-detailed>
      </div>
    </ng-container>
    <app-button (click)="back()" id="flight-added-action-back" type="full-width" size="l">
      {{'pages.ADDED_FLIGHTS.FINISH_ADDING_FLIGHTS' | translate}}
    </app-button>
  </div>
</app-trip-hero-block>
