import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output, SimpleChanges
} from "@angular/core";
import { ServicePartner } from "@common/profile/profile.interfaces";
import { FormBuilder, FormControl } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ServiceNotification, ServiceNotificationType } from "../../notification-options.consts";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-service-notification-dropdown',
  templateUrl: './service-notification-dropdown.component.html',
  styleUrls: ['./service-notification-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceNotificationDropdownComponent implements OnChanges {
  @Input() set serviceNotificationOptions(value: ServiceNotification[]) {
    this.serviceNotificationOptions$.next(value);
  };
  @Input() partnerName: ServicePartner;
  @Output() updateValue = new EventEmitter<ServiceNotificationType>();

  public serviceNotificationOptions$ = new BehaviorSubject<ServiceNotification[]>([]);
  public serviceNotificationSelectControl: FormControl = this.formBuilder.control(null);

  public get placeholderName(): string {
    return this.serviceNotificationOptions$.getValue().find(option => option.selected).name;
  }

  private get notificationType(): ServiceNotificationType {
    return this.serviceNotificationOptions$.getValue().find(option => option.selected).value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.initServiceNotificationSubscription();
  }

  public ngOnChanges(_changes: SimpleChanges) {
    this.initServiceNotificationSettings(this.notificationType);
  }

  private initServiceNotificationSettings(notificationType: ServiceNotificationType): void {
    this.serviceNotificationSelectControl.setValue(notificationType, { emitEvent: false });
  }

  private initServiceNotificationSubscription(): void {
    this.serviceNotificationSelectControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((value) => {
      this.updateValue.emit(value);
      this.initServiceNotificationSettings(value);
    });
  }
}
