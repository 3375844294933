import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AemRouterModule } from '@common/routing/aem-router.module';
import { AemHideableExtendedLinkModule } from '../aem-hideable-extended-link/aem-hideable-extended-link.module';
import { ButtonModule } from '../button/button.module';
import { TagModule } from '../tag/tag.module';
import { AemButtonComponent } from './aem-button.component';
import { FaqBackButtonComponent } from './faq-back-button/faq-back-button.component';
import { HealthcareProfessionalActionComponent } from './healthcare-professional-action/healthcare-professional-action.component';
import { ChatWithDoctorComponent } from './healthcare-real-chat-action/chat-with-doctor.component';
import { LogOutComponent } from './log-out-action/log-out.component';
import { LoginComponent } from './login-action/login.component';
import { OneTrustCookiesSettingsComponent } from './onetrust-cookies-settings-action/onetrust-cookies-settings.component';
import { OpenInsuranceRequestComponent } from './open-insurance-request-action/open-insurance-request.component';
import { SendInviteEmailComponent } from './send-invite-email-action/send-invite-email.component';
import { SurvayComponent } from './survay-action/survay.component';
import { TeleconsultationComponent } from './teleconsultation-action/teleconsultation.component';
import { TrackInsuranceRequestComponent } from './track-insurance-request-action/track-insurance-request.component';
import { UpgradeInsurancePolicyComponent } from './upgrade-insurance-policy-action/upgrade-insurance-policy.component';
import { UpgradeReferralCodeComponent } from './upgrade-referral-code-action/upgrade-referral-code.component';
import { AemUserIdButtonComponent } from './user-id-button/user-id-button.component';
import { AirDoctorComponent } from './air-doctor-action/air-doctor.component';
import { AemBuyTravelInsuranceActionComponent } from "@kit/aem-button/buy-travel-insurance-action/buy-travel-insurance-action";
import { OpenPolicyDetailsComponent } from '@kit/aem-button/open-policy-details-action/open-policy-details-action';

@NgModule({
  declarations: [
    AemButtonComponent,
    HealthcareProfessionalActionComponent,
    LogOutComponent,
    LoginComponent,
    ChatWithDoctorComponent,
    SendInviteEmailComponent,
    SurvayComponent,
    UpgradeReferralCodeComponent,
    UpgradeInsurancePolicyComponent,
    OneTrustCookiesSettingsComponent,
    OpenInsuranceRequestComponent,
    TrackInsuranceRequestComponent,
    TeleconsultationComponent,
    FaqBackButtonComponent,
    AemUserIdButtonComponent,
    AirDoctorComponent,
    AemBuyTravelInsuranceActionComponent,
    OpenPolicyDetailsComponent,
  ],
  exports: [AemButtonComponent, LoginComponent, AemUserIdButtonComponent],
  imports: [
    CommonModule,
    ButtonModule,
    AemRouterModule,
    TagModule,
    TranslateModule,
    AemHideableExtendedLinkModule,
  ],
})
export class AemButtonModule {}
