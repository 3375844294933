import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ProfileSearchResult,
  ProfileSearchResultDTO,
  UserProfile,
  UserProfileDTO,
  UserProfileForm,
  UserProfilePathOptions,
  UserSearchOptionsDTO
} from './profile.interfaces';
import { mapUserProfileFromDto } from './profile.mapper';

@Injectable({
  providedIn: 'root'
})
export class ApiProfileService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getUserProfileAndScope(): Observable<{
    profile: UserProfile,
    scope: string,
  }> {
    return this.http.get<UserProfileDTO>(`${this.baseUrl}/profile`, {observe: 'response'}).pipe(
      map((resp) => ({
        scope: resp.headers.get('x-scope'),
        profile: mapUserProfileFromDto(resp.body),
      }))
    );
  }

  public getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfileDTO>(`${this.baseUrl}/profile`).pipe(
      map(mapUserProfileFromDto)
    );
  }

  public getUserProfileById(id: string): Observable<UserProfile> {
    return this.http.get<UserProfileDTO>(`${this.baseUrl}/profiles/${id}`).pipe(
      map(mapUserProfileFromDto)
    );
  }

  public editUserProfile(data: UserProfileForm): Observable<{id: number}> {
    return this.http.put<{id: number}>(`${this.baseUrl}/profile`, data);
  }

  public getProfilesList(searchOptions: UserSearchOptionsDTO, page: number): Observable<ProfileSearchResult> {
    const params: URLSearchParams = new URLSearchParams();

    params.append('page', page.toString());
    if (searchOptions.profileId) params.append('profileId', searchOptions.profileId);
    if (searchOptions.firstName) params.append('firstName', searchOptions.firstName);
    if (searchOptions.lastName) params.append('lastName', searchOptions.lastName);
    if (searchOptions.email) params.append('email', searchOptions.email);
    if (searchOptions.profileStatus) params.append('profileStatus', searchOptions.profileStatus);
    if (searchOptions.membershipStatus) params.append('membershipStatus', searchOptions.membershipStatus);
    if (searchOptions.residence) params.append('residence', searchOptions.residence);
    if (searchOptions.fromCreated) params.append('fromCreated', searchOptions.fromCreated);
    if (searchOptions.toCreated) params.append('toCreated', searchOptions.toCreated);

    return this.http.get<ProfileSearchResultDTO>(`${this.baseUrl}/profiles?${params.toString()}`).pipe(
      map(resp => ({
        ...resp,
        profiles: resp.profiles?.map(mapUserProfileFromDto) || [],
      }))
    );
  }

  public editUserProfileById(id: number, data: Partial<UserProfile>): Observable<{id: number}> {
    return this.http.put<{id: number}>(`${this.baseUrl}/profiles/${id}`, data);
  }

  public deleteUserProfile(): Observable<{id: number}> {
    return this.http.delete<{id: number}>(`${this.baseUrl}/profile`);
  }

  public deleteUserProfileById(id: string): Observable<{id: number}> {
    return this.http.delete<{id: number}>(`${this.baseUrl}/profiles/${id}`);
  }

  public updateUserProfile(options: UserProfilePathOptions[]): Observable<UserProfile> {
    return this.http.patch<UserProfile>(`${this.baseUrl}/profile`, options);
  }
}
