<div class="back-button shell">
  <div>
    <app-button id="action-go-to-all-trips" 
                type="normal-width"
                theme="bordered"
                icon="chevron-left"
                size="s"
                [appAemRouterLink]="bootstrapService.link.trips">
                {{ 'pages.TRIP_WALLET.GO_BACK_TO_ALL_YOUR_TRIPS' | translate }}
    </app-button>
  </div>
</div>

<main class="trip-wallet main" *ngIf="currentTrip$ | async as currentTrip">
  <section class="section trip-wallet_top-section">
    <div class="section__shell shell trip-wallet_list-wrapper">
      <div class="trip-wallet_trip">
        <div class="trip-wallet_trip-avatar">
          <img [src]="countryImagePath"
               (error)="onImageLoadError()"
               [appImgAlt]="currentTrip?.name">
        </div>
        <div class="trip-wallet_trip-title headline-5"> {{ currentTrip.name }} </div>
        <div class="trip-wallet_trip-details">
          <div class="trip-wallet_trip-details-destinations text-large">
            <app-icon icon="map" theme="inherit" size="large"></app-icon>

            {{ getDestinationsPointsList(currentTrip.destinationPoints) }}
          </div>
          <div class="trip-wallet_trip-details-date text-large">
            <app-icon icon="calendar" theme="inherit" size="large"></app-icon>

            {{ currentTrip | dateRange }}
          </div>
          <div class="trip-wallet_trip-details-actions">
            <app-button id="action-trip-edit"
                        type="normal-width"
                        theme="bordered"
                        size="inherit"
                        icon="edit"
                        [appAemRouterLink]="bootstrapService.link.tripEdit">
              {{ 'pages.TRIP_WALLET.EDIT_TRIP' | translate }}
            </app-button>
            <app-button id="action-trip-delete"
                        type="normal-width"
                        theme="bordered"
                        size="inherit"
                        (click)="deleteTrip(currentTrip.tripId)"
                        icon="thrash">
              {{ 'pages.TRIP_WALLET.DELETE_TRIP' | translate }}
            </app-button>
          </div>
        </div>
        <div class="trip-wallet_trip-extra">

          <ng-container *ngIf="showMedicalBlock$ | async">
            <p class="text-normal margin-bottom-32"><app-safe-html [content]="'pages.TRIP_WALLET.GET_MEDICAL_HELP_DESCRIPTION' | translate"></app-safe-html></p>
            <app-button class="extra-button margin-bottom-32"
                        id="action-medical-help"
                        theme="bordered"
                        size="inherit"
                        [appAemRouterLink]="bootstrapService.link.tripHealth">
              {{ 'pages.TRIP_WALLET.GET_MEDICAL_HELP' | translate }}
            </app-button>
          </ng-container>

          <ng-container *ngIf="showCompensationBlock$ | async">
            <p class="text-normal trip-wallet_trip-extra-title">
              <app-safe-html [content]="'pages.TRIP_WALLET.GET_COMPENSATION_DESCRIPTION' | translate"></app-safe-html>
            </p>
            <app-button class="extra-button margin-bottom-32"
                        id="action-get-compensation"
                        theme="black"
                        size="inherit"
                        [appAemRouterLink]="bootstrapService.link.flightDelay">
              {{ 'pages.TRIP_WALLET.GET_COMPENSATION' | translate }}
            </app-button>
          </ng-container>

          <ng-container *ngIf="showInsuranceBlock$ | async">
            <p class="text-normal trip-wallet_trip-extra-title">
              <app-safe-html [content]="'pages.TRIP_WALLET.GET_COVERAGE_NOW' | translate"></app-safe-html>
            </p>

            <app-button class="extra-button"
                        id="action-protect-my-trip"
                        theme="black"
                        size="inherit"
                        (click)="getInsurance()">
             {{ 'pages.TRIP_WALLET.PROTECT_MY_TRIP' | translate }}
            </app-button>
          </ng-container>

          <ng-container *ngIf="showAEMInsuranceBlock$ | async">
            <div class="h5 m-h3 margin-bottom-32">{{ data.title }}</div>
            <p class="text-normal trip-wallet_trip-extra-title">
              <app-safe-html *ngIf="data.text"
                             [content]="data.text"
                             class="t-normal m-t-normal">
              </app-safe-html>
            </p>

            <app-aem-button *ngFor="let cta of data.ctas"
                            [cta]="cta"
                            type="full-width"
                            size="m"
                            id="action-protect-my-trip"
                            class="extra-button">
            </app-aem-button>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="section__shell shell">
      <app-trip-planner [currentTrip]="currentTrip"></app-trip-planner>
    </div>
  </section>
</main>
