import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiTermsAndConditionsService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public acceptTermsAndConditions(): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/profile/terms-and-conditions`, null);
  }
}