import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, Observable } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { notBlankValidator, rangeWithTimeValidator } from 'src/app/kit/utils/validators';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { FormAccommodationAdd } from '@common/model/formAccommodationAdd';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { mapHotelSourceToDto } from '../hotel.mapper';
import { HotelService } from '../hotel.service';
import { getDate } from '@kit/utils/date.utils';
import { getAllFormErrors } from "@kit/utils/form";
import { HotelDto } from "@pages/trip/components/hotel/hotel.interface";
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-add-hotel',
  templateUrl: './add-hotel.component.html',
  styleUrls: ['./add-hotel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService],
})
export class AddHotelComponent extends AemFragmentComponent implements OnInit {
  public form = this.formBuilder.group({
    hotelName: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
        wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank'),
        wrapValidator(Validators.maxLength(35), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('hotelName'),
    ],
    hotelAddress: [
      null,
      [
        wrapValidator(Validators.maxLength(160), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('hotelAddress'),
    ],
    checkInDate: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkInDate'),
    ],
    checkInHour: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkInHour'),
    ],
    checkOutDate: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkOutDate'),
    ],
    checkOutHour: [
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
      ],
      this.validationService.createValidator('checkOutHour'),
    ],
    bookingReference: [
      null,
      [
        wrapValidator(Validators.maxLength(15), 'errors.CEAZ000_Size'),
      ],
      this.validationService.createValidator('bookingReference'),
    ],
  });

  private tripId = this.route.firstChild.snapshot.params.tripId;

  constructor(
    private bootstrapService: BootstrapService,
    private formBuilder: UntypedFormBuilder,
    private hotelService: HotelService,
    private validationService: ServerErrorsValidationService,
    private destroyRef: DestroyRef,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    @Inject(AEM_DATA) data: FormAccommodationAdd,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.form.addAsyncValidators(this.validationService.createValidator());
    this.form.addValidators(wrapValidator(
      rangeWithTimeValidator(
        this.form.get('checkInDate'),
        this.form.get('checkInHour'),
        this.form.get('checkOutDate'),
        this.form.get('checkOutHour'),
      ),
      'errors.CEAZ024'
    ));
  }

  public goBack() {
    this.router.navigate(
      [setRouterData(this.bootstrapService.link.element, { tripId: this.tripId })],
      { queryParamsHandling: 'preserve' },
    );
  }

  public onSave() {
    this.form.markAllAsTouched();

    if (getAllFormErrors(this.form)?.length) {
      this.analyticsService.validationError(this.form, this.constructor.name);
      return;
    }

    const hotelDto: HotelDto = mapHotelSourceToDto(this.form.value);

    this.hotelService.create(this.tripId, hotelDto).pipe(
      catchError(err => this.handleServerError(err)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.sendAnalytics(hotelDto);
      this.goBack();
    });
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }

  private sendAnalytics(hotel: HotelDto): void {
    this.analyticsService.triggerAction({
      category: 'travel_wallet',
      action: 'hotelAdded',
      label: `${this.convertDate(hotel.checkinDate)}_${this.convertDate(hotel.checkoutDate)}_${hotel.hotelName}`,
      value: 1,
    });
  }

  private convertDate(date: string): string {
    return getDate(new Date(date));
  }
}
