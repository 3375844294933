import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WoosmapSearchComponent } from "@kit/woosmap-search/woosmap-search.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "@kit/button/button.module"
import { IconModule } from "@kit/icon/icon.module";
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  declarations: [WoosmapSearchComponent],
  exports: [WoosmapSearchComponent],
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, IconModule, TranslateModule, LetModule],
})
export class WoosmapSearchModule { }
