import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
})
export class FieldErrorComponent implements OnInit {
  @Input() public controlName: string;
  @Input() public control: AbstractControl;
  @Input() public isInBlock: boolean = false;

  private get formControl(): AbstractControl {
    return this.control || this.parentControl?.control?.get(this.controlName);
  }

  public get showMessage(): boolean {
    return this.formControl?.invalid && this.formControl?.touched;
  }

  public get errorMessage(): string {
    return this.formControl?.errors?.err;
  }

  constructor(
    @Optional() @SkipSelf() private parentControl: FormGroupDirective,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {
  }

  ngOnInit(): void {
    if (this.formControl) {
      this.formControl.statusChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.cdr.markForCheck();
        })
    }
  }
}
