import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { SelectWithChildComponent } from './select-with-child/select-with-child.component';
import { IconModule } from "@kit/icon/icon.module";
import { TranslateModule } from '@ngx-translate/core';
import { ActiveZoneModule } from '@kit/active-zone/active-zone.module';

@NgModule({
  declarations: [SelectComponent, SelectWithChildComponent],
  exports: [SelectComponent, SelectWithChildComponent],
  imports: [CommonModule, IconModule, TranslateModule, ActiveZoneModule],
})
export class SelectModule { }
