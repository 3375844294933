import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  Optional,
  Renderer2
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable } from 'rxjs';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { ThematicLinks } from '@common/blog/blog.interface';
import { BlogService } from '@common/blog/blog.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { ArticlesList } from '@common/model/articlesList';
import { BlogArticle } from '@common/model/blogArticle';
import { BlogArticlesData } from '@common/model/blogArticlesData';
import { BlogIndex } from '@common/model/blogIndex';
import { BlogThematic } from '@common/model/blogThematic';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { SortOption, Thematic } from './articles-list.interface';
import { DOCUMENT, Location } from "@angular/common";
import { CURRENT_LOCATION } from "@common/seo/seo.const";
import { IS_SERVER_PLATFORM } from "@kit/utils/ssr.utils";
import { DEFAULT_THEMATIC, ITEM_ON_PAGE, SortingType } from "@kit/aem/common/articles-list/articles-list.const";
import { Meta } from "@angular/platform-browser";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemArticleListComponent extends AemBaseBlockComponent implements OnInit {
  public articles: BlogArticle[];
  public thematicLinks: ThematicLinks;
  public thematicsList$: Observable<Thematic[]>;
  public filterForm: UntypedFormGroup;
  public itemOnPage = ITEM_ON_PAGE;
  public sortOptions$: Observable<SortOption[]>;
  public currentPage: number;

  private thematic: BlogThematic;
  private sortingControl: UntypedFormControl;
  private thematicControl: UntypedFormControl;
  private defaultThematic$: Observable<Thematic>;

  public get blogLink(): string {
    return this.bootstrapService.link.blog;
  }

  constructor(
    @Inject(AEM_DATA) public override data: ArticlesList,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(CURRENT_LOCATION) private currentLocation: string,
    @Inject(IS_SERVER_PLATFORM) private isServer: boolean,
    private blogService: BlogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
    private bootstrapService: BootstrapService,
    private translateService: TranslateService,
    private fromBuilder: UntypedFormBuilder,
    private zone: NgZone,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private readonly metaService: Meta,
    private location: Location
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.initCurrentPage();
    this.initSortingControl();
    this.initSortingOptions();
    this.initThematicsControl();
    this.initFilterForm();
    this.loadBlogData();
    this.addMetaTags();
  }

  public onPageNumberChange(currentPage: number): void {
    this.currentPage = currentPage;
    this.updateQueryParam(currentPage);
    this.zone.runOutsideAngular(() => requestAnimationFrame(
      () => this.document.scrollingElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    ));
  }

  public trackBy(index: number, item: BlogArticle): string {
    return item.uuid
  }

  private initCurrentPage(): void {
    this.currentPage = this.isServer ?
      Number(new URL(this.currentLocation).searchParams.get('page') || '1') :
      Number(this.activatedRoute.snapshot.queryParamMap.get('page') || '1');
  }

  private loadBlogData(): void {
    this.blogService.data$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(data => {
      const uuid = this.activatedRoute.snapshot.firstChild.data?.uuid;
      this.thematic = data.thematics.data[uuid];
      const thematics = this.getThematic(data);

      this.prepareThematicsSelectorOptions(thematics);
      this.initThematicsLinks(thematics);
      this.initArticles(data);
      this.intiFiltersFormData();
      this.addSeoLinks();

      this.cdr.detectChanges();
    });
  }

  private initArticles(data: BlogIndex): void {
    this.sortOptions$.subscribe(options => {
        this.articles = this.getThematicArticles(data.articles).sort(options[0].sort)
      });
  }

  private initThematicsLinks(thematics: BlogThematic[]): void {
    this.thematicLinks = thematics.reduce<ThematicLinks>((acc: ThematicLinks, item: BlogThematic) => {
      acc[item.tag.id] = item.link;
      return acc;
    }, {});
  }

  private prepareThematicsSelectorOptions(thematics: BlogThematic[]): void {
    this.thematicsList$ = this.defaultThematic$
      .pipe(
        map((defaultThematic: Thematic) => {
          const thematicsOptions: Thematic[] = thematics.map((thematic: BlogThematic) => ({
            name: thematic.tag.title,
            id: thematic.tag.id,
            thematic,
          }));
          thematicsOptions.push(defaultThematic);

          return thematicsOptions;
        })
      );
  }

  private intiFiltersFormData(): void {
    this.sortOptions$
      .pipe(map(options => options.find(({ id }) => id === SortingType.timeDesc)))
      .subscribe(currentSorting => this.sortingControl.setValue(currentSorting));

    this.thematicsList$
      .pipe(map(thematics => {
        const currentThematic = thematics.find(({ id }) => id === this.thematic?.tag?.id);

        return currentThematic || thematics[thematics.length - 1];
      }))
      .subscribe(currentThematic => this.thematicControl.setValue(currentThematic))
  }

  private getThematicArticles(articlesData: BlogArticlesData): BlogArticle[] {
    const articles = Object.values(articlesData.data);

    if (!this.thematic) {
      return articles;
    }

    return articles.filter(item => item.thematics.some(thematic => thematic.id === this.thematic.tag.id));
  }

  private getThematic(data: BlogIndex): BlogThematic[] {
    const articleList = Object.values(data.articles.data);

    return Object.values(data.thematics.data)
      .filter(item => articleList.some(article => article.thematics.some(tag => tag.id === item.tag.id)))
      .sort((current: BlogThematic, prev: BlogThematic) => current.tag.title.localeCompare(prev.tag.title));
  }

  private initSortingControl(): void {
    this.sortingControl = this.fromBuilder.control(null);
    this.sortingControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selected: SortOption) => {
        this.articles.sort(selected.sort);
        this.cdr.detectChanges();
      });
  }

  private initSortingOptions(): void {
    const descFunction = (current: BlogArticle, prev: BlogArticle) => prev.orderDate - current.orderDate;
    const ascFunction = (current: BlogArticle, prev: BlogArticle) => current.orderDate - prev.orderDate;

    this.sortOptions$ = forkJoin([
      this.translateService.get('pages.BLOG.LABELS.SORT_TIME_DESC'),
      this.translateService.get('pages.BLOG.LABELS.SORT_TIME_ASC'),
    ]).pipe(
      map(([timeDescTranslation, timeAscTranslation]: [string, string]) => [
        { id: SortingType.timeDesc, label: timeDescTranslation, sort: descFunction },
        { id: SortingType.timeAsc, label: timeAscTranslation, sort: ascFunction },
      ]),
    );
  }

  private initThematicsControl(): void {
    this.thematicControl = this.fromBuilder.control(null);

    this.thematicControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ thematic }) => this.router.navigateByUrl((thematic as BlogThematic)?.link?.link || this.blogLink));

    this.defaultThematic$ = this.translateService.get(DEFAULT_THEMATIC.name)
      .pipe(
        map((defaultThematicName: string) => ({
          ...DEFAULT_THEMATIC,
          name: defaultThematicName,
        }))
      );
  }

  private initFilterForm(): void {
    this.filterForm = this.fromBuilder.group({
      thematic: this.thematicControl,
      sorting: this.sortingControl,
    });
  }

  private updateQueryParam(page: number): void {
    const thematicLink = this.thematic?.link?.link || this.blogLink;

    this.location.go(`${thematicLink}?page=${page}`);
  }

  private addSeoLinks(): void {
    if (!this.isServer) {
      return;
    }

    const pageUrl = new URL(this.currentLocation);
    const buttons = this.elementRef.nativeElement.getElementsByClassName('show-for-sr');
    Array.from(buttons as HTMLElement[]).filter((button: HTMLElement) => button.parentElement.localName === 'a')
      .forEach((button: HTMLElement) => {
        const buttonText = button.nextElementSibling?.innerHTML;

        if (!isNaN(Number(buttonText))) {
          const url = `${pageUrl.origin + pageUrl.pathname}?page=${buttonText}`;

          this.renderer.setAttribute(button.parentElement, 'href', url);
          this.renderer.setStyle(button.parentElement, 'text-decoration', 'none');
        }
      });

    const nextButton: HTMLElement = this.elementRef.nativeElement.getElementsByClassName('pagination-next')[0];
    const prevButton: HTMLElement = this.elementRef.nativeElement.getElementsByClassName('pagination-previous')[0];

    if (nextButton && (nextButton.firstChild as HTMLElement)?.localName === 'a') {
      const url = `${pageUrl.origin + pageUrl.pathname}?page=${this.currentPage + 1}`;

      this.renderer.setAttribute(nextButton.firstChild, 'href', url);
      this.renderer.setStyle(nextButton.firstChild, 'text-decoration', 'none');
    }

    if (prevButton && (prevButton.firstChild as HTMLElement)?.localName === 'a') {
      const url = `${pageUrl.origin + pageUrl.pathname}?page=${this.currentPage - 1}`;

      this.renderer.setAttribute(prevButton.firstChild, 'href', url);
      this.renderer.setStyle(prevButton.firstChild, 'text-decoration', 'none');
    }
  }

  private addMetaTags(): void {
    const pageQuery = this.activatedRoute.snapshot.queryParamMap.get('page');

    this.removeOldMetaTag();

    if (!this.isServer || !pageQuery) {
      return;
    }

    this.metaService.addTag({
      name: 'robots',
      content: 'noindex, follow'
    });
  }

  private removeOldMetaTag(): void {
    const metaTag = this.document.querySelector('[name=robots]');

    if (metaTag) {
      this.metaService.removeTagElement(metaTag as HTMLMetaElement)
    }
  }
}
