import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { MobileAppContentLinks } from '@common/model/mobileApplicationLinks';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { LanguageService } from '@common/language/language.service';

@Component({
  selector: 'app-mobile-application-links',
  templateUrl: './mobile-application-links.component.html',
  styleUrls: ['./mobile-application-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileApplicationLinksComponent extends AemFragmentComponent {
  public language: string;

  public get appleStoreImagePath(): string {
    if (this.data.designType === 'us-landing') return `assets/img/app-store/EN/app-store-badge-black.svg`
    return `assets/img/app-store/${this.language}/app-store-badge-black.svg`;
  }

  public get googlePlayImagePath(): string {
    if (this.data.designType === 'us-landing') return `assets/img/google-play/EN/google-play-badge-black.png`;
    return `assets/img/google-play/${this.language}/google-play-badge-black.png`;
  }

  constructor(
    private languageService: LanguageService,
    @Inject(AEM_DATA) public override data: MobileAppContentLinks,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.language = this.languageService.getCurrentLanguage();
  }
}
