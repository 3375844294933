export enum LanguageOptions {
  EN = 'EN',
  FR = 'FR',
}

export enum ConsentType {
  'INITIATED_MARKETING' = 'INITIATED_MARKETING',
  'GLOBAL_MARKETING' = 'GLOBAL_MARKETING',
  'INTERNAL_MARKETING' = 'INTERNAL_MARKETING',
  'EXTERNAL_MARKETING' = 'EXTERNAL_MARKETING',
  'MARKETING_CAMPAIGN' = 'MARKETING_CAMPAIGN',
  'EMAIL' = 'EMAIL',
  'SMS' = 'SMS',
  'PUSH' = 'PUSH'
}

export interface ConsentValue {
  EMAIL: string;
  SMS: string;
  PUSH: string;
}

export enum ServiceNotificationType {
  'SMS' = 'SMS',
  'E-MAIL_AND_SMS' = 'E-MAIL_AND_SMS',
  'E-MAIL_ONLY' = 'E-MAIL_ONLY',
  'SMS_ONLY' = 'SMS_ONLY',
  'E-MAIL' = 'E-MAIL',
  'PUSH' = 'PUSH',
  'NONE' = 'NONE'
}

export enum ServiceNotificationName {
  "TRIP_ALERTS" = "TRIP_ALERTS",
  "FLIGHT_ALERTS" = "FLIGHT_ALERTS",
  "LOUNGE_VOUCHERS" = "LOUNGE_VOUCHERS",
  "FLIGHT_COMPENSATION" = "FLIGHT_COMPENSATION"
}

export interface ServiceNotification {
  name: string;
  value: ServiceNotificationType;
  selected: boolean;
}

export enum NotificationsSteps {
  MAIN = 'MAIN',
  MARKETING = 'MARKETING',
  TRIP_ALERTS = 'TRIP_ALERTS',
  FLIGHT_ALERTS = 'FLIGHT_ALERTS',
  LOUNGE_VOUCHERS = 'LOUNGE_VOUCHERS',
  FLIGHT_COMPENSATION = 'FLIGHT_COMPENSATION',
  NOTIFICATION_SETTING = 'NOTIFICATION_SETTING'
}

export interface RegroupedNotifications {
  [key: string]: {
    consentTypes: string[];
    isBlocked: boolean
  };
}

export const TRIP_ALERTS_OPTIONS: ServiceNotification[] = [
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_AND_SMS',
    value: ServiceNotificationType['E-MAIL_AND_SMS'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_ONLY',
    value: ServiceNotificationType['E-MAIL_ONLY'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.NONE',
    value: ServiceNotificationType['NONE'],
    selected: false
  }
];

export const FLIGHT_ALERTS_OPTIONS: ServiceNotification[] = [
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_AND_SMS',
    value: ServiceNotificationType['E-MAIL_AND_SMS'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_ONLY',
    value: ServiceNotificationType['E-MAIL_ONLY'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.SMS_ONLY',
    value: ServiceNotificationType['SMS_ONLY'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.NONE',
    value: ServiceNotificationType['NONE'],
    selected: false
  }
];

export const LOUNGE_VOUCHERS_OPTIONS: ServiceNotification[] = [
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_AND_SMS',
    value: ServiceNotificationType['E-MAIL_AND_SMS'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_ONLY',
    value: ServiceNotificationType['E-MAIL_ONLY'],
    selected: false
  }
];

export const FLIGHT_COMPENSATION_OPTIONS: ServiceNotification[] = [
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_AND_SMS',
    value: ServiceNotificationType['E-MAIL_AND_SMS'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.E-MAIL_ONLY',
    value: ServiceNotificationType['E-MAIL_ONLY'],
    selected: false
  },
  {
    name: 'pages.NOTIFICATION.OPTIONS.SMS_ONLY',
    value: ServiceNotificationType['SMS_ONLY'],
    selected: false
  }
];
