import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginationModule } from '@kit/pagination/pagination.module';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlModule } from '@kit/safe-html/safe-html.module';
import { AemDocumentVaultComponent } from './document-vault.component';
import { ButtonModule } from '@kit/button/button.module';
import { IconModule } from '@kit/icon/icon.module';
import { DocumentVaultFolderComponent } from './folder/folder.component';
import { DocumentVaultFileComponent } from './file/file.component';
import { DateModule } from '@kit/date/date.module';
import { ContextMenuModule } from '@kit/context-menu/context-menu.module';
import { DocumentVaultFolderPageComponent } from './folder-page/folder-page.component';
import { BackButtonModule } from '@kit/back-button/back-button.module';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  declarations: [
    AemDocumentVaultComponent,
    DocumentVaultFolderComponent,
    DocumentVaultFileComponent,
    DocumentVaultFolderPageComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    PaginationModule,
    TranslateModule,
    SafeHtmlModule,
    ButtonModule,
    IconModule,
    DateModule,
    ContextMenuModule,
    BackButtonModule,
    LetModule,
  ]
})
export class AemDocumentVaultModule { }
