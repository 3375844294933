import { ChangeDetectionStrategy, Component, DestroyRef, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs';
import { dateToNativeDateInputFormat, isValidDate } from '@kit/utils/date.utils';
import { LanguageService } from '@common/language/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
  ],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() min: Date | string;
  @Input() max: Date | string = '9999-12-31';
  @Input() disabled = false;

  public innerControl = new UntypedFormControl(null);
  public focused = false;
  public placeholder = this.languageService.countryCode === 'US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy';

  constructor(
    private readonly languageService: LanguageService,
    private readonly destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      map(value => value ? new Date(value) : null),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((value: Date) => this.onChange(value));

    if (this.min instanceof Date) {
      this.min = dateToNativeDateInputFormat(this.min);
    }

    if (this.max instanceof Date) {
      this.max = dateToNativeDateInputFormat(this.max);
    }

    if (this.min > this.max) {
      throw new Error('max value should be less than min value');
    }
  }

  public onChange = (value: Date) => {};
  public onTouched = () => {};

  public writeValue(value: Date): void {
    if (value && !isValidDate(value)) {
      throw new Error('Incorrect date format');
    }

    this.innerControl.setValue(
      value && dateToNativeDateInputFormat(value),
      { emitEvent: false }
    );
  }

  public registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.innerControl.disable();
    } else {
      this.innerControl.enable();
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.onTouched();
  }

  public clear(): void {
    this.innerControl.setValue(null);
  }
}
