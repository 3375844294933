import { ChangeDetectionStrategy, Component, DestroyRef, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true
  }],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  public innerControl = new UntypedFormControl(null);
  public focused = false;
  public disabled = false;

  constructor(private readonly destroyRef: DestroyRef) {}

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => this.onChange(value));
  }

  public onChange = (value: Date) => {};
  public onTouched = () => {};

  public writeValue(value: Date): void {
    this.innerControl.setValue(
      value,
      { emitEvent: false }
    );
  }

  public registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.onTouched();
  }

  public clear(): void {
    this.innerControl.setValue(null);
  }
}
