<app-dialog-wrapper>
  <div dialogTitle class="h6 m-h4 dialog-header_title">
    <app-icon [icon]="data.icon"
              *ngIf="data.icon && !data.bigIcon"
              theme="lighten"
              size="large">
    </app-icon>
    {{ data.title }}
  </div>
  <ng-container dialogContent>
    <div class="big-icon">
      <app-icon [icon]="data.icon"
                *ngIf="data.icon && data.bigIcon"
                theme="amped"
                size="xx-large">
      </app-icon>
    </div>
    <div class="dialog-content">
      <div *ngIf="data.message" [class.centered-message]="data.centredMessage" class="h5 m-h3 dialog-message" >
        {{ data.message }}
      </div>
      <div *ngIf="data.info" class="t-normal m-t-small dialog-info">
        <app-safe-html [content]="data.info"></app-safe-html>
      </div>
      <div *ngIf="data.importantInfo" class="t-normal m-t-normal dialog-important-info">
        <app-icon icon="warning" theme="amped" size="large"></app-icon>
        <app-safe-html [content]="data.importantInfo"></app-safe-html>
      </div>
      <div *ngIf="data.warning" class="t-normal m-t-normal dialog-warning">
        <app-icon icon="warning" theme="warning" size="x-large"></app-icon>
        <app-safe-html [content]="data.warning"></app-safe-html>
      </div>
      <div *ngIf="data.cancelTitle || data.confirmTitle" class="dialog-actions" [class.reverse]="data.reverseButtonPosition">
        <app-button id="action-dialog-cancel" theme="bordered"
                    *ngIf="data.cancelTitle"
                    class="dialog-actions_item"
                    size="l"
                    type="full-width"
                    (click)="cancel()">
          {{ data.cancelTitle }}
        </app-button>
        <app-button id="action-dialog-confirm" size="l"
                    *ngIf="data.confirmTitle"
                    class="dialog-actions_item"
                    type="full-width"
                    (click)="confirm()">
          {{ data.confirmTitle }}
        </app-button>
      </div>
      <div *ngIf="data.noteTitle" class="t-normal m-t-small weight-bold dialog-note-title">
        {{ data.noteTitle }}
      </div>
      <div *ngIf="data.note" class="t-tiny m-t-small dialog-note">
        {{ data.note }}
      </div>
      <div *ngIf="data.bigNote" class="t-normal m-t-small">
        {{ data.bigNote }}
      </div>
    </div>
  </ng-container>
</app-dialog-wrapper>
