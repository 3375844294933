import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[let]'
})
export class LetDirective<T> {
  @Input()
  public let!: T;

  constructor() {
    inject(ViewContainerRef).createEmbeddedView(
      inject(TemplateRef<LetContext<T>>),
      new LetContext<T>(this),
    );
  }

  public static ngTemplateContextGuard<T>(
    _dir: LetDirective<T>,
    _ctx: unknown
  ): _ctx is LetContext<T> {
    return true;
  }
}

interface Context<T> {
  $implicit: T;
}

class LetContext<T> implements Context<T> {
  constructor(private readonly internalDirectiveInstance: LetDirective<T>) {}

  public get $implicit(): T {
    return this.internalDirectiveInstance.let;
  }

  public get let(): T {
    return this.internalDirectiveInstance.let;
  }
}


