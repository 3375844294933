import { ChangeDetectionStrategy, Component, DestroyRef, Input } from "@angular/core";
import { Flight, TripDetails } from '@pages/trip/trip.interfaces';
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "@kit/dialog/dialog.service";
import { CoTravelersListDialogData } from '../../../../aem-co-traveler/co-travelers-list-dialog/co-travelers-list-dialog-data';
import { CoTravelersListDialogComponent } from '../../../../aem-co-traveler/co-travelers-list-dialog/co-travelers-list-dialog.component';
import { BootstrapService } from "@common/bootstrap/bootstrap.service";
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { TranslateService } from "@ngx-translate/core";
import { ApiFlightService } from "@common/flight/api-flight.service";
import { filter, switchMap } from "rxjs";
import { FlightInfo, FlightStatus } from '@common/flight/flight.interfaces';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-trip-planner-flight',
  templateUrl: './trip-planner-flight.component.html',
  styleUrls: ['./trip-planner-flight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TripPlannerFlightComponent {
  @Input() currentTrip: TripDetails;
  @Input() flight: FlightInfo;
  @Input() boundUpdateCurrentTrip: Function;

  public readonly FlightStatus = FlightStatus;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly destroyRef: DestroyRef,
    private readonly translateService: TranslateService,
    private readonly flightService: ApiFlightService,
  ) {}

  public showCoTravelersList(flight: Flight): void {
    const { tripId } = this.route.firstChild.snapshot.params;

    this.dialogService.open(CoTravelersListDialogComponent, <CoTravelersListDialogData>{
      tripId: tripId,
      flightId: flight.flightId,
      coTravelers: flight.coTravelers,
      editable: !flight.lessThan24hBeforeDeparture
    });
  }

  public addCotraveler(flight: Flight): void {
    this.router.navigate([setRouterData(
      this.bootstrapService.link.flightSelectCotravelers,
      {
        tripId: this.currentTrip.tripId,
        flightId: flight.flightId,
      }
    )], { queryParams: { returnToTripWallet: true } });
  }

  public deleteFlight(flight: Flight): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DELETE_FLIGHT'),
      info: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DISCLAIMER_DELETE'),
      cancelTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.CANCEL'),
      confirmTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.DELETE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.flightService.deleteFlight(this.currentTrip.tripId, flight.flightId)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.boundUpdateCurrentTrip());
  }

}
