<section>
  <div class="shell" *ngIf="(policies$ | async) as policies">
    <ng-container *ngIf="policies?.length; else noActivePolicies">
      <h4 class="h4 m-h3">
        {{ 'pages.MY_INSURANCE.TITLES.CURRENT_N_FUTURE_POLICIES' | translate }}
      </h4>
      <div class="cards">
        <div *ngFor="let policy of policies" class="card">
          <div class="h5 m-h3 card_header">
            {{ 'pages.MY_INSURANCE.TITLES.INSURANCE_POLICY' | translate }}
          </div>
          <div class="card_body">
            <div class="card_table">
              <div class="row">
                <div class="row_title h6 h4">
                  <app-icon class="icon t-normal m-t-normal"
                            icon="form"
                            size="large"
                            theme="lighten">
                  </app-icon>
                  {{ 'pages.MY_INSURANCE.LABELS.POLICY_NAME' | translate }}
                </div>
                <div class="t-normal m-t-normal">
                  {{policy.productName}}
                </div>
              </div>
              <div class="row">
                <div class="row_title h6 h4">
                  <app-icon class="icon t-normal m-t-normal"
                            icon="form"
                            size="large"
                            theme="lighten">
                  </app-icon>
                  {{ 'pages.MY_INSURANCE.LABELS.POLICY_NUMBER' | translate }}
                </div>
                <div class="t-normal m-t-normal">
                  {{ policy.openPolicyNumber || policy.number}}
                </div>
              </div>
              <div class="row">
                <div class="row_title h6 h4">
                  <app-icon class="icon t-normal m-t-normal"
                            icon="form"
                            size="large"
                            theme="lighten">
                  </app-icon>
                  {{ 'pages.MY_INSURANCE.LABELS.DATES' | translate }}
                </div>
                <div class="t-normal m-t-normal">
                  {{policy.startDate | dateFormat : 'fullSpelled'}} - {{policy.endDate | dateFormat : 'fullSpelled'}}
                </div>
              </div>
            </div>
            <div class="card_buttons">
              <app-button (click)="onViewDetails(policy.number, policy.openPolicyNumber)" type="full-width" size="m">
                {{ 'pages.MY_INSURANCE.ACTIONS.VIEW_POLICY_DETAILS' | translate }}
              </app-button>
              <app-button (click)="deletePolicy(policy.number)" theme="bordered" icon="thrash" type="full-width" size="m">
                {{ 'pages.MY_INSURANCE.ACTIONS.REMOVE_POLICY' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
      <div class="add-button">
        <app-button theme="bordered"
                    type="big-width"
                    [appAemRouterLink]="bootstrapService.link.insuranceAddExistingPolicy">
          <app-icon class="icon"
                    icon="plus"
                    size="normal"
                    theme="lighten">
          </app-icon>
          {{ 'pages.MY_INSURANCE.ACTIONS.ADD_INSURANCE_POLICY' | translate }}
        </app-button>
      </div>
    </ng-container>
  </div>
</section>

<ng-template #noActivePolicies>
  <h4 class="h4 m-h3">
    {{ 'pages.MY_INSURANCE.TITLES.CURRENT_OR_FUTURE_POLICIES' | translate }}
  </h4>
  <p>{{ 'pages.MY_INSURANCE.DESCRIPTIONS.NO_ACTIVE_POLICIES' | translate }}</p>
</ng-template>
