<div *ngIf="selectedOptions.length < entitiesLimit; else limitWarning" class="input form-field"
     [class.form-field_focused]="focused"
     [class.form-field_disabled]="disabled">
  <div class="input_wrapper">
    <div class="input_search-icon" (click)="input.focus()">
      <app-icon class="icon"
                icon="search"
                size="large"
                theme="lighten">
      </app-icon>
    </div>
    <input #input
           [placeholder]="placeholder"
           [formControl]="searchControl"
           (focus)="onFocus()"
           (blur)="onBlur()"
           class="input_field text-small">
    <div class="input__action">
      <app-icon *ngIf="!disabled && clearButton && dirtyInput"
                (click)="clear($event)"
                class="input__action-icon icon"
                icon="close"
                size="large"
                theme="lighten">
      </app-icon>
    </div>
  </div>
  <div class="input_options text-tiny" *ngIf="expanded">
    <ng-container *let="options$ | async as options">
      <ng-container *ngIf="options?.length; else emptyPlaceholder">
        <div *ngFor="let option of options"
             class="input_options-item"
             (click)="select(option)">
          <div class="input_options-item-content">
            {{ option.description }}
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #emptyPlaceholder>
      <div class="input_options-item-empty">
        {{ 'forms.CREATE_TRIP.PLACEHOLDERS.EMPTY_SEARCH_RESULT' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<div class="entities" >
  <div *ngFor="let entity of selectedOptions"
       class="entity">
    <div class="t-small m-t-normal">{{entity.description}}</div>
    <app-button (click)="deleteElement(entity.description)"
                [disabled]="isButtonDisabled"
                type="icon-button"
                theme="black"
                size="xs"
                icon="close">
    </app-button>
  </div>
</div>

<ng-template #limitWarning>
  <div class="warning t-small m-t-normal">
    {{warningText}}
  </div>
</ng-template>
