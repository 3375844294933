import { Component, ChangeDetectionStrategy, OnInit, DestroyRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { notBlankValidator } from 'src/app/kit/utils/validators';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { PolicyDetails, PolicyPinCode } from '../../policy.interface';
import { InsurancePolicyStoreService } from '../../insurance-policy-store.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// deprecated
@Component({
  selector: 'app-insurance-details-pin',
  templateUrl: './insurance-details-pin.component.html',
  styleUrls: ['./insurance-details-pin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService]
})
export class InsuranceDetailsPinComponent implements OnInit {
  public pinControl: UntypedFormControl;

  private routePolicyCode: string;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly validationService: ServerErrorsValidationService,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly bootstrapService: BootstrapService,
    private readonly policyStoreService: InsurancePolicyStoreService,
  ) { }

  public ngOnInit(): void {
    this.initPolicyCode();
    this.initPinControl();
  }

  public goBack(): void {
    this.router.navigateByUrl(this.bootstrapService.link.myInsurancePolicies);
  }

  public onSubmit(): void {
    this.pinControl.markAsTouched();

    if (this.pinControl.invalid) {
      return;
    }

    const data: PolicyPinCode = {
      contractNumber: this.routePolicyCode.trim(),
      pin: this.pinControl.value.trim()
    };

    this.policyService.getPolicyDetails$(data.contractNumber).pipe(
      catchError(err => {
        this.validationService.handleServerError(err?.error);
        return throwError(() => err);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((policyDetails: PolicyDetails) => {
      this.policyStoreService.setItem(policyDetails);
      this.router.navigateByUrl(
        setRouterData(
          this.bootstrapService.link.insurancePolicy,
          { policyId: btoa(data.contractNumber) }
        ));
    });
  }

  private initPolicyCode(): void {
    this.route.queryParams.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(({ policyCode }) => {
      this.routePolicyCode = atob(policyCode)
    });
  }

  private initPinControl(): void {
    this.pinControl = new UntypedFormControl(
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
        wrapValidator(notBlankValidator, 'errors.CEAZ000_NotBlank')
      ],
      [
        this.validationService.createValidator('pinCode'),
        this.validationService.createValidator(),
      ]
    );
  }
}
