import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, distinctUntilChanged, map } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { FlightSelectCotravelersForm } from 'src/app/common/model/flightSelectCotravelersForm';
import { CoTraveler } from 'src/app/common/profile/profile.interfaces';
import { ApiFlightService } from '@common/flight/api-flight.service';
import { FlightInfo } from '@common/flight/flight.interfaces';
import { HasTimeGapPipe } from 'src/app/kit/date/time-gap/has-time-gap.pipe';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { getAllRouterParams, setRouterData } from 'src/app/kit/utils/router.utils';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-co-traveler-list',
  templateUrl: './co-traveler-list.component.html',
  styleUrls: ['./co-traveler-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ServerErrorsValidationService,
    HasTimeGapPipe
  ]
})
export class CoTravelerListComponent extends AemFragmentComponent implements OnInit {
  public flight: FlightInfo;
  public expired: string;

  public listForm = this.formBuilder.group({
    users: this.formBuilder.array([])
  })

  get usersFormArray(): UntypedFormArray {
    return this.listForm.controls["users"] as UntypedFormArray;
  }

  public readonly coTravelers: CoTraveler[] = this.route.firstChild.snapshot.data.coTraveler;
  public readonly tripId = this.route.firstChild.snapshot.params.tripId;
  public readonly flightId = this.route.firstChild.snapshot.params.flightId;
  public readonly returnToTripWallet = this.route.firstChild.snapshot.queryParams.returnToTripWallet;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
    private readonly flightService: ApiFlightService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly validationService: ServerErrorsValidationService,
    public readonly bootstrapService: BootstrapService,
    private readonly translateService: TranslateService,
    private readonly gapPipe: HasTimeGapPipe,
    @Inject(AEM_DATA) data: FlightSelectCotravelersForm,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    if (!this.coTravelers.length) {
      this.router.navigate([setRouterData(
        this.bootstrapService.link.flightAddCotraveler,
        getAllRouterParams(this.route.snapshot))],
        { queryParamsHandling: 'preserve' },
      );
      return;
    }

    this.flightService.getFlight(this.tripId, this.flightId)
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((flight: FlightInfo) => {
        this.flight = flight;

        this.coTravelers.forEach((coTraveler) => {
          const selected = flight.coTravelers?.some(el => coTraveler.id === el.id);
          this.usersFormArray.push(new UntypedFormControl(selected), { emitEvent: false });
        });

        this.usersFormArray.updateValueAndValidity();
        this.cdr.markForCheck();
      });

    this.usersFormArray.valueChanges.pipe(
      map((data: Boolean[]) => {
        return data.filter(Boolean).length === this.flightService.maxCotravelersCount;
      }),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(isMaxCount => {
      if (isMaxCount) {
        this.usersFormArray.controls.forEach(control => {
          if (!control.value) {
            control.disable({ emitEvent: false });
          }
        });
      } else {
        this.usersFormArray.controls.forEach(control => {
          control.enable({ emitEvent: false });
        });
      }
      this.cdr.markForCheck();
    });

    this.usersFormArray.addAsyncValidators(this.validationService.createValidator());
  }

  public goBack(): void {
    if (this.returnToTripWallet) {
      this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet,{ tripId: this.tripId }));

      return;
    }

    this.router.navigateByUrl(setRouterData(
      this.bootstrapService.link.flightBenefits,
      { tripId: this.tripId, flightId: this.flightId }
    ));
  }

  public submit(): void {
    const canEdit = !this.flight.lessThan24hBeforeDeparture;

    if (canEdit) {
      const selectedUsers = (this.usersFormArray.getRawValue() as boolean[])
        .map((checked: boolean, i: number) => checked ? this.coTravelers[i].id : null)
        .filter(Boolean);

      this.flightService.updateCotravelersList(this.tripId, this.flightId, selectedUsers).pipe(
        catchError(err => this.validationService.handleServerError(err?.error)),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => {
        this.returnToTripWallet
          ? this.router.navigateByUrl(setRouterData(this.bootstrapService.link.tripWallet,{ tripId: this.tripId }))
          : this.router.navigateByUrl(setRouterData(this.bootstrapService.link.flightsAddedPage, { tripId: this.tripId }));
      });

      return;
    }

    this.expired = this.translateService.instant('forms.CO_TRAVELER.ERROR');
  }
}
