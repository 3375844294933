import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CoTraveler, CoTravelerDTO } from "@common/profile/profile.interfaces";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiCotravelersService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
  ) {}

  public getCotravelers(): Observable<CoTraveler[]> {
    return this.http.get<CoTraveler[]>(`${this.baseUrl}/profile/cotravelers`);
  }

  public getCotravelerById(id: number): Observable<CoTraveler> {
    return this.getCotravelers().pipe(
      map(data => data.find(el => el.id === id))
    );
  }

  public addCotraveler(data: CoTravelerDTO): Observable<number> {
    return this.http.post<number>(
      `${this.baseUrl}/profile/cotravelers`,
      data
    );
  }

  public editCotraveler(data: CoTraveler): Observable<number> {
    return this.http.put<number>(
      `${this.baseUrl}/profile/cotravelers/${data.id}`,
      data
    );
  }

  public deleteCotraveler(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/profile/cotravelers/${id}`);
  }
}