import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSelectComponent } from './search-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from "@kit/icon/icon.module";
import { TranslateModule } from '@ngx-translate/core';
import { ActiveZoneModule } from '@kit/active-zone/active-zone.module';

@NgModule({
  declarations: [SearchSelectComponent],
  exports: [SearchSelectComponent],
  imports: [CommonModule, ReactiveFormsModule, IconModule, TranslateModule, ActiveZoneModule],
})
export class SearchSelectModule { }
