import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { map, Observable } from 'rxjs';
import { TripContentAndShapedImage } from '@common/model/tripContentAndShapedImage';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { endDatePassed } from '@kit/utils/date.utils';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { ActivityType } from '../../trip.const';
import { Activity, TripDetails } from '../../trip.interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// deprecated
@Component({
  selector: 'app-start-journey',
  templateUrl: './start-journey.component.html',
  styleUrls: ['./start-journey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartJourneyComponent extends AemBaseBlockComponent implements AfterViewInit {
  showJourneyBlock$: Observable<boolean> = this.route.firstChild.data.pipe(
    map((data: Data) => data.currentTrip),
    map((trip: TripDetails) =>
        !endDatePassed(trip.endDate) && !trip.actions?.some((activity: Activity) =>
          activity.type === ActivityType.FLIGHT
        )
    ),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly destroyRef: DestroyRef,
    @Inject(AEM_DATA) data: TripContentAndShapedImage,
  ) {
    super(data);
  }

  ngAfterViewInit(): void {
    this.showJourneyBlock$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((showBlock: boolean) => {
        if (!showBlock) {
          this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
        }
      });
  }
}
