import { ChangeDetectorRef, Component, DestroyRef, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HasTimeGapPipe } from 'src/app/kit/date/time-gap/has-time-gap.pipe';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { TripDetails as TripDetailsAEM } from '../../../../../common/model/models';
import { endDatePassed } from '@kit/utils/date.utils';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { ActivityType } from '../../../trip.const';
import { TripDetails } from '../../../trip.interfaces';
import { TripService } from '@pages/trip/trip.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-trip-planner',
  templateUrl: './trip-planner.component.html',
  styleUrls: ['./trip-planner.component.scss'],
  providers: [HasTimeGapPipe],
})
export class TripPlannerComponent implements OnInit, OnChanges {
  @Input() currentTrip: TripDetails;
  public showAddButton: boolean;
  public boundUpdateCurrentTrip: Function;

  public readonly ActivityType = ActivityType;
  public tripId = this.route.firstChild?.snapshot.params.tripId;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly route: ActivatedRoute,
    private readonly destroyRef: DestroyRef,
    private readonly tripService: TripService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(AEM_DATA) public aemData: TripDetailsAEM,
  ) { }

  public ngOnInit(){
    this.boundUpdateCurrentTrip = this.updateCurrentTrip.bind(this);
  }

  public ngOnChanges(): void {
    this.showAddButton = this.canShowAddButton();
  }

  private updateCurrentTrip(): void {
    const { tripId } = this.route.firstChild.snapshot.params;

    this.tripService.getTripById(tripId).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(trip => {
      this.currentTrip = trip;
      this.cdr.markForCheck();
    });
  }

  private canShowAddButton(): boolean {
    if (!this.currentTrip) {
      return false;
    }

    if (endDatePassed(this.currentTrip.endDate)) {
      return true;
    }

    return !!this.currentTrip.actions?.length;
  }
}
