import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, Observable, switchMap } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { FlightAddCotravelerForm } from 'src/app/common/model/flightAddCotravelerForm';
import { UserService } from 'src/app/common/user/user.service';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { notBlankValidator } from 'src/app/kit/utils/validators';
import { CREDENTIAL_REGEXP } from 'src/app/pages/account/components/co-travelers/co-travelers.const';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { getAllFormErrors } from "@kit/utils/form";
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiCotravelersService } from '@common/co-travelers/api-cotravelers.service';

@Component({
  selector: 'app-add-co-traveler',
  templateUrl: './add-co-traveler.component.html',
  styleUrls: ['./add-co-traveler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService],
})
export class AddCoTravelerComponent extends AemFragmentComponent implements OnInit {
  public userForm: UntypedFormGroup;

  private readonly tripId = this.route.firstChild.snapshot.params.tripId;
  private readonly flightId = this.route.firstChild.snapshot.params.flightId;
  private readonly returnToTripWallet = this.route.firstChild.snapshot.queryParams.returnToTripWallet;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
    private readonly validationService: ServerErrorsValidationService,
    private readonly coTravelersService: ApiCotravelersService,
    private readonly userService: UserService,
    private readonly destroyRef: DestroyRef,
    private readonly analyticsService: AnalyticsService,
    @Inject(AEM_DATA) data: FlightAddCotravelerForm,
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    this.userForm = this.formBuilder.group({
      firstName: [
        '', [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotNull'),
          wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('name')
      ],
      lastName: [
        '', [
          wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
          wrapValidator(notBlankValidator, 'errors.CEAZ000_NotNull'),
          wrapValidator(Validators.maxLength(50), 'errors.CEAZ000_Size'),
          wrapValidator(Validators.pattern(CREDENTIAL_REGEXP), 'errors.CEAZ000_Pattern')
        ],
        this.validationService.createValidator('surname')
      ]
    });
    this.userForm.addAsyncValidators(this.validationService.createValidator());
  }

  public onCancel(): void {
    this.coTravelersService.getCotravelers().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(coTravelers => {
      if (coTravelers.length) {
        this.router.navigate(
          [setRouterData(this.bootstrapService.link.flightSelectCotravelers, { tripId: this.tripId, flightId: this.flightId })],
          { queryParamsHandling: 'preserve' });

        return;
      }

      this.router.navigateByUrl(setRouterData(this.getOriginLink(), { tripId: this.tripId, flightId: this.flightId }));
    });
  }

  public onSave(): void {
    this.userForm.markAllAsTouched();

    if (getAllFormErrors(this.userForm)?.length) {
      this.analyticsService.validationError(this.userForm, this.constructor.name);
      return;
    }

    this.coTravelersService.addCotraveler({
      name: this.userForm.value.firstName?.trim(),
      surname: this.userForm.value.lastName?.trim()
    }).pipe(
      catchError(err => this.handleServerError(err)),
      switchMap(() => this.userService.reloadUserInfo$()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.router.navigate([setRouterData(
        this.bootstrapService.link.flightSelectCotravelers,
        { tripId: this.tripId, flightId: this.flightId }
      )], { queryParamsHandling: 'preserve' });
    });
  }

  private getOriginLink(): string {
    return this.returnToTripWallet
      ? this.bootstrapService.link.tripWallet
      : this.bootstrapService.link.flightBenefits;
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }
}
