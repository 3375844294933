import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoungeVoucher } from "@pages/account/components/lounge-vouchers/lounge-vouchers.interface";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ApiLoungeVouchersService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private http: HttpClient
  ) {}

  public getLoungeAccessVouchers(): Observable<LoungeVoucher[]> {
    return this.http.get<LoungeVoucher[]>(`${this.baseUrl}/profile/vouchers`);
  }

  public deleteLoungeAccessVoucher(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/profile/vouchers/${id}`);
  }
}