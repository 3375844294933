import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { catchError } from 'rxjs';
import { AuthService } from 'src/app/common/auth/auth.service';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { UserService } from 'src/app/common/user/user.service';
import { ApiFlightService } from '@common/flight/api-flight.service';
import { wrapValidator } from 'src/app/kit/field-error/field-error.utils';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { applyDaysOffset, dateToEndDay, getDate } from 'src/app/kit/utils/date.utils';
import { getAllFormErrors } from 'src/app/kit/utils/form';
import { maxDateValidator } from 'src/app/kit/utils/validators';
import { FLIGHT_NUMBER_REGEXP } from '../flight/flight.const';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-past-flight-compensation',
  templateUrl: './aem-past-flight-compensation.component.html',
  styleUrls: ['./aem-past-flight-compensation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService]
})
export class AemPastFlightCompensationComponent implements OnInit {
  public yesterday = applyDaysOffset(dateToEndDay(new Date()), -1);
  public compensationForm: UntypedFormGroup;
  public showConfirmationMessage = false;
  public authorized$ = this.userService.isAuth$;
  public registrationLink = this.bootstrapService.link.register;

  constructor(
    private readonly bootstrapService: BootstrapService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly flightService: ApiFlightService,
    private readonly validationService: ServerErrorsValidationService,
    private readonly analyticsService: AnalyticsService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    const flightControl = this.formBuilder.control(
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
        wrapValidator(Validators.pattern(FLIGHT_NUMBER_REGEXP), 'errors.CEAZ000_Pattern'),
      ],
      this.validationService.createValidator('flightNumber'),
    );
    const flightDateControl = this.formBuilder.control(
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotNull'),
        wrapValidator(maxDateValidator(this.yesterday), 'errors.CEAZ000_Past')
      ],
      this.validationService.createValidator('departureDate'),
    );

    this.compensationForm = this.formBuilder.group({
      flightNumber: flightControl,
      departureDate: flightDateControl,
    }, {
      asyncValidators: this.validationService.createValidator(),
    });
  }

  public submit(): void {
    this.compensationForm.markAllAsTouched();

    if (!getAllFormErrors(this.compensationForm).length) {
      const {flightNumber, departureDate} = this.compensationForm.value;

      this.flightService.getFlightCompensation(flightNumber, getDate(departureDate)).pipe(
        catchError((err) => {
          return this.validationService.handleServerError(err?.error);
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => {
        this.analyticTrigger(departureDate, flightNumber)
        this.compensationForm.reset();
        this.toggle();
      });

      return;
    }

    this.analyticsService.validationError(this.compensationForm, this.constructor.name);
  }

  public toggle(): void {
    this.showConfirmationMessage = !this.showConfirmationMessage;
    this.changeDetector.markForCheck();
  }

  public login(): void {
    this.authService.authorize();
  }

  private analyticTrigger(flightDate: string, flightNumber: string): void {
    this.analyticsService.triggerAction({
      category: 'flight_right',
      action: 'compensationChecked',
      label: `${getDate(new Date(flightDate))}_${flightNumber}`,
      value: 1,
    });
  }
}
