import { DestroyRef, Inject, Injectable } from '@angular/core';
import { UserService } from "@common/user/user.service";
import { delay, filter, map, Observable, of, switchMap, tap } from "rxjs";
import { AnalyticsService } from "@common/analytics/analytics.service";
import { TradedoublerService } from "@common/tradedoubler/tradedoubler.service";
import { UserProfile } from "@common/profile/profile.interfaces";
import { TradedoublerEvent } from "@common/tradedoubler/tradedoublerRouteData";
import { IS_BROWSER_PLATFORM } from "@kit/utils/ssr.utils";
import { DigitalDataService } from "@common/analytics/digital-data.service";
import { PremiumDetail } from "@pages/admin/components/user-edit/premium-details.interface";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiMembershipService } from '@common/membership/api-membership.service';

@Injectable({
  providedIn: 'root'
})
export class ActivationUserAnalyticsService {
  constructor(
    private readonly userService: UserService,
    private readonly analyticsService: AnalyticsService,
    private readonly tradedoublerService: TradedoublerService,
    private readonly digitalDataService: DigitalDataService,
    private readonly apiMembershipervice: ApiMembershipService,
    private readonly destroyRef: DestroyRef,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
  ) { }

  public initActivationUserAnalytic(): void {
    this.userService.isAuth$.pipe(
      switchMap(() => this.userService.userData$),
      filter((profile: UserProfile) => !!profile)
    ).subscribe((profile: UserProfile) => {
      this.digitalDataService.updateDigitalData();
      this.sentTradedoublerActivationAction(profile);
      of(null).pipe(
        delay(300),
        tap(() => this.sendSingUpAnalyticsAction(profile)),
        delay(300),
        tap(() => this.sendBundleOfferAnalyticsAction(profile)),
        delay(300),
        tap(() => this.sendPromoCodeAnalyticsAction(profile)),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe();
    });
  }

  private sentTradedoublerActivationAction(profile: UserProfile): void {
    if (this.isBrowserFirstAccess(profile)) {
      this.tradedoublerService.sendTradedoublerAction(TradedoublerEvent.activation);
    }
  }

  private sendSingUpAnalyticsAction(profile: UserProfile): void {
    if (!this.isBrowserFirstAccess(profile)) {
      return;
    }

    const label = profile.registrationSource === 'allyz.com' ? 'email' : profile.registrationSource;

    this.analyticsService.triggerAction({
      category: 'member_account',
      action: 'signUp',
      label: label,
      value: 1,
    });
  }

  private sendBundleOfferAnalyticsAction(profile: UserProfile): void {
    if (this.isBrowserFirstAccess(profile) && profile.registrationSource !== 'allyz.com') {
      this.getFirstUserMembership$()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((membership: PremiumDetail) => {
          if (membership?.insurance) {
            this.analyticsService.triggerAction({
              category: 'partner_subscription',
              action: 'memberUpgraded',
              label: membership?.insurance,
              value: 1,
              timeStamp: new Date().toISOString(),
            });
          }
        });
    }
  }

  private sendPromoCodeAnalyticsAction(profile: UserProfile): void {
    if (this.isBrowserFirstAccess(profile) && profile.registrationSource === 'allyz.com' && profile.promoCode) {
      this.analyticsService.triggerAction({
        category: 'partner_subscription',
        action: 'memberUpgraded',
        label: profile.promoCode,
        value: 1,
        timeStamp: new Date().toISOString(),
      });
    }
  }

  private isBrowserFirstAccess(profile: UserProfile): boolean {
    return this.isBrowser && profile && !profile.firstAccess && !profile.lastAccess;
  }

  private getFirstUserMembership$(): Observable<PremiumDetail> {
    return this.apiMembershipervice.getUserProfileMemberships().pipe(
      map((memberships: PremiumDetail[]) => {
          return memberships.sort((a: PremiumDetail, b: PremiumDetail) => a.id - b.id)[0]
        }
      ),
    );
  }
}
