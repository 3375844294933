import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, filter, switchMap } from 'rxjs';
import { CoTraveler } from 'src/app/common/profile/profile.interfaces';
import { ConfirmationDialogComponent } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from 'src/app/kit/dialog/dialog.service';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { DynamicComponentCotravelersList } from '@common/model/dynamicComponentCotravelersList';
import { AemFragmentComponent } from '@kit/aem-base-block/aem-fragment';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiCotravelersService } from '@common/co-travelers/api-cotravelers.service';

@Component({
  selector: 'app-co-travelers',
  templateUrl: './co-travelers.component.html',
  styleUrls: ['./co-travelers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemCoTravelersPageComponent extends AemFragmentComponent implements OnInit {
  public cotravelersList: CoTraveler[] = [];

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly cotravelersService: ApiCotravelersService,
    public readonly bootstrapService: BootstrapService,
    @Inject(AEM_DATA) data: DynamicComponentCotravelersList,
  ) {
    super(data);
  }

  public ngOnInit(): void {
    this.cotravelersService.getCotravelers().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((cotravelers: CoTraveler[]) => {
      this.cotravelersList = cotravelers;
      this.cdr.detectChanges();
    });
  }

  public delete(data: CoTraveler): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.CO_TRAVELERS.DELETE_DIALOG.MESSAGE', data),
      confirmTitle: this.translateService.instant('pages.CO_TRAVELERS.DELETE_DIALOG.CONFIRM'),
      cancelTitle: this.translateService.instant('pages.CO_TRAVELERS.DELETE_DIALOG.CANCEL'),
      note: this.translateService.instant('pages.CO_TRAVELERS.DELETE_DIALOG.NOTE', data),
      reverseButtonPosition: true,
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.cotravelersService.deleteCotraveler(data.id)),
      concatMap(() => this.cotravelersService.getCotravelers()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((cotravelers: CoTraveler[]) => {
       this.cotravelersList = cotravelers;
       this.cdr.detectChanges();
    });
  }
}
