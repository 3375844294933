<div class="shell">
  <div class="notifications__header">
    <h1 class="notifications__header__text">{{ 'pages.NOTIFICATION.TITLES.NOTIFICATIONS' | translate }}</h1>
  </div>
  <div class="notifications__list">
    <app-tag icon="message-new"
             theme="white"
             [content]="'pages.NOTIFICATION.TITLES.MARKETING' | translate"
             (click)="proceedStep(notificationSteps.MARKETING)">
    </app-tag>
    <app-tag *ngIf="!notifications.CRISIS24.isBlocked" 
             icon="earth-pin"
             theme="white"
             [content]="'pages.NOTIFICATION.TITLES.TRIP_ALERTS' | translate"
             (click)="proceedStep(notificationSteps.TRIP_ALERTS)">
    </app-tag>
    <app-tag *ngIf="!notifications.AMADEUS.isBlocked"
             icon="plane"
             theme="white"
             [content]="'pages.NOTIFICATION.TITLES.FLIGHT_ALERTS' | translate"
             (click)="proceedStep(notificationSteps.FLIGHT_ALERTS)">
    </app-tag>
    <app-tag *ngIf="!notifications.COLLINSON.isBlocked"
             icon="sofa"
             theme="white"
             [content]="'pages.NOTIFICATION.TITLES.LOUNGE_VOUCHERS' | translate"
             (click)="proceedStep(notificationSteps.LOUNGE_VOUCHERS)">
    </app-tag>
    <app-tag *ngIf="!notifications.FLIGHT_RIGHT.isBlocked"
             icon="plane-with-check"
             theme="white"
             [content]="'pages.NOTIFICATION.TITLES.FLIGHT_COMPENSATION' | translate"
             (click)="proceedStep(notificationSteps.FLIGHT_COMPENSATION)">
    </app-tag>
    <app-tag *ngIf="showLanguageNotifications"
             icon="settings"
             theme="white"
             [content]="'pages.NOTIFICATION.TITLES.NOTIFICATION_SETTING' | translate"
             (click)="proceedStep(notificationSteps.NOTIFICATION_SETTING)">
    </app-tag>
  </div>
  <div class="notifications__disclaimer">
    <app-icon icon="round-warning"
              theme="amped"
              size="x-large">
    </app-icon>
    {{ 'pages.NOTIFICATION.DISCLAIMER.DISCLAIMER_TEXT' | translate }}
  </div>
</div>