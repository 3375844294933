<header [class.us-landing]="data.designType === 'us-landing'" class="header">
  <div class="header__shell shell">
    <app-breadcrumbs *ngIf="data.hasBreadcrumbs"></app-breadcrumbs>
    <app-back-button *ngIf="data.hasBackButton" class="back-button display-block"></app-back-button>

    <div [class.display-block]="!data.rightColumnComponents?.length"
         [class.alternative-grid-template-row]="!data.text"
         id="header-content"
         class="header__content">
      <div *ngIf="data.title"
           [style.justify-content]="titleAlignment"
           [style.color]="titleFontColor"
           [class.align-items-center]="this.leftBlockOnlyTitle"
           class="header__title">
        <h1 class="header__title_text">{{ data.title | translate: { userName: userService.userName } }}</h1>
      </div>

      <div *ngFor="let dynamicData of dynamicRightSideContent$ | async"
           [class.display-none]="!data.rightColumnComponents?.length"
           [class.grid-row-span-1]="this.leftBlockOnlyTitle"
           class="right-container">
        <ng-container *ngIf="dynamicData?.component">
          <ng-container *ngComponentOutlet="dynamicData.component; injector: dynamicData.injector"></ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="userData$ | async as userData">
        <app-safe-html id="header-rich-text"
                       class="header__text t-normal m-t-normal"
                       [content]="data.text | translate: {
                        userMembership: userData?.membershipStatus | appTranslateMembership,
                        premiumExpirationDate: userData?.premiumEnd | dateFormat: 'fullSpelledWithFullMonth'}">
        </app-safe-html>
      </ng-container>
      <div [class.max-width-none]="!data.rightColumnComponents?.length"
           [class.us-landing-right-column]="data.designType === 'us-landing'"
           class="header__dynamic">
        <ng-container *ngFor="let dynamicData of dynamicLeftSideContent$ | async">
          <ng-container *ngComponentOutlet="dynamicData.component; injector: dynamicData.injector"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container *ngIf="data.designType === 'us-landing'" class="figure-wrapper">
    <div class="figure-wrapper">
      <div class="figure-one"></div>
      <div class="figure-two"></div>
      <div class="figure-three"></div>
      <div class="figure-four"></div>
    </div>
    <div class="figure-five"></div>
  </ng-container>
</header>
