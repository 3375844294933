import { Component, OnInit, ChangeDetectionStrategy, Inject, DestroyRef } from '@angular/core';
import { ServerErrorsValidationService } from "@kit/server-errors/server-errors-validation.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { DIALOG_DATA, DIALOG_REF } from "@kit/dialog/dialog.const";
import { DialogRef } from "@kit/dialog/dialog.interfaces";
import { wrapValidator } from "@kit/field-error/field-error.utils";
import { codeToPattern } from "@kit/phone/phone.utils";
import { UserService } from "@common/user/user.service";
import { ApiProfileService } from "@common/profile/api-profile.service";
import { catchError, map, switchMap } from "rxjs";
import { UserProfile, UserProfileForm } from "@common/profile/profile.interfaces";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-phone-add-dialog',
  templateUrl: './phone-add-dialog.component.html',
  styleUrls: ['./phone-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerErrorsValidationService]
})
export class PhoneAddDialogComponent implements OnInit {
  public phoneControl: UntypedFormControl;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly validationService: ServerErrorsValidationService,
    private readonly userService: UserService,
    private readonly profileService: ApiProfileService,
    @Inject(DIALOG_DATA) private dialogData: unknown,
    @Inject(DIALOG_REF) private dialogRef: DialogRef<unknown>,
  ) { }

  ngOnInit(): void {
    this.initPhoneControl();
  }

  public save(): void {
    this.phoneControl.markAsTouched();

    if (!this.phoneControl.invalid) {
      this.profileService.getUserProfile()
        .pipe(
          map((profile: UserProfile) => this.normalizeUserData(profile)),
          switchMap((profile: UserProfileForm) => this.profileService.editUserProfile(profile)),
          takeUntilDestroyed(this.destroyRef),
          catchError((err) => this.validationService.handleServerError(err?.error)),
        )
        .subscribe(() => this.dialogRef.close(true));
    }
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  private initPhoneControl(): void {
    this.phoneControl = new UntypedFormControl(
      null,
      [
        wrapValidator(Validators.required, 'errors.CEAZ000_NotBlank'),
        wrapValidator(Validators.pattern(codeToPattern(this.userService.location)), 'errors.CEAZ000_Pattern'),
      ],
      [this.validationService.createValidator()],
    );
  }

  private normalizeUserData(profile: UserProfile): UserProfileForm {
    return {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      phone: this.phoneControl.value,
    };
  }
}
