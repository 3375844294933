import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DestroyRef, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService, OAuthStorage, TokenResponse } from 'angular-oauth2-oidc';
import {
  filter,
  Observable,
  ReplaySubject,
  tap,
} from 'rxjs';
import { IS_SERVER_PLATFORM } from 'src/app/kit/utils/ssr.utils';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '@kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from '@kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { DialogService } from '@kit/dialog/dialog.service';
import { LanguageService } from '../language/language.service';
import { ANONYMOUS_TOKEN_EXPIRES_KEY, ANONYMOUS_TOKEN_KEY } from './auth.const';
import { AnonymousTokenInfo, AuthServerConfig } from './auth.interfaces';
import { SERVER_AUTH_DATA_STATE } from "@common/start-up/start-up.const";
import { TransferState } from "@angular/platform-browser";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authorized = false;
  public tokenRefreshed$: Observable<void>;

  private readonly _loginResult$ = new ReplaySubject<boolean>(1);
  private readonly _anonymousToken$ = new ReplaySubject<string>(1);
  private readonly _authConfig: AuthServerConfig = this.transfer.get(SERVER_AUTH_DATA_STATE, null);
  private homeRoute = this.languageService.isVisaEnUrl() ? '/en-fr' : '/';

  public get anonymousToken$(): Observable<string> {
    return this._anonymousToken$.asObservable();
  }

  public get loginResult$(): Observable<boolean> {
    return this._loginResult$.asObservable().pipe(
      tap((authorized: boolean) => this.authorized = authorized),
    );
  }

  public get hasValidOfflineAnonymousToken(): boolean {
    const anonymousToken = this.oauthStorage.getItem(ANONYMOUS_TOKEN_KEY);
    const anonymousExpiresAt = Number(this.oauthStorage.getItem(ANONYMOUS_TOKEN_EXPIRES_KEY));

    return anonymousToken && anonymousExpiresAt > Date.now()
  }

  constructor(
    private readonly oauth: OAuthService,
    private readonly http: HttpClient,
    private readonly dialog: DialogService,
    private readonly translate: TranslateService,
    private readonly languageService: LanguageService,
    private readonly oauthStorage: OAuthStorage,
    private readonly router: Router,
    private readonly transfer: TransferState,
    private readonly destroyRef: DestroyRef,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
  ) {
    if (this.isServer) {
      return;
    }

    const anonymousToken = this.oauthStorage.getItem(ANONYMOUS_TOKEN_KEY);

    if (this.hasValidOfflineAnonymousToken) {
      this._anonymousToken$.next(anonymousToken);
    } else {
      this.refreshAnonymousToken();
    }

    this.setupOAuthService();
  }

  public tryLogin(): Promise<boolean> {
    return this.oauth.loadDiscoveryDocumentAndTryLogin()
      .then((isAuth) => {
        const authorized = isAuth && this.oauth.hasValidAccessToken();

        this._loginResult$.next(authorized);
        return authorized;
      })
      .catch(() => {
        this._loginResult$.next(false);
        return false;
      });
  }

  public authorize(): void {
    this.oauth.initCodeFlow();
  }

  public logOut(): void {
    this.oauth.revokeTokenAndLogout()
      .then(() => this._loginResult$.next(false));
  }

  public safeLogout(noRedirectToLogoutUrl = false): void {
    this.oauth.logOut(noRedirectToLogoutUrl);
    this._loginResult$.next(false);
    this.router.navigate([this.homeRoute]);
  }

  public refreshToken(): Promise<TokenResponse> {
    return this.oauth.refreshToken();
  }

  public notifyAboutSuccessDeletion(email: string): void {
    this.translate.get('global.NOTIFICATION_DIALOG.MESSAGES.ACCOUNT_SUCCESSFULLY_DELETED', { email })
      .subscribe((message: string) => this.dialog.open(ConfirmationDialogComponent, <ConfirmDialogData>{
        message: message,
      }))
  }

  public refreshAnonymousToken(): Observable<AnonymousTokenInfo> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const data = new HttpParams({
      fromObject: {
        client_id: this._authConfig.clientId,
        client_secret: this._authConfig.sk,
        grant_type: 'client_credentials',
      }
    });

    const query$ = this.http.post<AnonymousTokenInfo>(
      `${ environment.api.anonymousAuth }/client_credential/accesstoken`,
      data.toString(),
      { headers }
    );

    query$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((info) => {
        this._anonymousToken$.next(info.access_token);
        this.oauthStorage.setItem(ANONYMOUS_TOKEN_KEY, info.access_token);
        this.oauthStorage.setItem(ANONYMOUS_TOKEN_EXPIRES_KEY, (info.issued_at + info.expires_in * 1000).toString());
      });

    return query$;
  }

  private setupOAuthService(): void {
    let postfix = this.languageService.isVisaEnUrl() ? '/en-fr' : '';

    this.oauth.configure({
      issuer: environment.api.oidcProxy,
      tokenEndpoint: `${ environment.api.oidcProxy }oauth/token`,
      userinfoEndpoint: `${ environment.api.oidcProxy }userinfo`,
      clientId: this._authConfig.clientId,
      responseType: environment.oidcConfig.response_type,
      redirectUri: window.location.origin + postfix,
      scope: environment.oidcConfig.scope,
    });

    this.oauth.setupAutomaticSilentRefresh();

    this.tokenRefreshed$ = <Observable<any>>this.oauth.events.pipe(
      filter(({ type }) => type === 'silently_refreshed'),
    );
  }
}
