<app-trip-hero-block (goBack)="goBack()">
  <ng-container *let="isAddingFlightAvailable$ | async as isAddingFlightAvailable">
    <div *ngIf="isAddingFlightAvailable === 'success'" class="add-flight_form" [formGroup]="flightForm">
      <app-label [label]="'forms.FLIGHT.LABELS.FLIGHT_NUMBER' | translate" [required]="true">
        <app-input id="flight-number" [placeholder]="'forms.FLIGHT.PLACEHOLDERS.FLIGHT_NUMBER' | translate" formControlName="flightNumber">
        </app-input>
        <app-field-error controlName="flightNumber"></app-field-error>
        <app-field-error [control]="flightForm"></app-field-error>
        <div *ngIf="showManuallyButton" class="add-flight__manually">
          {{ 'forms.FLIGHT.NOTIFICATION_MANUALLY' | translate }}
        </div>
      </app-label>
      <app-label [label]="'forms.FLIGHT.LABELS.FLIGHT_DATE' | translate" [required]="true">
        <app-date-picker  id="flight-date" formControlName="departure">
        </app-date-picker>
        <app-field-error controlName="departure"></app-field-error>
      </app-label>
      <div *ngIf="'forms.FLIGHT.DISCLAIMER' | translate as content"
           class="m-t-normal notification-text">
            {{ content }}
      </div>
      <app-button id="action-flight-add" type="full-width" size="l" (click)="addFlight()">
        {{ 'forms.FLIGHT.ACTIONS.ADD_MY_FLIGHT' | translate }}
      </app-button>
      <app-button id="action-flight-go-manualy" *ngIf="showManuallyButton" type="full-width" theme="pure" size="l"
                  [appAemRouterLink]="bootstrapService.link.flightAddManually"
                  [aemRouterQueryParams]="{ flightNumber: flightForm.controls.flightNumber.value, newTrip: newTrip }">
        {{ 'forms.FLIGHT.ACTIONS.ADD_FLIGHT_MANUALLY' | translate }}
      </app-button>
      <app-button *ngIf="newTrip"
                  id="action-flight-skip"
                  class="link-button skip-button"
                  theme="inherit"
                  size="l"
                  (click)="skipStep()">
        {{ 'forms.FLIGHT.ACTIONS.SKIP_THIS_STEP' | translate }}
      </app-button>
    </div>

    <div *ngIf="isAddingFlightAvailable === 'error'" class="add-flight_form add-flight_form__max-flights">
      <app-safe-html [content]="'forms.FLIGHT.MAXIMUM_OF_FLIGHTS_TITLE' | translate"
                     class="h5 m-h3 form-title">
      </app-safe-html>
      <app-safe-html [content]="'forms.FLIGHT.MAXIMUM_OF_FLIGHTS_TEXT' | translate"
                     class="t-normal m-t-normal">
      </app-safe-html>
      <app-button type="full-width"
                  theme="bordered"
                  size="l"
                  (click)="goToAllTrips()"
                  id="action-go-to-all-trips">
        {{ 'forms.FLIGHT.ACTIONS.GO_TO_MY_TRIPS' | translate }}
      </app-button>
    </div>
  </ng-container>
</app-trip-hero-block>
