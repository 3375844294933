import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { TripList } from '@common/model/tripList';
import { AemBaseBlockComponent } from '@kit/aem-base-block/aem-base-block';
import { IS_BROWSER_PLATFORM } from '@kit/utils/ssr.utils';
import { AEM_DATA } from '@pages/dynamic/dynamic-render/dynamic-render.const';
import { TripDetails, TripsList } from '../../trip.interfaces';
import { TripService } from '../../trip.service';
import { Router } from '@angular/router';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-my-trips',
  templateUrl: './my-trips.component.html',
  styleUrls: ['./my-trips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyTripsComponent extends AemBaseBlockComponent implements OnInit {
  public trips$ = new Subject<TripsList>();
  public tripCounter$: Observable<number>;

  constructor(
    private readonly tripService: TripService,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
    @Inject(AEM_DATA) data: TripList,
    private readonly destroyRef: DestroyRef,
    public readonly bootstrapService: BootstrapService,
    private readonly router: Router
  ) {
    super(data);
  }

  ngOnInit(): void {
    this.refreshTrips();

    this.tripCounter$ = this.trips$.pipe(
      map((list) => list.currentTrips.length + list.upcomingTrips.length + list.pastTrips.length)
    );
  }

  public onDelete(): void {
    this.refreshTrips();
  }

  public trackByFn(index: number, item: TripDetails): number {
    return item.tripId;
  }

  public mergeTrip(): void {
    this.router.navigateByUrl(this.bootstrapService.link?.mergeTripsPage);
  }

  private refreshTrips(): void {
    this.tripService.getTrips().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(trips => {
      this.trips$.next(trips);
    });
  }
}
