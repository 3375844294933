import { LanguageService } from '@common/language/language.service';
import { Market } from '@common/language/language.const';

export const LOCALE_DATE: { [key:string]: string } = {
  [Market.FR]: 'fr-FR',
  [Market.ES]: 'es-ES',
  [Market.DE]: 'de-DE',
  [Market.IT]: 'it-IT',
  [Market.NL]: 'nl-NL',
  [Market.PL]: 'pl-PL',
  [Market.AT]: 'de-AT',
  [Market.AU]: 'en-AU',
};

export const dateLocaleFactory = (languageService: LanguageService) => languageService.localizationCode;
