import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '@common/language/language.service';
import { WindowRef } from '@common/window-service/window.service';
import { LumiToken } from '@kit/lumi-widget/lumi.interface';

@Injectable({
  providedIn: 'root'
})
export class LumiWidgetService {
  private apiPath: string = environment.api.baseUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly languageService: LanguageService,
    private readonly windowRef: WindowRef,
  ) { }

  public getLumiToken$(): Observable<LumiToken> {
    return this.http.get<LumiToken>(`${this.apiPath}/widgets/lumi-login`);
  }

  public initWidget(name: string, wrapper: string, token: any): void {
    const languageCode: string =  this.languageService.languageCode;
    const countryCode: string = this.languageService.countryCode;
    const config = {
      elementID: wrapper,
      userAccessToken: token,
      lumiWidgetConfig: {
        userAccessServices: {
          SYMPTOM_CHECKER: true,
          TELECONSULTATION: true,
          MEDICATION_DELIVERY: true,
          APPOINTMENT_BOOKING: true,
          DOCTOR_CHAT: true,
          SMO: true,
        },
        screenRedirect: name,
        screenParams: {},
        partnerCountry: countryCode.toLowerCase(),
        language: `${languageCode.toLowerCase()}-${languageCode.toUpperCase()}`,
        partnerPlatform: 'Android',
        encKey: token.aesKey,
        isEmbeddedWidget: true,
        drChatUserDataNickName: "Allyz User",
        drChatHideSupportButton: true,
        hideDrChatCloseButton: true,
        drChatTitle: "Dr. Chat",
        hideCloseButton: true,
      }
    }

    // @ts-ignore
    this.windowRef.nativeWindow.window.lumi.initWidget(config);
  }
}
