import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  Input,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() public placeholder = '';

  public focused = false;
  public disabled = false;
  public innerControl = new UntypedFormControl({ value: null, disabled: this.disabled });

  public get checked(): boolean {
    return this.innerControl.value;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) { }

  public ngOnInit(): void {
    this.innerControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => this.onChange(value));
  }

  public onChange = (value: string) => { };
  public onTouched = () => { };

  public writeValue(value: string): void {
    this.innerControl.setValue(value, { emitEvent: false });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.innerControl[isDisabled ? 'disable' : 'enable']({ emitEvent: false });
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.onTouched();
  }

  public toggle(): void {
    this.innerControl.setValue(!this.checked);
  }
}
