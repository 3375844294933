<section class="section">
  <div class="shell">
    <div class="succeeded">
      <div class="succeeded_title h5 m-h3">
        {{
          reverification
            ? ('pages.MY_INSURANCE.TITLES.SUCCEEDED_REVERIFICATION_1' | translate)
            : ('pages.MY_INSURANCE.TITLES.SUCCEEDED_1' | translate)
        }}
        <a (click)="onViewPolicy()" class="succeeded_title_link">{{ policyCode }}</a>
        {{
          reverification
            ? ('pages.MY_INSURANCE.TITLES.SUCCEEDED_REVERIFICATION_2' | translate)
            : ('pages.MY_INSURANCE.TITLES.SUCCEEDED_2' | translate)
        }}
      </div>
      <div class="succeeded_text t-small m-t-normal">
        {{
          reverification
            ? ('pages.MY_INSURANCE.DESCRIPTIONS.SUCCEEDED_REVERIFICATION' | translate)
            : ('pages.MY_INSURANCE.DESCRIPTIONS.SUCCEEDED' | translate)
        }}
      </div>
      <div class="succeeded_buttons">
        <app-button *ngIf="showViewPolicyButton"
                    (click)="onViewPolicy()"
                    class="succeeded_button"
                    type="full-width">
          {{ 'pages.MY_INSURANCE.ACTIONS.VIEW_POLICY' | translate }}
        </app-button>
        <app-button [appAemRouterLink]="bootstrapService.link.myInsurancePolicies"
                    class="succeeded_button"
                    type="full-width"
                    theme="bordered">
          {{ 'pages.MY_INSURANCE.ACTIONS.CLOSE' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</section>
