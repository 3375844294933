import { DestroyRef, Injectable } from '@angular/core';
import { AsyncValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, Subject, switchMap, take, throwError } from 'rxjs';
import { FieldValidationError } from '../field-error/field-error.interfaces';
import { ServerValidationError, ServerValidationErrorResponse } from './server-errors.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ServerErrorsValidationService {
  private errors$ = new Subject<Map<string, ServerValidationError[]>>();
  private readonly commonErrorName = 'common';

  constructor(
    private readonly translationService: TranslateService,
    private readonly destroyRef: DestroyRef,
  ) {}

  public getErrorTranslationByCode(code: string): Observable<string> {
    return this.translationService.get(`errors.${code}`);
  }

  public createValidator(fieldName: string = this.commonErrorName): AsyncValidatorFn {
    return (): Observable<FieldValidationError | null> => this.errors$.pipe(
      switchMap((errors) => {
        if (errors.has(fieldName)) {
          const [error] = errors.get(fieldName);

          return this.getErrorTranslationByCode(error.errorCode).pipe(
            map((errorMessage) => ({ err: errorMessage })),
          );
        }

        return of(null)
      }),
      take(1),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  public handleServerError(error: ServerValidationErrorResponse): Observable<any> {
    if (error?.fault?.errors) {
      this.errors$.next(this.mapErrors(error.fault.errors));
    }

    return throwError(error);
  }

  private mapErrors(errors: ServerValidationError[]): Map<string, ServerValidationError[]> {
    const errorsMap = new Map<string, ServerValidationError[]>();

    errors.forEach((error) => {
      const fieldName = error.fieldName || this.commonErrorName;
      const errors = errorsMap.get(fieldName) || [];

      errorsMap.set(fieldName, [...errors, error]);
    });

    return errorsMap;
  }
}
