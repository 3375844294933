import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from "@angular/router";
import { AppStorage } from "@kit/utils/ssr.utils";
import { Tradedoubler, TradedoublerEvent, TradedoublerRouteData } from "@common/tradedoubler/tradedoublerRouteData";
import { take } from "rxjs";
import { getCountryCode } from "@common/language/language.const";
import { DOCUMENT } from "@angular/common";
import { AuthService } from "@common/auth/auth.service";
import { ApiTradedoublerService } from './api-tradedoubler.service';

export const TRADEDOUBLER_STORAGE_KEY = 'tradedoubler';

@Injectable({
  providedIn: 'root'
})
export class TradedoublerService {
  constructor(
    private readonly appStorage: AppStorage,
    private readonly apiTradedoublerService: ApiTradedoublerService,
    private readonly authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  public saveTradedoublerData(route: ActivatedRouteSnapshot): void {
    const tradedoublerData: TradedoublerRouteData = {
      ac: route.queryParamMap.get('ac') || null,
      affId: route.queryParamMap.get('affId') || null,
      tduid: route.queryParamMap.get('tduid') || null,
      pc: route.queryParamMap.get('pc') || null,
    };

    this.appStorage.setItem(TRADEDOUBLER_STORAGE_KEY, JSON.stringify(tradedoublerData));
  }

  public sendTradedoublerAction(event: TradedoublerEvent, orderNumber?: string, orderValue?: number): void {
    const tradedoublerData = this.getTradedoublerData();

    if(!(!!tradedoublerData?.affId && !!tradedoublerData?.tduid)) {
      return;
    }

    const body: Tradedoubler = {
      residence: getCountryCode(document.location?.host).toLowerCase(),
      eventType: event,
      affiliateId: tradedoublerData.affId,
      userId: tradedoublerData.tduid,
    }

    if (tradedoublerData.ac) body.affiliateCode = tradedoublerData.ac;
    if (orderNumber) body.orderNumber = orderNumber;
    if (orderValue) body.orderValue = orderValue;

    this.apiTradedoublerService.sendTradedoublerData(body, this.authService.authorized)
      .pipe(take(1))
      .subscribe();
  }

  public getTradedoublerData(): TradedoublerRouteData | null {
    const data = this.appStorage.getItem(TRADEDOUBLER_STORAGE_KEY);

    return data ? JSON.parse(data) as TradedoublerRouteData : null;
  }

  public getParamsString(): string {
    const tradedoublerData: TradedoublerRouteData = this.getTradedoublerData();
    const params: URLSearchParams = new URLSearchParams();

    if (tradedoublerData?.tduid) params.append('tduid', tradedoublerData.tduid);
    if (tradedoublerData?.affId) params.append('affId', tradedoublerData.affId);
    if (tradedoublerData?.ac) params.append('ac', tradedoublerData.ac);
    if (tradedoublerData?.pc) params.append('pc', tradedoublerData.pc);

    return params.toString();
  }
}
