import { Component, OnInit, ChangeDetectionStrategy, DestroyRef } from '@angular/core';
import { Observable } from 'rxjs';
import { PolicyDetails } from '../../policy.interface';
import { ApiInsurancePolicyService } from '../../insurance-policy.service';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-insurance-policy-details',
  templateUrl: './insurance-policy-details.component.html',
  styleUrls: ['./insurance-policy-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancePolicyDetailsComponent implements OnInit {
  public policyDetails$: Observable<PolicyDetails>;

  private readonly policyNumber: string = atob(this.route.firstChild.snapshot.params.policyId);

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly destroyRef: DestroyRef,
    private readonly policyService: ApiInsurancePolicyService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  public ngOnInit(): void {
    this.initPolicyDetails();
  }

  public removePolice(): void {
    this.policyService.deletePolicy$(this.policyNumber)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.router.navigate([this.bootstrapService.link.myInsurancePolicies]))
  }

  private initPolicyDetails(): void {
    this.policyDetails$ = this.policyService.getPolicyDetails$(this.policyNumber);
  }
}

