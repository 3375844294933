import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LumiDoctorChatComponent } from './widgets/lumi-doctor-chat/lumi-doctor-chat.component';
import { LumiSymptomCheckerComponent } from './widgets/lumi-symptom-checker/lumi-symptom-checker.component';
import { LumiTeleconsultationComponent } from './widgets/lumi-teleconsultation/lumi-teleconsultation.component';

@NgModule({
  declarations: [LumiDoctorChatComponent, LumiSymptomCheckerComponent, LumiTeleconsultationComponent],
  exports: [LumiDoctorChatComponent, LumiSymptomCheckerComponent],
  imports: [CommonModule],
})
export class LumiWidgetModule { }
