import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, Inject, DestroyRef } from '@angular/core';
import { WindowRef } from "@common/window-service/window.service";
import { TranslateService } from "@ngx-translate/core";
import { IS_SERVER_PLATFORM } from "@kit/utils/ssr.utils";
import { Observable, of } from "rxjs";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const MOBILE_SCREEN_WIDTH = 480;

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit {
  @Input() entities: any[];
  @Input() entitiesOnPage: number;
  @Input() entitiesTotalElements: number;
  @Input() id?: string;
  @Input() theme: 'purple' | 'grey' = 'purple';
  @Input() visiblePagesCount: number = 9;

  @Output() changePage = new EventEmitter<number>();

  public prevLabel$: Observable<string>;
  public nextLabel$: Observable<string>;

  constructor(
    private readonly windowRef: WindowRef,
    private readonly translateService: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
    @Inject(IS_SERVER_PLATFORM) private isServer: boolean,
  ) { }

  ngOnInit(): void {
    this.updateLabels();
    this.updateVisiblePagesCount();

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        if(event instanceof NavigationStart && event.navigationTrigger !== 'imperative') {
          setTimeout(() => {
            this.onPageNumberChange(this.route.snapshot.queryParams?.page || 1);
          }, 0)
        }
      })
  }

  public onPageNumberChange(pageNumber: number): void {
    this.changePage.emit(pageNumber);
  }

  private updateVisiblePagesCount(): void {
    if (this.windowRef.nativeWindow.innerWidth < MOBILE_SCREEN_WIDTH) {
      this.visiblePagesCount = 5;
    }
  }

  private updateLabels(): void {
    if (this.isServer) {
      return;
    }

    if (this.windowRef.nativeWindow.innerWidth > MOBILE_SCREEN_WIDTH) {
      this.prevLabel$ = this.translateService.get('global.PAGINATION.LABELS.PREVIOUS');
      this.nextLabel$ = this.translateService.get('global.PAGINATION.LABELS.NEXT');

      return;
    }

    this.prevLabel$ = of(null);
    this.nextLabel$ = of(null);
  }
}
