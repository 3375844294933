/**
 * Content API
 * The API which will provide the content for Angular application to render Allyz websites
 *
 * OpenAPI spec version: v0.1
 * Contact: tbd@allyz.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CTA } from './cTA';
import { Entitlements } from './entitlements';

export interface CTAAction extends CTA {
    /**
     * Action.
     */
    action: CTAAction.ActionEnum;
    entitlements?: Entitlements;
}
export namespace CTAAction {
    export type ActionEnum =
      'healthcare-professional' |
      'chat-with-doctor' |
      'log-out' |
      'login' |
      'login-once' |
      'survay' |
      'add-insurance-policy' |
      'upgrade-insurance-policy' |
      'upgrade-referral-code' |
      'insurance-product-booking' |
      'faq-back-button' |
      'send-invite-email' |
      'onetrust-cookies-settings' |
      'open-insurance-request' |
      'track-insurance-request' |
      'teleconsultation' |
      'air-doctor' |
      'buy-travel-insurance-action' |
      'open-policy-details' |
      'book-teleconsultation';
    export const ActionEnum = {
        HealthcareProfessional: 'healthcare-professional' as ActionEnum,
        ChatWithDoctor: 'chat-with-doctor' as ActionEnum,
        LogOut: 'log-out' as ActionEnum,
        Login: 'login' as ActionEnum,
        LoginOnce: 'login-once' as ActionEnum,
        Survay: 'survay' as ActionEnum,
        AddInsurancePolicy: 'add-insurance-policy' as ActionEnum,
        UpgradeInsurancePolicy: 'upgrade-insurance-policy' as ActionEnum,
        UpgradeReferralCode: 'upgrade-referral-code' as ActionEnum,
        InsuranceProductBooking: 'insurance-product-booking' as ActionEnum,
        FaqBackButton: 'faq-back-button' as ActionEnum,
        SendInviteEmail: 'send-invite-email' as ActionEnum,
        OnetrustCookiesSettings: 'onetrust-cookies-settings' as ActionEnum,
        OpenInsuranceRequest: 'open-insurance-request' as ActionEnum,
        TrackInsuranceRequest: 'track-insurance-request' as ActionEnum,
        Teleconsultation: 'book-teleconsultation' as ActionEnum,
        AirDoctorButton: 'air-doctor' as ActionEnum,
        BuyTravelInsurance: 'buy-travel-insurance-action' as ActionEnum,
        OpenPolicyDetails: 'open-policy-details' as ActionEnum,
    };
}
