import { DestroyRef, Injectable } from '@angular/core';
import { AppSessionStorage } from '@kit/utils/ssr.utils';
import { PolicyDetails } from './policy.interface';
import { interval } from 'rxjs';
import { HOUR, SECOND } from '@kit/date/date.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface StoreItem {
  policy: PolicyDetails;
  timestamp: number;
}

const STORAGE_KEY = 'insurance_policy';
const CHECK_INTERVAL = 30 * SECOND;

@Injectable({
  providedIn: 'root'
})
export class InsurancePolicyStoreService {
  constructor(
    private readonly sessionStorage: AppSessionStorage,
    private readonly destroyRef: DestroyRef,
  ) {
    this.startObserver();
  }

  get store(): StoreItem[] {
    return JSON.parse(this.sessionStorage.getItem(STORAGE_KEY)) || [];
  }

  public setItem(policy: PolicyDetails): void {
    const item: StoreItem = {
      policy,
      timestamp: new Date().valueOf(),
    }
    const store: StoreItem[] = this.store.filter((item: StoreItem) => item.policy.policyNumber !== policy.policyNumber);

    store.push(item);
    this.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(store));
  }

  public getItem(policyNumber: string): PolicyDetails {
    return this.store.find((item: StoreItem) => item.policy.policyNumber === policyNumber)?.policy || null;
  }

  private startObserver(): void {
    interval(CHECK_INTERVAL)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
      const currentDateStamp = Date.now();
      const storeLength = this.store.length;
      const store: StoreItem[] = this.store.filter((item: StoreItem) => ((currentDateStamp - item.timestamp) < HOUR));

      if (storeLength !== store.length) {
        this.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(store));
      }
    })
  }
}
