import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, delay, finalize, Observable, switchMap, tap } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { ApiFlightService } from '@common/flight/api-flight.service';
import { FlightInfo } from '@common/flight/flight.interfaces';
import { HasTimeGapPipe } from 'src/app/kit/date/time-gap/has-time-gap.pipe';
import { ServerErrorsValidationService } from 'src/app/kit/server-errors/server-errors-validation.service';
import { setRouterData } from 'src/app/kit/utils/router.utils';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { GlobalLoaderService } from '@kit/global-loader/global-loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-flight-preview',
  templateUrl: './flight-preview.component.html',
  styleUrls: ['./flight-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    HasTimeGapPipe,
    ServerErrorsValidationService,
  ]
})
export class FlightPreviewComponent implements OnInit {
  @Input() selectedFlight: FlightInfo;
  @Output() goPreviousStep = new EventEmitter<void>();

  public fakeControl: UntypedFormControl;
  private tripId: string = this.route.firstChild.snapshot.paramMap.get('tripId');

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly flightApi: ApiFlightService,
    private readonly destroyRef: DestroyRef,
    private readonly bootstrapService: BootstrapService,
    private readonly analyticsService: AnalyticsService,
    private readonly loaderService: GlobalLoaderService,
    private readonly validationService: ServerErrorsValidationService,
  ) { }

  ngOnInit(): void {
    this.fakeControl = this.formBuilder.control(null, null, this.validationService.createValidator())
  }

  public goBack() {
    this.goPreviousStep.emit();
  }

  public addFlight(): void {
    this.fakeControl.markAllAsTouched();
    this.fakeControl.patchValue(null);
    this.flightApi.addFlightToTrip(this.tripId, this.selectedFlight).pipe(
      tap(() => this.loaderService.show()),
      delay(3000),
      switchMap(({ flightId }) => this.flightApi.getFlight(this.tripId, flightId.toString())),
      catchError((err) => this.handleServerError(err)),
      finalize(() => this.loaderService.hide()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((flight: FlightInfo) => {
      this.sendAddFlightAnalytics();
      this.onFlightAdded(flight);
    });
  }

  private onFlightAdded(flight: FlightInfo): void {
    const canAddCotravelers = !flight.lessThan24hBeforeDeparture;

    this.router.navigateByUrl(setRouterData(
      canAddCotravelers
        ? this.bootstrapService.link.flightBenefits
        : this.bootstrapService.link.flightsAddedPage,
      { tripId: this.tripId, flightId: flight.flightId }
    ));
  }

  private handleServerError(err: HttpErrorResponse): Observable<any> {
    this.analyticsService.validationServerError(err?.error, this.constructor.name);

    return this.validationService.handleServerError(err?.error);
  }

  private sendAddFlightAnalytics(): void {
    this.analyticsService.triggerAction({
      category: 'travel_wallet',
      action: 'flightAdded',
      label: `${this.selectedFlight.departure.date}_${this.selectedFlight.arrival.date}_${this.selectedFlight.arrival.country.name}`,
      value: 1,
    });
  }
}
