import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { IconList } from '@kit/icon/icon.interface';
import { DocumentVaultService } from '../document-vault.service';
import { File, Document } from '@common/documents-vault/api-documents.interface';
import { map, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApiDocumentsService } from '@common/documents-vault/api-documents.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-document-vault-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentVaultFileComponent implements OnInit {
  @Input() public file: File;
  @Input() public currentPage: number;
  @Input() public pageSize: number;
  @Input() public totalElements: number;
  @Output() public deleteFile = new EventEmitter<Document>();
  @Output() public changeCurrentPage = new EventEmitter<number>();

  public iconName: IconList;

  constructor(
    private readonly documentVaultService: DocumentVaultService,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly apiDocumentsService: ApiDocumentsService,
  ) {}

  public ngOnInit(): void {
    this.resolveIconNames(this.file);
  }

  public delete(file: File): void {
    this.documentVaultService.deleteFile(file).pipe(
      switchMap(() => this.route.queryParams),
      map(params => params['folderId']),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((folderId) => {
      this.getOutOfBoundCorrection();
      if (folderId) {
        this.apiDocumentsService.getDocuments(this.pageSize, this.currentPage - 1, folderId)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((value) => this.deleteFile.emit(value));
      } else {
        this.documentVaultService.getUserVaultData(this.pageSize, this.currentPage - 1);
      }

      this.cdr.markForCheck();
    });
  }

  public download(file: File): void {
    this.documentVaultService.downloadFile(file);
  }

  private getOutOfBoundCorrection(): void {
    const lastPage = Math.ceil((this.totalElements - 1) / this.pageSize);
    if (lastPage && lastPage < this.currentPage) {
      this.currentPage = lastPage;
      this.changeCurrentPage.emit(this.currentPage);
    }
  }

  private resolveIconNames(file: File): void {
    const fileExtension = file.extension;

    switch (fileExtension) {
      case 'pdf':
        this.iconName = 'pdf-icon';
        break;
      default: this.iconName = 'image-icon';
    }
  }

}
