export enum ProfileStatus {
  Registered = 'REGISTERED',
  Active =  'ACTIVE',
  Deleted = 'DELETED',
}

export interface UserProfileDTO {
  id: number;
  accountId?: number;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  language?: string;
  dateOfBirth?: string;
  residence?: string;
  profileStatus?: ProfileStatus;
  membershipStatus?: MembershipStatus;
  membershipDuration?: string;
  premiumStart?: string;
  premiumEnd?: string;
  created?: string;
  deleted?: string;
  firstAccess?: string;
  lastAccess?: string;
  lastChange?: string;
  promoCode?: string;
  identificationMethod?: string;
  marketingConsent?: boolean;
  noOfCoTravellers?: string;
  flightNotificationSmsConsent?: boolean;
  consents?: AccountNotification[];
  registrationSource: string;
  signUpType?: SignUpType;
  premiumLastChange?: string;
  owner?: string;
}

export interface UserProfile extends Omit<UserProfileDTO, 'membershipStatus'> {
  membershipStatus: UserType;
  encodedEmail?: string;
}

export interface UserProfileExtended extends Omit<UserProfileDTO, 'membershipStatus' | 'notificationSettings'| 'coTravelers' | 'activationCode'> {
  membershipStatus: UserType;
  notificationSettings: AccountNotification[],
  coTravelers: CoTraveler[],
  activationCode: ActivationCode
}

export interface ProfileSearchResultDTO {
  profiles: UserProfileDTO[];
  pagination: {
    pageSize: number;
    totalElements: number;
  }
}

export interface ProfileSearchResult {
  profiles: UserProfile[];
  pagination: ProfileSearchPagination;
}

export interface ProfileSearchPagination {
  pageSize: number;
  totalElements: number;
}

export interface UserProfileForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface UserSearchOptionsDTO {
  email: string;
  firstName: string;
  lastName: string;
  residence: string;
  membershipStatus: MembershipStatus;
  profileStatus: ProfileStatus;
  fromCreated: string;
  toCreated: string;
  profileId: string;
}

export enum UserType {
  Anonymous = 'anonymous',
  Freemium = 'freemium',
  Premium = 'premium',
}

export enum MembershipStatus {
  STANDARD = 'STANDARD',
  PLUS = 'PLUS',
}

export enum MarketingPartner {
  ALLYZ = "ALLYZ",
}

export enum ServicePartner {
  AMADEUS = "AMADEUS",
  CRISIS24 = "CRISIS24",
  FLIGHT_RIGHT = "FLIGHT_RIGHT",
  COLLINSON = "COLLINSON",
  ADOBE_CAMPAIGN = "ADOBE_CAMPAIGN"
}

export interface CoTraveler {
  id: number;
  name: string;
  surname: string;
}

export interface CoTravelerDTO {
  name: string;
  surname: string;
}

export interface AccountNotification {
  consentType: string,
  consentValue: boolean,
  partnerName: ServicePartner | MarketingPartner,
  isBlocked?: boolean
}

export interface ActivationCode {
  activationCodeValue?: string;
  country: {
    countryId: number;
    name?: string;
    code?: string;
  }
  activated?: string;
}

export interface MembershipsValidationResponse {
  id: string;
  membershipAmount: number;
  userAmount: number;
  uri: string;
}

export interface ActivationCodesData {
  activationCodes: ActivationCode[];
  created: string;
}

export enum SignUpType {
  REGULAR_REGISTRATION = 'REGULAR_REGISTRATION',
  WIDGET_REGISTRATION = 'WIDGET_REGISTRATION',
  API_REGISTRATION = 'API_REGISTRATION'
}

export interface UserProfilePathOptions {
  op: ProfilePatchOp,
  path: ProfilePatchPath,
  value: string
}

export enum ProfilePatchPath {
  FIRST_NAME = '/firstname',
  LAST_NAME = '/lastname',
  PHONE = '/phone',
  LANGUAGE = '/language'
}

export enum ProfilePatchOp {
  REPLACE = 'replace', // replace only for phone
  REMOVE = 'remove'
}
