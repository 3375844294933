<div class="shell">
  <div class="notification__header">
    <h1 class="notification__header__text">{{ 'pages.NOTIFICATION.TITLES.MARKETING' | translate }}</h1>
  </div>
  <div class="notification__item">
    <div class="notification__item__header">
      <span class="notification__item__title t-normal weight-medium">
        {{ 'pages.NOTIFICATION.SUBTITLES.MARKETING_NOTIFICATIONS' | translate }}
      </span>
      <app-icon [appTooltip]="'pages.NOTIFICATION.TOOLTIP.MARKETING_COMMUNICATIONS' | translate"
                icon="info-fill"
                size="large"
                theme="invert"
                tooltipFontSize="small"
                tooltipPosition="bottom">
      </app-icon>
    </div>
    <div class="notification__item__body">
      <p class="notification__item__text t-small">
        <app-safe-html [content]="'pages.NOTIFICATION.DESCRIPTIONS.MARKETING_COMMUNICATIONS' | translate">
        </app-safe-html>
      </p>

      <ng-container>

        <div class="notification__toggle" *ngIf="isGlobalMarketingConsent; else internalAndExternalConsents">
          <app-icon icon="letter"
                    size="x-large"
                    theme="amped">
          </app-icon>
          <p class="zero-margin">
            {{ 'pages.NOTIFICATION.LABELS.MARKETING_COMMUNICATIONS_GLOBAL' | translate }}
          </p>
          <app-slide-toggle [checked]="globalMarketingConsentValue"
                            (click)="switchMarketingNotification(consentType.GLOBAL_MARKETING, $event)"
                            id="notification-marketing-global">
          </app-slide-toggle>
        </div>

        <ng-template #internalAndExternalConsents>
          <div class="notification__toggle">
            <app-icon icon="letter" size="x-large" theme="amped">
            </app-icon>
            <p class="zero-margin">
              {{ 'pages.NOTIFICATION.LABELS.MARKETING_COMMUNICATIONS_INTERNAL' | translate }}
            </p>
            <app-slide-toggle [checked]="internalMarketingConsentValue"
                              (click)="switchMarketingNotification(consentType.INTERNAL_MARKETING, $event)"
                              id="notification-marketing-internal">
            </app-slide-toggle>
          </div>

          <div class="notification__toggle">
            <app-icon icon="letter" size="x-large" theme="amped">
            </app-icon>
            <p class="zero-margin">
              <app-safe-html [content]="'pages.NOTIFICATION.LABELS.MARKETING_COMMUNICATIONS_EXTERNAL' | translate ">
              </app-safe-html>
            </p>
            <app-slide-toggle [checked]="externalMarketingConsentValue"
                              (click)="switchMarketingNotification(consentType.EXTERNAL_MARKETING, $event)"
                              id="notification-marketing-external">
            </app-slide-toggle>
          </div>

        </ng-template>
      </ng-container>
    </div>
  </div>
</div>