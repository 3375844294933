import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveZoneDirective } from '@kit/active-zone/active-zone.directive';

@NgModule({
  declarations: [ActiveZoneDirective],
  imports: [CommonModule],
  exports: [ActiveZoneDirective],
})
export class ActiveZoneModule { }
