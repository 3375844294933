import { ChangeDetectionStrategy, Component, DestroyRef, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BootstrapService } from "@common/bootstrap/bootstrap.service";
import { TripDetails } from '@pages/trip/trip.interfaces';
import { ConfirmationDialogComponent } from "@kit/dialog/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogData } from "@kit/dialog/confirmation-dialog/confirmation-dialog.interface";
import { DialogService } from "@kit/dialog/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Hotel } from "@pages/trip/components/hotel/hotel.interface";
import { HotelService } from "@pages/trip/components/hotel/hotel.service";
import { filter, switchMap } from "rxjs";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-trip-planner-hotel',
  templateUrl: './trip-planner-hotel.component.html',
  styleUrls: ['./trip-planner-hotel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TripPlannerHotelComponent {
  @Input() currentTrip: TripDetails;
  @Input() hotel: Hotel;
  @Input() boundUpdateCurrentTrip: Function;

  constructor(
    public readonly bootstrapService: BootstrapService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly destroyRef: DestroyRef,
    private readonly hotelService: HotelService) {
  }

  public deleteHotel(hotel: Hotel): void {
    const { tripId } = this.route.firstChild.snapshot.params;

    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      title: this.translateService.instant('global.CONFIRMATION_DIALOG.TITLES.CONFIRMATION'),
      message: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DELETE_HOTEL'),
      info: this.translateService.instant('global.CONFIRMATION_DIALOG.MESSAGES.DISCLAIMER_DELETE'),
      cancelTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.CANCEL'),
      confirmTitle: this.translateService.instant('global.CONFIRMATION_DIALOG.ACTIONS.DELETE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.hotelService.delete(tripId, hotel.hotelId)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.boundUpdateCurrentTrip());
  }

}
