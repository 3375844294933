import { Component, DestroyRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AccountNotification, ServicePartner } from '@common/profile/profile.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@pages/account/components/notification/notification.service';
import { filter, map, Observable, switchMap } from 'rxjs';
import { BootstrapService } from 'src/app/common/bootstrap/bootstrap.service';
import { TripList } from 'src/app/common/model/models';
import { ConfirmationDialogComponent } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogData } from 'src/app/kit/dialog/confirmation-dialog/confirmation-dialog.interface';
import { AEM_DATA } from 'src/app/pages/dynamic/dynamic-render/dynamic-render.const';
import { DialogService } from '@kit/dialog/dialog.service';
import { TripDetails } from '../../trip.interfaces';
import { TripService } from '../../trip.service';
import { AlertInfoDialogData } from '../alert-info-dialog/alert-info-dialog-data';
import { AlertInfoDialogComponent } from '../alert-info-dialog/alert-info-dialog.component';
import { NotificationsInfoDialogComponent } from '../notifications-info-dialog/notifications-info-dialog.component';
import { CountyImageService } from "@common/country-image/county-image.service";
import { Router } from "@angular/router";
import { ConsentType } from '@pages/account/components/notification/notification-options.consts';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-trip-card-detailed',
  templateUrl: './trip-card-detailed.component.html',
  styleUrls: ['./trip-card-detailed.component.scss'],
})
export class TripCardDetailedComponent implements OnInit {
  @Input() public data: TripDetails;
  @Input() public currentAndFutureTripCounter: number;
  @Output() public delete = new EventEmitter<void>();

  public showShieldIcon$: Observable<boolean>;
  public countryImagePath: string;

  constructor(
    @Inject(AEM_DATA) public aemData: TripList,
    public readonly bootstrapService: BootstrapService,
    private readonly dialogService: DialogService,
    private readonly destroyRef: DestroyRef,
    private readonly tripService: TripService,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly countyImageService: CountyImageService,
    private readonly router: Router,
    private readonly bootstrap: BootstrapService,
  ) { }

  ngOnInit(): void {
    this.countryImagePath = this.countyImageService.getCountyImagePath(this.data.destinationPoints[0]?.country?.code);
    this.showShieldIcon$ = this.notificationService.userNotificationSettings$
      .pipe(map((settings: AccountNotification[]) => !settings
        .find(setting => setting.partnerName === ServicePartner.CRISIS24 && setting.consentType === ConsentType.EMAIL).consentValue));
  }

  public openNotificationsInfoDialog(): void {
    this.dialogService.open(NotificationsInfoDialogComponent);
  }

  public openAlertInfoDialog(): void {
    this.dialogService.open(AlertInfoDialogComponent, <AlertInfoDialogData>{
      countryId: this.data.destinationPoints[0]?.country?.countryId
    });
  }

  public mergeTrip(): void {
    this.router.navigateByUrl(this.bootstrap.link.mergeTripsPage);
  }

  public deleteTrip(tripId: number): void {
    this.dialogService.open(ConfirmationDialogComponent, <ConfirmDialogData>{
      message: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.DIALOG_TITLE'),
      info: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CONTENT_MESSAGE'),
      cancelTitle: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CANCEL_TITLE'),
      confirmTitle: this.translateService.instant('pages.MY_TRIPS.DELETE_TRIP_DIALOG.CONFIRMATION_TITLE'),
      reverseButtonPosition: true
    }).afterClosed$.pipe(
      filter(value => value === ConfirmationDialogComponent.CONFIRM),
      switchMap(() => this.tripService.deleteTrip(tripId)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.delete.emit());
  }

  public onImageLoadError(): void {
    this.countryImagePath = this.countyImageService.getDefaultImagePath();
  }
}
