import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@common/user/user.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { catchError, of, switchMap } from "rxjs";
import { ProfilePatchOp, ProfilePatchPath, UserProfilePathOptions } from "@common/profile/profile.interfaces";
import { ApiProfileService } from "@common/profile/api-profile.service";
import { LanguageOptions } from "../../notification-options.consts";

@Component({
  selector: 'app-language-notifications',
  templateUrl: './language-notifications.component.html',
  styleUrls: ['./language-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageNotificationsComponent implements OnInit {
  public languageNotificationSelects = [
    {
      name: this.translateService.instant('pages.NOTIFICATION.OPTIONS.EN'),
      value: LanguageOptions.EN
    },
    {
      name: this.translateService.instant('pages.NOTIFICATION.OPTIONS.FR'),
      value: LanguageOptions.FR
    }
  ];
  public languageNotificationSelectControl: FormControl = this.formBuilder.control(null);

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private apiProfileService: ApiProfileService,
    private destroyRef: DestroyRef
  ) {
  }

  public ngOnInit(): void {
    this.initLanguageNotificationSettings();
    this.initLanguageNotificationSubscription();
  }

  private initLanguageNotificationSettings(): void {
    const userLanguage = this.userService.userData.language.toUpperCase();
    const defaultOption = this.languageNotificationSelects.find(
      (option) => LanguageOptions[option.value].toUpperCase() === userLanguage
    );

    if (defaultOption) {
      this.languageNotificationSelectControl.setValue(defaultOption.value, { emitEvent: false });
    }
  }

  private initLanguageNotificationSubscription(): void {
    this.languageNotificationSelectControl.valueChanges.pipe(
      switchMap((value: LanguageOptions) => {
        const requestOptions: UserProfilePathOptions = {
          op: ProfilePatchOp.REPLACE,
          path: ProfilePatchPath.LANGUAGE,
          value: LanguageOptions[value]
        };

        return this.apiProfileService.updateUserProfile([requestOptions]).pipe(
          catchError(() => of([]))
        );
      }),
      switchMap(() => this.userService.reloadUserInfo$()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

}
