import { DOCUMENT } from '@angular/common';
import { Component, ChangeDetectionStrategy, Renderer2, Inject, DestroyRef } from '@angular/core';
import { DISABLE_GLOBAL_SCROLL_CLASS } from '../../app.const';
import { GlobalLoaderService } from './global-loader.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoaderComponent {
  constructor(
    public loaderService: GlobalLoaderService,
    private destroyRef: DestroyRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loaderService.loading$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((loading) => {
      if (loading) {
        this.renderer.addClass(this.document.body, DISABLE_GLOBAL_SCROLL_CLASS);
      } else {
        this.renderer.removeClass(this.document.body, DISABLE_GLOBAL_SCROLL_CLASS);
      }
    });
  }
}
