import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordSecurityComponent } from './password-security.component';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@kit/let/let.module';

@NgModule({
  declarations: [PasswordSecurityComponent],
  exports: [PasswordSecurityComponent],
    imports: [CommonModule, TranslateModule, LetModule]
})
export class PasswordSecurityModule { }
