import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BootstrapService } from '@common/bootstrap/bootstrap.service';
import { LanguageService } from '@common/language/language.service';
import { DocumentVaultService } from './document-vault.service';
import { Observable, map, switchMap, BehaviorSubject } from 'rxjs';
import { File, FolderContent, VaultData } from '@common/documents-vault/api-documents.interface';
import { environment } from 'src/environments/environment';
import { UserService } from '@common/user/user.service';
import { UserType } from '@common/profile/profile.interfaces';
import { TranslateService } from "@ngx-translate/core";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-document-vault',
  templateUrl: './document-vault.component.html',
  styleUrls: ['./document-vault.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AemDocumentVaultComponent implements OnInit {
  public vaultData$: Observable<VaultData> = this.documentVaultService.userVaultData$;
  public entitiesOnPage = 10;
  public currentPage = 1;
  public selectedFolder$: BehaviorSubject<FolderContent> = new BehaviorSubject(null);
  public pageTitle$: Observable<string>;
  public language = this.languageService.languageCode;
  public appStorePath = `assets/img/app-store/${this.language}/app-store-badge-black.svg`;
  public googlePlayPath = `assets/img/google-play/${this.language}/google-play-badge-black.png`;
  public appStoreLink = (<any>environment.appStore)[this.userService.location?.toLowerCase()] || '#';
  public googlePlayLink = (<any>environment.googlePlay)[this.userService.location?.toLowerCase()] || '#';
  public isUserFreemium = this.userService.userData.membershipStatus === UserType.Freemium;

  private vaultData: VaultData;

  constructor(
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly bootstrapService: BootstrapService,
    private readonly documentVaultService: DocumentVaultService,
    private readonly destroyRef: DestroyRef,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    private readonly location: Location
  ) {}

  public ngOnInit(): void {
    this.resolveViews();
    this.fetchDocuments();
  }

  public trackByFile(index: number, item: File): string {
    return item.id;
  }

  public trackByFolder(index: number, item: FolderContent): string {
    return item.id.toString();
  }

  public getPage(page: number): void {
    this.currentPage = page;
    this.fetchDocuments();
  }

  public changeCurrentPage(event: number): void {
    this.currentPage = event;
  }

  public selectFolder(folder: FolderContent): void {
    const folderId: number = folder.id;

    this.selectedFolder$.next(folder);
    this.router.navigate([this.location.path()], { queryParams: { folderId } });
  }

  public goBack(): void {
    if (this.selectedFolder$.value) {
      this.currentPage = 1;
      this.fetchDocuments();
      this.selectedFolder$.next(null);
      this.router.navigate([this.location.path().split('?')[0]]);

      return;
    }
    this.router.navigate([this.bootstrapService.link.tripHealth]);
  }

  private fetchDocuments(): void {
    this.documentVaultService.getUserVaultData(this.entitiesOnPage, this.currentPage - 1)
  }

  private resolveViews(): void {
    this.vaultData$.pipe(
      map(vaultData => this.vaultData = vaultData),
      switchMap((_params) => this.route.queryParams),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(params => {
      const selectedFolder: FolderContent =
        this.vaultData.folder.content.find((folder: any) => folder.id === +params['folderId']) || null;

      this.selectedFolder$.next(selectedFolder);
      this.pageTitle$ = this.getPageTitle$();
    })
  }

  private getPageTitle$(): Observable<string> {
    return this.selectedFolder$.value
      ? this.translateService.get(
        `pages.DOCUMENT_VAULT.FOLDER_NAME.${this.selectedFolder$.value.name
          .replace(' ',  '_').toUpperCase()}`
      )
      : this.translateService.get('pages.DOCUMENT_VAULT.DEFAULT_PAGE_TITLE');
  }
}
