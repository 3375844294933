import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivationCode, ActivationCodesData } from "@common/profile/profile.interfaces";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ApiActivationCodesService {
  private readonly baseUrl = environment.api.baseUrl;

  constructor(
    private http: HttpClient
  ) {}
  
  public getActivationCode(): Observable<ActivationCode> {
    return this.http.get<ActivationCode>(`${this.baseUrl}/profile/activation-code`);
  }

  public parseActivationCodes(countryCode: string, file: File): Observable<ActivationCodesData> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<ActivationCodesData>(`${this.baseUrl}/activation-codes/parse/${countryCode}`, formData);
  }

  public sendActivationCodes(data: ActivationCodesData): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/activation-codes/import`, data);
  }
}